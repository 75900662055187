// @font-face {
//   font-family: 'Noto Sans KR';
//   font-style: normal;
//   font-weight: 300;
//   src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.eot'); /* IE9 Compat Modes */
//   src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
//     url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.woff')
//       format('woff'),
//     /* Modern Browsers */ url('../assets/fonts/NotoSansKR-Light.ttf') format('truetype'),
//     /* Safari, Android, iOS */
//       url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
// }
// /* noto-sans-kr-regular - korean_latin */
// @font-face {
//   font-family: 'Noto Sans KR';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.eot'); /* IE9 Compat Modes */
//   src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
//     url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.eot?#iefix')
//       format('embedded-opentype'),
//     /* IE6-IE8 */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.woff')
//       format('woff'),
//     /* Modern Browsers */ url('../assets/fonts/NotoSansKR-Regular.ttf') format('truetype'),
//     /* Safari, Android, iOS */
//       url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
// }
// /* noto-sans-kr-500 - korean_latin */
// @font-face {
//   font-family: 'Noto Sans KR';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.eot'); /* IE9 Compat Modes */
//   src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
//     url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.woff')
//       format('woff'),
//     /* Modern Browsers */ url('../assets/fonts/NotoSansKR-Medium.ttf') format('truetype'),
//     /* Safari, Android, iOS */
//       url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
// }
// /* noto-sans-kr-700 - korean_latin */
// @font-face {
//   font-family: 'Noto Sans KR';
//   font-style: normal;
//   font-weight: 700;
//   src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.eot'); /* IE9 Compat Modes */
//   src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
//     url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.woff')
//       format('woff'),
//     /* Modern Browsers */ url('../assets/fonts/NotoSansKR-Bold.ttf') format('truetype'),
//     /* Safari, Android, iOS */
//       url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
// }
// /* noto-sans-kr-900 - korean_latin */
// @font-face {
//   font-family: 'Noto Sans KR';
//   font-style: normal;
//   font-weight: 900;
//   src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.eot'); /* IE9 Compat Modes */
//   src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
//     url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.woff')
//       format('woff'),
//     /* Modern Browsers */ url('../assets/fonts/NotoSansKR-Black.ttf') format('truetype'),
//     /* Safari, Android, iOS */
//       url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
// }

// @font-face {
//   font-family: 'NanumSquareNeo';
//   font-weight: 300;
//   font-style: normal;
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-aLt.eot');
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-aLt.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-aLt.woff2') format('woff2'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-aLt.woff') format('woff'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-aLt.ttf') format('truetype');
//   font-display: swap;
// }
// @font-face {
//   font-family: 'NanumSquareNeo';
//   font-weight: 400;
//   font-style: normal;
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-bRg.eot');
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-bRg.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-bRg.woff2') format('woff2'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-bRg.woff') format('woff'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-bRg.ttf') format('truetype');
//   font-display: swap;
// }
// @font-face {
//   font-family: 'NanumSquareNeo';
//   font-weight: 700;
//   font-style: normal;
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.eot');
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.woff2') format('woff2'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.woff') format('woff'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-cBd.ttf') format('truetype');
//   font-display: swap;
// }
// @font-face {
//   font-family: 'NanumSquareNeo';
//   font-weight: 800;
//   font-style: normal;
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.eot');
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.woff2') format('woff2'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.woff') format('woff'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-dEb.ttf') format('truetype');
//   font-display: swap;
// }
// @font-face {
//   font-family: 'NanumSquareNeo';
//   font-weight: 900;
//   font-style: normal;
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.eot');
//   src: url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.woff2') format('woff2'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.woff') format('woff'),
//     url('https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo-eHv.ttf') format('truetype');
//   font-display: swap;
// }

// @font-face {
//   font-family: 'NanumSquareNeo';
//   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.eot);
//   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.eot?#iefix)
//       format('embedded-opentype'),
//     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.woff)
//       format('woff'),
//     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-bRg.ttf)
//       format('truetype');
// }

body {
  font-family: 'NanumSquareNeo', sans-serif;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  font-size: 14px;
  letter-spacing: -0.3px;
  background-color: #fafafa;
  color: #262327;
  position: relative;
  word-break: keep-all;
  overflow-x: hidden;
  user-select: auto;
}
img,
a {
  // user-drag: none;
  user-select: auto;
  -moz-user-select: auto;
  -webkit-user-drag: none;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  max-width: 100%;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  color: #262327;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #333;
}
// common
.c-app {
  color: black;
}
.c-wrapper {
  position: relative;
}
.t-main {
  min-height: 60vh;
}
.box-shadow {
  box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1) !important;
}
.box-shadow1 {
  box-shadow: 1px 2px 16px 0 rgba(255, 113, 113, 0.16) !important;
}
.shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s ease-out;
}
.shadow:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
}
.nowrap {
  flex-wrap: nowrap;
}
.wrap {
  flex-wrap: wrap;
}
.white-bg {
  background: #fff !important;
}
.bg-1 {
  background: #f5f4fb;
}
.bg-2 {
  background: #f1f1f1;
}
.bg-E {
  background: #eee;
}
.bg-C {
  background: #ccc;
}
.bg-G {
  background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
}
.bg-T {
  background: transparent !important;
}
.bg-yellow {
  background: $yellow;
}
.blur-2 {
  filter: blur(2px);
}
.blur-5 {
  filter: blur(5px);
}
.y-scroll {
  overflow-y: scroll;
}
.y-hidden {
  overflow-y: hidden;
}
.x-scroll {
  overflow-x: scroll;
}
.x-hidden {
  overflow-x: hidden;
}
.z-top {
  z-index: 999999;
  position: relative;
}
.minh-5 {
  min-height: 5rem !important;
}
.minh-7 {
  min-height: 7rem !important;
}
.minh-10 {
  min-height: 10rem !important;
}
.minh-56 {
  min-height: 56px !important;
}
.minh-68 {
  min-height: 68px !important;
}
.minh-272 {
  min-height: 272px !important;
}
.minh-300 {
  min-height: 300px !important;
}
.minh-400 {
  min-height: 400px !important;
}
.max100 {
  max-width: 100%;
}
.max90 {
  max-width: 90%;
}
.max80 {
  max-width: 80%;
}
.maxw-10 {
  max-width: 10rem;
}
.maxw-54 {
  max-width: 54px;
}
.width50 {
  width: 50% !important;
  display: inline-block;
}
.w-30 {
  width: 30% !important;
  display: inline-block;
}
.w-20 {
  width: 20% !important;
  display: inline-block;
}
.w-auto {
  width: auto;
}
.width25 {
  width: 25rem !important;
}
.width24 {
  width: 24rem !important;
}
.width23 {
  width: 23rem !important;
}
.width21 {
  width: 21rem !important;
}
.width20 {
  width: 20rem !important;
}
.width18 {
  width: 18rem !important;
}
.width15 {
  width: 15rem !important;
}
.width14 {
  width: 14rem !important;
}
.width13 {
  width: 13rem !important;
}
.width12 {
  width: 12rem !important;
}
.width11 {
  width: 11rem !important;
}
.width10 {
  width: 10rem !important;
}
.width9 {
  width: 9rem !important;
}
.width8 {
  width: 8rem !important;
}
.width7 {
  width: 7rem !important;
}
.width6 {
  width: 6rem !important;
}
.width5 {
  width: 5rem !important;
}
.width4 {
  width: 4rem !important;
}
.width3 {
  width: 3rem !important;
}
.width2 {
  width: 2rem !important;
}
.width1 {
  width: 1rem !important;
}
.h-auto {
  height: auto;
}
.h-8 {
  height: 8px;
}
.h-16 {
  height: 16px;
}
.h-30 {
  height: 30px !important;
}
.h-36 {
  height: 36px !important;
}
.h-40 {
  height: 40px !important;
}
.h-44 {
  height: 44px !important;
}
.h-50 {
  height: 50px !important;
}
.h-64 {
  height: 64px !important;
}
.h-100 {
  height: 100% !important;
}
.h-100px {
  height: 100px !important;
}
.h-240 {
  height: 240px !important;
}
.w-8px {
  width: 8px !important;
}
.w-9px {
  width: 9px !important;
}
.w-10px {
  width: 10px !important;
}
.w-11px {
  width: 11px !important;
}
.w-12px {
  width: 12px !important;
}
.w-13px {
  width: 13px !important;
}
.w-14px {
  width: 14px !important;
}
.w-15px {
  width: 15px !important;
}
.w-16px {
  width: 16px !important;
}
.w-17px {
  width: 17px !important;
}
.w-18px {
  width: 18px !important;
}
.w-19px {
  width: 19px !important;
}
.w-20px {
  width: 20px !important;
}
.w-21px {
  width: 21px !important;
}
.w-22px {
  width: 22px !important;
}
.w-23px {
  width: 23px !important;
}
.w-24px {
  width: 24px !important;
}
.w-25px {
  width: 25px !important;
}
.w-28px {
  width: 28px !important;
}
.w-32px {
  width: 32px !important;
}
.w-36px {
  width: 36px !important;
}
.w-48px {
  width: 48px !important;
}
.w-55px {
  width: 55px !important;
}
.w-64px {
  width: 64px !important;
}
.m-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.m-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-48 {
  margin-top: 48px !important;
}
.mt-56 {
  margin-top: 56px !important;
}
.mt-60 {
  margin-top: 60px;
}
.mt-64 {
  margin-top: 64px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mb-28 {
  margin-bottom: 28px;
}
.m-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.p--56 {
  padding-top: 56px;
  padding-bottom: 56px;
}
.p--24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pt-30 {
  padding-top: 30px;
}
.d-Iblock {
  display: inline-block !important;
}
.align-center {
  align-items: center !important;
  display: flex;
  justify-content: center;
}
.align-center2 {
  align-items: center !important;
  display: flex;
}
.align-start {
  align-items: flex-start !important;
  display: flex;
}
.align-end {
  align-items: flex-end !important;
  display: flex;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.justify-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-direction {
  display: flex;
  flex-direction: column;
}
.flex-direction-row {
  display: flex;
}
.gap-2 {
  display: flex;
  gap: 2px !important;
}
.gap-4 {
  display: flex;
  gap: 4px !important;
}
.gap-6 {
  display: flex;
  gap: 6px;
}
.gap-8 {
  display: flex;
  gap: 8px;
}
.gap-9 {
  display: flex;
  gap: 9px;
}
.gap-10 {
  display: flex;
  gap: 10px;
}
.gap-12 {
  display: flex;
  gap: 12px;
}
.gap-16 {
  display: flex;
  gap: 16px;
}
.gap-20 {
  display: flex;
  gap: 20px;
}
.gap-24 {
  display: flex;
  gap: 24px;
}
.gap-28 {
  display: flex;
  gap: 28px;
}
.gap-32 {
  display: flex;
  gap: 32px;
}
.gap-40 {
  display: flex;
  gap: 40px;
}
.gap-48 {
  display: flex;
  gap: 48px;
}
.gap-52 {
  display: flex;
  gap: 52px;
}
.gap-56 {
  display: flex;
  gap: 56px;
}
.gap-64 {
  display: flex;
  gap: 64px;
}
.gap-104 {
  display: flex;
  gap: 104px;
}
.flex-1 {
  flex: 1;
}
.strong,
strong {
  font-weight: 900 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-350 {
  font-weight: 350 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-700,
.fw-bold {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.b-none {
  border: none !important;
}
.border-1 {
  border: 1px solid #eee;
}
.bordertop-1 {
  border-top: 1px solid #eee;
}
.box-none {
  box-shadow: none !important;
}
.b-radius0 {
  border-radius: 0px !important;
}
.b-radius1 {
  border-radius: 1px !important;
}
.b-radius4 {
  border-radius: 4px !important;
}
.b-radius10 {
  border-radius: 10px !important;
}
.b-radius10rem {
  border-radius: 10rem !important;
}
.rotate-90 {
  transform: rotate(-90deg);
}
.rotate-180 {
  transform: rotate(-180deg);
}
.btn {
  font-size: 16px !important;
  font-weight: 500;
  text-transform: none;
  box-shadow: none !important;
  height: 50px;
  border-radius: 10px;
}
.btn:hover {
  border-color: transparent;
  color: inherit;
}
.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.show:focus,
.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
  border-color: transparent; //border: none;
}
.btn-check:focus + .btn,
.btn:focus {
  border-color: transparent;
}
.btn-sm {
  margin: 0;
  padding: 0.5rem 1.3rem !important;
  font-size: 0.8rem !important;
}
.btn-default {
  background-color: #f4f4ee !important;
  color: rgba(60, 60, 67, 0.3);
}
.btn-default:hover {
  color: #94969a;
  background-color: #f4f4ee;
}
.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background-color: #f4f4ee !important;
  color: rgba(60, 60, 67, 0.3);
}
.btn-gray {
  background-color: gray !important;
}
.btn-gray1 {
  background-color: #f4f4ee !important;
  color: rgba(60, 60, 67, 0.3);
}
.btn-black,
.btn-black:hover,
.btn-black:active {
  background-color: #000 !important;
  color: white !important;
  border: 1px solid black !important;
}
.btn-danger {
  background-color: $danger !important;
  color: white;
}
.btn-pink,
.btn-pink:hover {
  background-color: $pink !important;
  color: white;
}
.btn-white {
  background-color: #fff !important;
  color: #666;
}
.btn-success,
.btn-success:hover {
  background-color: #2db400 !important;
  color: white;
}
.btn-primary,
.btn-primary:hover {
  background-color: $primary !important;
  color: white !important;
}
.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #cccccc !important;
  color: white !important;
  opacity: 1;
}
.btn-warning {
  background-color: #f9e000 !important;
  color: #262327;
  border: none;
}
.btn-secondary,
.btn-secondary:hover {
  background-color: #ccc !important;
  color: white !important;
  border: none !important;
}
.btn-rounded {
  border-radius: 10rem !important;
}
.btn-outline-primary,
.btn-outline-primary:hover {
  color: $primary !important;
  border: 1px solid $primary !important;
  font-weight: 700;
}
.btn-outline-primary.btn-rounded,
.btn-outline-primary.btn-rounded:hover {
  font-size: 14px !important;
  font-weight: 700;
  height: 40px;
}
.btn-outline-danger {
  color: #fa4616 !important;
  border: 1px solid #fa4616 !important;
}
.btn-outline-danger.btn-rounded,
.btn-outline-danger.btn-rounded:hover {
  font-size: 14px !important;
  font-weight: 700;
  height: 40px;
}
.btn-outline-warning,
.btn-outline-warning:hover {
  color: #262327 !important;
  border-color: #f9e000 !important;
}
.btn-outline-light {
  border-color: #ccc !important;
}
.btn-outline-dark {
  border: 1px solid black !important;
  color: black;
}
.btn-outline-black {
  border: 1px solid black !important;
}
.btn-outline-gray {
  border-color: #ccc !important;
}
.btn-outline-pink,
.btn-outline-pink:hover {
  border: 1px solid $pink !important;
  color: $pink;
}
.btn-outline-secondary,
.btn-outline-secondary:hover {
  border: 1px solid #999 !important;
  color: #666;
}
// .btn[class*="btn-outline-"] { border: 1px solid !important }
.btn[class*='btn-outline-']:active,
.btn[class*='btn-outline-']:focus,
.btn[class*='btn-outline-']:hover {
  background-color: inherit !important;
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f4f4ee !important;
  color: rgba(60, 60, 67, 0.3);
  font-weight: bold;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: inherit;
  border-color: transparent;
}
// button:not(.btn-pill) { font-size: 0.8rem !important; font-weight: 700 !important; border-radius: 3px;}
.btn-pill {
  border-radius: 50em !important;
}
.btn-outline-danger:hover {
  background-color: #ff35472b !important;
}
.bg-danger {
  background-color: $pink;
}
.disabled,
:disabled {
  cursor: not-allowed;
}
.sticky {
  position: sticky;
  top: 108px;
}
.sticky2 {
  position: sticky;
  top: 120px;
}
.sticky3 {
  position: sticky;
  top: 200px;
}
select option:disabled {
  color: #eee;
}
.c-pointer,
.pointer {
  cursor: pointer !important;
}
.c-auto {
  cursor: auto !important;
}
.react-draggable,
.cursor {
  cursor: move;
}
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}
.italic {
  font-style: italic !important;
}
hr {
  color: #eee;
  opacity: 1;
}
.wspace-prewrap {
  white-space: pre-wrap;
}
.wspace-preline {
  white-space: pre-line;
}
.word-wrap-break {
  word-wrap: break-word;
}
.word-break-word {
  word-break: break-word;
}
.pre-wrap {
  white-space: pre-wrap;
}
.text-intent-7 {
  text-indent: -7px;
  padding-left: 15px;
}
.text-intent-9 {
  text-indent: -9px;
  padding-left: 9px;
}
.gray-text3 {
  color: #303e48 !important;
}
.gray-text6 {
  color: #666 !important;
}
.gray-text9 {
  color: #999 !important;
}
.gray-text10 {
  color: #cccccc !important;
}
.gray-text11 {
  color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
}
.mute-text {
  color: #ccc !important;
}
.white-text {
  color: white !important;
}
.dark-text {
  color: #454f64 !important;
}
.dark-text1 {
  color: #62676a !important;
}
.black-text {
  color: #262327 !important;
}
.pink-text {
  color: $pink !important;
}
.text-light-blue {
  color: #4e65a0;
}
.kakao-text {
  color: #401c26 !important;
}
.green-text {
  color: #2db400 !important;
}
.danger-text {
  color: $danger !important;
}
.yellow-text {
  color: $yellow !important;
}
.blue-text {
  color: #5a80c4 !important;
}
.under-line {
  border-bottom: 1px solid !important;
}
.text-sideways {
  text-orientation: sideways;
  writing-mode: vertical-lr;
  text-align: center;
}
.v-bottom {
  vertical-align: bottom !important;
}
.v-top {
  vertical-align: top !important;
}
.v-ttop {
  vertical-align: text-top;
}
.v-tbottom {
  vertical-align: text-bottom;
}
.v-init {
  vertical-align: initial;
}
.v-middle {
  vertical-align: middle !important;
}
.v-hidden {
  visibility: hidden !important;
}
.opacity5 {
  opacity: 0.5;
}
.not-allowed {
  opacity: 0.5;
  cursor: not-allowed;
}
.slider-btn.not-allowed {
  opacity: 0.5;
  cursor: auto !important;
}
.card {
  border-radius: 0;
  margin: 0;
  border: none;
}
.view img {
  border-radius: 1px;
}
.b-top {
  border-top: 1px solid #eee !important;
}
.b-bottom {
  border-bottom: 1px solid #eee !important;
}
.b-left {
  border-left: 1px solid #ddd !important;
}
.b-right {
  border-right: 1px solid #ddd !important;
}
.b-radius-0 {
  border-radius: 0;
}
.b-radius-1 {
  border-radius: 0.5rem;
}
.b-radius-4 {
  border-radius: 4px;
}
.b-radius-8 {
  border-radius: 8px;
}
.b-radius-20 {
  border-radius: 20px;
}
.position-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.f-none {
  float: none !important;
}
.fs5 {
  font-size: 0.5rem !important;
}
.fs6 {
  font-size: 0.688rem !important;
}
.fs7 {
  font-size: 0.75rem !important;
}
.fs8 {
  font-size: 0.813rem !important;
}
.fs9 {
  font-size: 0.938rem !important;
}
.fs10 {
  font-size: 1.063rem !important;
}
.fs11 {
  font-size: 1.125rem !important;
}
.fs12 {
  font-size: 1.25rem !important;
}
.fs13 {
  font-size: 1.375rem !important;
}
.fs14 {
  font-size: 1.4rem !important;
}
.fs15 {
  font-size: 1.5rem !important;
}
.fs16 {
  font-size: 1.6rem !important;
}
.fs17 {
  font-size: 1.75rem !important;
}
.fs2 {
  font-size: 2rem !important;
}
.fs21 {
  font-size: 2.125rem !important;
}
.fs3 {
  font-size: 3rem !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-44 {
  font-size: 44px !important;
}
.fs-56 {
  font-size: 56px !important;
}
.lh10 {
  line-height: 1rem;
}
.lh18 {
  line-height: 18px !important;
}
.lh13 {
  line-height: 1.3rem;
}
.lh15 {
  line-height: 1.5rem;
}
.lh20 {
  line-height: 2rem;
}
.lh-50 {
  line-height: 50px;
}
.lh0813 {
  line-height: 0.813rem;
}
.ls32 {
  letter-spacing: 0.32px;
}
.ls43 {
  letter-spacing: 0.43px;
}
.ls65 {
  letter-spacing: 0.65px;
}
.ls-41 {
  letter-spacing: -0.41px;
}
.ls-71 {
  letter-spacing: -0.71px;
}
.ls-1 {
  letter-spacing: -1px;
}
.ls-17 {
  letter-spacing: -1.7px;
}
.label,
.inputTitle {
  font-size: 0.813rem;
  font-weight: 400;
  margin-bottom: 0.188rem !important;
}
.form-label {
  font-size: 14px;
  color: #303e48;
  span {
    color: $danger;
  }
}
.col-form-label {
  font-size: 14px;
  color: #303e48;
  padding-top: 11px;
}

.br-7 {
  border-radius: 6.9px !important;
}
.br-0 {
  border-radius: 0 !important;
}
.m--1 {
  margin-left: -15px;
  margin-right: -15px;
}
.m--30 {
  margin-left: 30px;
  margin-right: 30px;
}
.m--15 {
  margin-left: 15px;
  margin-right: 15px;
}
.m--12 {
  margin-left: 12px;
  margin-right: 12px;
}

.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.mb-2rem {
  margin-bottom: 2rem;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-60 {
  margin-bottom: 60px;
}
.pr-2rem {
  padding-right: 2rem;
}
.mt--1 {
  position: relative;
  top: -1px;
}
.mt--2 {
  position: relative;
  top: -2px;
}
.mt--3 {
  position: relative;
  top: -3px;
}
.mt--4 {
  position: relative;
  top: -4px;
}
.mt--15 {
  position: relative;
  top: -15px;
}
.error {
  color: red;
  font-style: normal;
}
.ellipsis1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-item {
  font-size: 14px;
  color: black;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
  color: inherit;
  background-color: white;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
.clear {
  clear: both;
}
.color1 {
  color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
}
input.security {
  -webkit-text-security: disc;
}
.custom-select:focus,
select:focus,
.uneditable-input:focus,
input[type='color']:focus,
input[type='date']:focus,
input[type='datetime-local']:focus,
input[type='datetime']:focus,
input[type='email']:focus,
input[type='month']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='time']:focus,
input[type='url']:focus,
input[type='week']:focus,
textarea:focus {
  border-color: #262327 !important;
  outline: 0;
  box-shadow: none !important;
}
.form-control,
.custom-select,
.rs-input {
  height: 50px;
  font-size: 16px !important;
  color: #333;
  border-radius: 10px !important;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
}
textarea.form-control {
  padding: 1rem;
}
.form-control.border-input,
.custom-select.border-input,
.rs-input.border-input {
  border: 1px solid rgba(60, 60, 67, 0.3);
  border-radius: 10rem;
  padding-left: 1rem;
  font-size: 0.938rem;
}
.form-control:focus {
  box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f4f4f4;
}
.rs-input {
  height: 3rem !important;
}
.md-form.md-outline label {
  padding-left: 20px;
  top: 4px;
  font-size: 0.9rem;
}
.md-form.md-outline {
  margin-bottom: 1rem;
}
// .custom-select {padding-left: 5px; background: #FFF url("/img/arrow-down.svg") no-repeat right .5rem center;}
// .custom-select.custom {padding-left: 0; background: #FFF url("/img/arrow-right.svg") no-repeat right .5rem center;}
.form-select {
  height: 50px;
  border-color: #ccc;
  background-image: url(../assets/img/i_down_arrow.png);
  background-size: 24px;
}
// @supports (-webkit-font-smoothing: auto) {   // for apple
//   select {
//      padding: .375rem 0;
//      width: calc(100% - 4px);
//      transform: translateX(15px);
//   }
// }
.popover-body {
  font-size: 0.688rem;
  white-space: pre-wrap;
  em {
    font-style: normal;
    color: $danger;
  }
}
.progress {
  border-radius: 10rem;
  background: #eeeeee;
  height: 10px;
}
.avatar-xxl {
  width: 6rem;
  height: 6rem;
}
.avatar-xxxl {
  width: 8rem;
  height: 8rem;
}
// .collapse, .collapsing {transition: height 0s ease !important; height: auto !important; animation: none;animation-duration: 0s !important;}
// .collapsing {display: none;}
// .collapse.collapsing {display: block;}
.collapse .descript,
.collapsing .descript {
  padding: 1rem;
  font-size: 0.938rem;
}
.collapse p a {
  text-decoration: underline;
}
.tooltip-inner {
  max-width: 100%;
}
.modal-open {
  overflow: hidden;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}
.modal.fade.show {
  z-index: 900000;
}
.modal .bottom-wrap .btn {
  height: 3rem !important;
}
.modal .modal-close {
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.modal-header {
  border: none;
  padding: 0 1rem;
}
.modal-dialog .modal-content {
  border-radius: 10px;
  border: none;
}
.modal-body {
  padding: 20px;
}
.modal-footer {
  border: none;
  justify-content: center;
  padding: 30px 20px;
  padding-top: 0px;
  button,
  button:hover {
    flex: 1;
    border: none;
    height: 40px;
    font-size: 14px !important;
    border-radius: 6px;
  }
  button.one {
    flex: none;
    width: 7rem;
  }
  .btn-secondary {
    background: #cccccc;
    border-radius: 6px;
    color: white;
  }
  .btn-primary {
    background: #f9e000;
    border-radius: 6px;
    color: white;
  }
}
.accordion-item {
  border-radius: 10px !important;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  overflow: hidden;
}
.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dddddd;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  border-bottom: 0;
  box-shadow: none;
  color: #262327;
}
.accordion-button:focus {
  outline: 0;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--cui-accordion-btn-icon);
}
//
.tippy-box[data-theme~='cpopover'] {
  background-color: #fee;
  border-radius: 0.7rem;
  border-color: #fee;
  font-size: 0.9rem;
}
.tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow::after,
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::after {
  border-bottom-color: #fee;
}
.tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow::before,
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::before {
  border-bottom-color: #fee;
}
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::after {
  border-top-color: #fee;
}
.custom-control-label {
  cursor: pointer;
  font-size: 0.938rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #916a6c;
  border-color: #916a6c;
  background-color: #916a6c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 3px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  top: 7px;
  left: -20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $danger;
  background-image: none;
}
.custom-radio .custom-control-label::before {
  background-color: #916a6c;
  border-color: #8a7677;
}
// checkbox
.custom-checkbox .custom-control-label::before {
  top: 4px;
  border-radius: 50% !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 4px;
  left: -24px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-radius: 5px;
  color: $danger;
  border-color: $danger;
  background-color: $danger;
  width: 16px;
  height: 16px;
  top: 4px;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url('../assets/img/i_checked.png');
}
.form-check-input[type='checkbox'] {
  background-image: url('../assets/img/i_check.png');
  background-color: transparent;
  border-color: transparent;
  height: 24px;
  width: 24px;
  margin-top: -1px;
}
.form-check-input:focus {
  box-shadow: none;
}
.form-check-input:checked[type='radio'] {
  background-image: url('../assets/img/i_radio_on.png');
}
.form-check-input[type='radio'] {
  background-image: url('../assets/img/i_radio_off.png');
  background-color: transparent;
  border-color: transparent;
  width: 28px;
  height: 28px;
  margin-top: 0;
}
.form-check-label {
  cursor: pointer;
  font-size: 14px;
  white-space: pre-wrap;
}
.form-check.big-size {
  .form-check-input[type='checkbox'] {
    width: 30px;
    height: 30px;
  }
  .form-check-label {
    font-size: 20px;
    font-weight: 700;
    margin-left: 4px;
  }
}
.form-check-input.toggle:checked[type='checkbox'] {
  background-image: url('../assets/img/i_toggleed.png');
}
.form-check-input.toggle[type='checkbox'] {
  background-image: url('../assets/img/i_toggle.png');
  background-color: transparent;
  border-color: transparent;
  width: 40px;
  height: 20px;
}
.form-check-input.radio:checked[type='checkbox'] {
  background-image: url('../assets/img/i_checked2.png');
}
.form-check-input.radio[type='checkbox'] {
  background-image: url('../assets/img/i_check2.png');
  background-color: transparent;
  border-color: transparent;
  width: 24px;
  height: 24px;
}
.tippy-content {
  max-width: 100%;
}
.tippy-box {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
[data-tippy-root] {
  min-width: 18rem;
}
.product-detail [data-tippy-root] {
  min-width: 20rem;
}
.popover-header {
  border: none;
  font-weight: bold;
  padding-bottom: 0;
  background-color: transparent;
  padding-top: 1rem;
}
.c-switch {
  width: 3.188rem;
  height: 2.138rem;
}
.c-switch[class*='-3d'] .c-switch-slider::before {
  top: 2px;
  left: 5px;
  width: 1.738rem;
  height: 1.738rem;
}
.nav-tabs {
  border-bottom: none;
  .nav-item {
    flex: 1;
  }
  .nav-link {
    border: none;
    color: #999;
    font-size: 20px;
    font-weight: 400;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
    padding: 0;
    border-radius: 0;
    text-align: center;
    border-bottom: 2px solid #eee;
  }
  // .nav-link:hover, .nav-link:focus,
  .nav-link.active {
    border-color: #262327;
    color: #262327;
    font-weight: 700;
    background-color: transparent;
  }
}
.pagination {
  justify-content: center;
}
.page-link {
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #999;
  border: none;
  border-radius: 0 !important;
  margin: 0 2px !important;
  background: transparent;
  font-size: 12px;
}
.page-link.active,
.active > .page-link {
  background: #262327 !important;
  color: white !important;
}
.page-link:focus,
.page-link:hover {
  color: inherit;
}
//
.animated1 {
  animation-duration: 100ms;
  animation-fill-mode: both;
}
.animated2 {
  animation-duration: 200ms;
  animation-fill-mode: both;
}
.animated3 {
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.fade.show {
  transition: opacity 0.15s linear;
  opacity: 1 !important;
}
.tooltip.show {
  z-index: 999999 !important;
  white-space: pre-wrap;
  opacity: 0.8 !important;
}
.invalid-feedback {
  font-size: 10px;
  margin-top: 0.15rem;
  color: $danger;
  text-align: left;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background: none;
}
// slider
.carousel .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 0 0.3rem;
  background-color: #ccc;
  opacity: 1;
  background-clip: unset;
  border: none;
}
.carousel-indicators .active {
  background-color: $pink !important;
}
.carousel-control-prev,
.carousel-control-next {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  opacity: 1;
  top: 39%;
}
.carousel-control-prev {
  left: -65px;
}
.carousel-control-next {
  right: -65px;
}
.carousel .carousel-control-prev-icon {
  background-image: url('../assets/img/slider_prev.png');
}
.carousel .carousel-control-next-icon {
  background-image: url('../assets/img/slider_next.png');
}
.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  width: 13px;
  height: 19px;
}
//
.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  display: none !important;
}
.swiper-prev,
.swiper-next {
  position: absolute;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  top: 44%;
  width: 14px;
  height: 28px;
  cursor: pointer;
}
.swiper-prev {
  background-image: url('../assets/img/slider_prev.png');
  left: -55px;
}
.swiper-next {
  background-image: url('../assets/img/slider_next.png');
  right: -55px;
}
.swiper-next.swiper-button-disabled,
.swiper-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-pagination span {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 0 0.3rem;
  background-color: #ccc;
  opacity: 1;
  background-clip: unset;
  border: none;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: $pink !important;
}
// confirm
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
  padding-top: 28px;
  align-items: flex-start !important;
  .react-confirm-alert-body {
    text-align: center;
    font-size: 16px;
    color: #262327;
    padding-top: 50px;
    position: relative;
    white-space: pre-wrap;
  }
  .modal-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .react-confirm-alert-button-group {
    justify-content: center;
    button {
      font-size: 14px;
      color: white;
      font-weight: 700;
      background: #ccc;
      height: 40px;
      padding: 5px 25px;
      line-height: 30px;
      width: 8rem;
    }
    button:last-child {
      background: $primary;
    }
  }
}
//
.rs-popover {
  max-width: 20rem;
  span {
    color: $danger;
  }
}
// global
::-webkit-input-placeholder {
  color: #999 !important;
  font-size: 16px;
  font-weight: 300;
} /* Edge */
:-ms-input-placeholder {
  color: #999 !important;
  font-size: 16px;
  font-weight: 300;
} /* Internet Explorer 10-11 */
::placeholder {
  color: #999 !important;
  font-size: 16px;
  font-weight: 300;
}
// input [number] spin disable
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* =============
  Tables
============= */
table {
  border: 1px solid;
  border-collapse: collapse;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border: 1px solid;
  padding: 0.5rem;
}
/* Firefox */
input[type='number'] {
  // -moz-appearance: textfield;
  appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
@media (min-width: 768px) {
  .fs-md-10 {
    font-size: 10px !important;
  }
  .fs-md-11 {
    font-size: 11px !important;
  }
  .fs-md-12 {
    font-size: 12px !important;
  }
  .fs-md-13 {
    font-size: 13px !important;
  }
  .fs-md-14 {
    font-size: 14px !important;
  }
  .fs-md-15 {
    font-size: 15px !important;
  }
  .fs-md-16 {
    font-size: 16px !important;
  }
  .fs-md-18 {
    font-size: 18px !important;
  }
  .fs-md-20 {
    font-size: 20px !important;
  }
  .fs-md-24 {
    font-size: 24px !important;
  }
  .fs-md-32 {
    font-size: 32px !important;
  }
  .fs-md-36 {
    font-size: 36px !important;
  }
  .fs-md-40 {
    font-size: 40px !important;
  }
  .fs-md-44 {
    font-size: 44px !important;
  }
  .w-md-10px {
    width: 10px !important;
  }
  .w-md-11px {
    width: 11px !important;
  }
  .w-md-12px {
    width: 12px !important;
  }
  .w-md-13px {
    width: 13px !important;
  }
  .w-md-14px {
    width: 14px !important;
  }
  .w-md-15px {
    width: 15px !important;
  }
  .w-md-16px {
    width: 16px !important;
  }
  .w-md-17px {
    width: 17px !important;
  }
  .w-md-18px {
    width: 18px !important;
  }
  .w-md-19px {
    width: 19px !important;
  }
  .w-md-20px {
    width: 20px !important;
  }
  .w-md-21px {
    width: 21px !important;
  }
  .w-md-22px {
    width: 22px !important;
  }
  .w-md-23px {
    width: 23px !important;
  }
  .w-md-24px {
    width: 24px !important;
  }
  .w-md-36px {
    width: 36px !important;
  }
  .w-md-64px {
    width: 64px !important;
  }
  .width-md-24 {
    width: 24rem !important;
  }
  .width-md-23 {
    width: 23rem !important;
  }
  .width-md-21 {
    width: 21rem !important;
  }
  .width-md-20 {
    width: 20rem !important;
  }
  .width-md-14 {
    width: 14rem !important;
  }
  .width-md-13 {
    width: 13rem !important;
  }
  .width-md-12 {
    width: 12rem !important;
  }
  .width-md-11 {
    width: 11rem !important;
  }
  .width-md-10 {
    width: 10rem !important;
  }
  .width-md-9 {
    width: 9rem !important;
  }
  .width-md-8 {
    width: 8rem !important;
  }
  .width-md-7 {
    width: 7rem !important;
  }
  .width-md-6 {
    width: 6rem !important;
  }
  .width-md-5 {
    width: 5rem !important;
  }
  .width-md-1 {
    width: 1rem !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .lh-md-13 {
    line-height: 1.3rem;
  }
  .lh-md-15 {
    line-height: 1.5rem;
  }
  .lh-md-20 {
    line-height: 2rem;
  }
  .under-md-line {
    border-bottom: 1px solid !important;
  }
}
@media (max-width: 767px) {
  .white-sm-bg {
    background: #fff !important;
  }
  .swiper-pagination span {
    width: 6px;
    height: 6px;
  }
  .form-check-label {
    font-size: 14px;
  }
  .form-check-input[type='checkbox'] {
    margin-top: 0;
  }
  .form-check-input[type='radio'] {
    margin-top: -3px;
  }
  .form-check.big-size {
    .form-check-input[type='checkbox'] {
      width: 27px;
      height: 27px;
      margin-top: 2px;
    }
    .form-check-label {
      font-size: 20px;
      font-weight: 700;
      margin-left: 0;
    }
  }
  .align-sm-center {
    align-items: center !important;
    display: flex;
    justify-content: center;
  }
  .justify-sm-between {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  .justify-sm-start {
    justify-content: start !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .fw-sm-900 {
    font-weight: 900 !important;
  }
  .fw-sm-700 {
    font-weight: 700 !important;
  }
  .fw-sm-400 {
    font-weight: 400 !important;
  }
  .fs-sm-10 {
    font-size: 1.063rem;
  }
  .fs-sm-9 {
    font-size: 0.938rem;
  }
  .fs-sm-14 {
    font-size: 14px !important;
  }
  .width-sm-8 {
    width: 8rem !important;
  }
  .h-sm-44 {
    height: 44px !important;
  }
  .h-sm-50 {
    height: 50px !important;
  }
  .h-sm-64 {
    height: 64px !important;
  }
  .minh-sm-240 {
    min-height: 240px !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .rs-popover {
    max-width: 12rem;
  }
  .form-control,
  .custom-select,
  .rs-input {
    padding: 0.375rem 10px;
  }
  .b-sm-none {
    border: none !important;
  }
  .modal-body {
    padding: 15px;
  }
  .modal-footer {
    padding: 20px 10px;
    button.one {
      width: 100%;
    }
  }
  // confirm
  .react-confirm-alert-overlay {
    padding-top: 8px;
    .react-confirm-alert-body {
      width: 96vw;
    }
    .react-confirm-alert-button-group {
      button {
        width: 100%;
      }
    }
  }
  .nav-tabs {
    .nav-link {
      font-size: 12px;
      height: 45px;
      padding: 0 5px;
    }
    // .nav-link:hover, .nav-link:focus { border-color: #EEE; color:#999; }
  }
  // global
  ::-webkit-input-placeholder {
    color: #999 !important;
    letter-spacing: -0.7px;
  } /* Edge */
  :-ms-input-placeholder {
    color: #999 !important;
    letter-spacing: -0.7px;
  } /* Internet Explorer 10-11 */
  ::placeholder {
    color: #999 !important;
    letter-spacing: -0.7x;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    display: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
  }
  [data-tippy-root] {
    min-width: 100%;
  }
}
//
@media (min-width: 576px) {
  .modal-md {
    .modal-dialog {
      max-width: 400px;
    }
  }
  .modal-lg {
    .modal-dialog {
      max-width: 600px;
    }
  }
  .modal-lg.result {
    .modal-dialog {
      max-width: 720px;
    }
  }
  html:not([dir='rtl']) .text-sm-center {
    text-align: left !important;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.height30 {
  height: 30px !important;
}

.pickgrayColor {
  color: var(--Neutral-7, #8f8f8f) !important;
  font-size: 14px !important;
}
.gap-0 {
  display: flex;
  gap: 0 !important;
}

.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
  pointer-events: none;
}
.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  margin: 0;
  padding: 0;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li::before {
  content: '\2022';
}
.ql-editor ul[data-checked='true'],
.ql-editor ul[data-checked='false'] {
  pointer-events: none;
}
.ql-editor ul[data-checked='true'] > li *,
.ql-editor ul[data-checked='false'] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked='true'] > li::before,
.ql-editor ul[data-checked='false'] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked='true'] > li::before {
  content: '\2611';
}
.ql-editor ul[data-checked='false'] > li::before {
  content: '\2610';
}
.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: #000;
}
.ql-editor .ql-bg-red {
  background-color: #e60000;
}
.ql-editor .ql-bg-orange {
  background-color: #f90;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}
.ql-editor .ql-bg-green {
  background-color: #008a00;
}
.ql-editor .ql-bg-blue {
  background-color: #06c;
}
.ql-editor .ql-bg-purple {
  background-color: #93f;
}
.ql-editor .ql-color-white {
  color: #fff;
}
.ql-editor .ql-color-red {
  color: #e60000;
}
.ql-editor .ql-color-orange {
  color: #f90;
}
.ql-editor .ql-color-yellow {
  color: #ff0;
}
.ql-editor .ql-color-green {
  color: #008a00;
}
.ql-editor .ql-color-blue {
  color: #06c;
}
.ql-editor .ql-color-purple {
  color: #93f;
}
.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: '';
  display: table;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}
.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}
.ql-snow.ql-toolbar button:active:hover,
.ql-snow .ql-toolbar button:active:hover {
  outline: none;
}
.ql-snow.ql-toolbar input.ql-image[type='file'],
.ql-snow .ql-toolbar input.ql-image[type='file'] {
  display: none;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}
@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active),
  .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}
.ql-snow {
  box-sizing: border-box;
}
.ql-snow * {
  box-sizing: border-box;
}
.ql-snow .ql-hidden {
  display: none;
}
.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden;
}
.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}
.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}
.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}
.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-formats:after {
  clear: both;
  content: '';
  display: table;
}
.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444;
}
.ql-snow .ql-empty {
  fill: none;
}
.ql-snow .ql-even {
  fill-rule: evenodd;
}
.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1;
}
.ql-snow .ql-transparent {
  opacity: 0.4;
}
.ql-snow .ql-direction svg:last-child {
  display: none;
}
.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}
.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}
.ql-snow .ql-editor h1 {
  font-size: 2em;
}
.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}
.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}
.ql-snow .ql-editor h4 {
  font-size: 1em;
}
.ql-snow .ql-editor h5 {
  font-size: 0.83em;
}
.ql-snow .ql-editor h6 {
  font-size: 0.67em;
}
.ql-snow .ql-editor a {
  text-decoration: underline;
}
.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}
.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}
.ql-snow .ql-editor code {
  font-size: 85%;
  padding: 2px 4px;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}
.ql-snow .ql-editor img {
  max-width: 100%;
}
.ql-snow .ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}
.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}
.ql-snow .ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}
.ql-snow .ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}
.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}
.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px;
}
.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}
.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0px;
}
.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}
.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
  content: attr(data-label);
}
.ql-snow .ql-picker.ql-header {
  width: 98px;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: 'Normal';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: 'Heading 1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: 'Heading 2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: 'Heading 3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: 'Heading 4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: 'Heading 5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: 'Heading 6';
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  font-size: 2em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  font-size: 1.5em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  font-size: 1.17em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  font-size: 1em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  font-size: 0.83em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  font-size: 0.67em;
}
.ql-snow .ql-picker.ql-font {
  width: 108px;
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Sans Serif';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: 'Serif';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: 'Monospace';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  font-family: Georgia, Times New Roman, serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  font-family: Monaco, Courier New, monospace;
}
.ql-snow .ql-picker.ql-size {
  width: 98px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normal';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: 'Small';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: 'Large';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: 'Huge';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  font-size: 32px;
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
}
.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}
.ql-snow .ql-tooltip::before {
  content: 'Visit URL:';
  line-height: 26px;
  margin-right: 8px;
}
.ql-snow .ql-tooltip input[type='text'] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: 'Edit';
  margin-left: 16px;
  padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Remove';
  margin-left: 8px;
}
.ql-snow .ql-tooltip a {
  line-height: 26px;
}
.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.ql-snow .ql-tooltip.ql-editing input[type='text'] {
  display: inline-block;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: 'Save';
  padding-right: 0px;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'Enter link:';
}
.ql-snow .ql-tooltip[data-mode='formula']::before {
  content: 'Enter formula:';
}
.ql-snow .ql-tooltip[data-mode='video']::before {
  content: 'Enter video:';
}
.ql-snow a {
  color: #06c;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
}

// common
// :root {
//     --cui-info-rgb: 90,128,196;
// }

.loading-animation {
  -webkit-animation: dots-fade 2s infinite;
  animation: dots-fade 2s infinite;
  background-color: var(--primary);
  border-radius: 100%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

html {
  scroll-padding-top: 130px;
}

body.stop {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}

// .spinner-border {display: none;}
div:has(.spinner-border) {
  text-align: center;
}

.search-wrap {
  position: relative;

  input {
    border-radius: 16px !important;
    background: var(--neutral-3, #f5f5f5);
    padding-right: 2.5rem;
    padding-left: 40px;
  }

  .btn-search {
    position: absolute;
    top: 13px;
    left: 12px;
    cursor: pointer;
  }

  .search-list {
    position: absolute;
    top: 48px;
    left: 20px;
    width: calc(100% - 40px);
    background-color: white;
    z-index: 9;
    border-radius: 10px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;

    li,
    li:hover {
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      margin-bottom: 10px;
    }

    li:last-child {
      margin-bottom: 0px;
    }
  }
}

.logo {
  font-size: 28px;
  font-weight: 900 !important;
  line-height: 41px;
}

.logo-img {
  width: 87px;
}

.btn-big {
  height: 64px;
  font-size: 20px !important;
  font-weight: 700 !important;
  border-radius: 10px;
}

@media (hover: hover) and (pointer: fine) {
  .btn-warning:hover {
    background: #222222 !important;
    color: #fce500 !important;

    img {
      filter: invert(1);
    }
  }
}

.radio-check {
  .form-check-input[type='checkbox'] {
    background-image: url('../assets/img/i_check1_off.png');
    height: 32px;
    width: 32px;
  }

  .form-check-input:checked[type='checkbox'] {
    background-image: url('../assets/img/i_check1_on.png');
  }

  .form-check-label {
    font-size: 20px;
    margin-left: 10px;
  }
}

.range-slider {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: auto;
  user-select: auto;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 10px;
  background: #eeeeee;
  border-radius: 100px;

  .range-slider__thumb {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid #f9e000;
    width: 20px;
    height: 20px;
  }

  .range-slider__thumb:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 6px rgba(33, 150, 243, 0.5);
  }

  .range-slider__thumb[data-disabled] {
    z-index: 2;
  }

  .range-slider__range {
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    height: 100%;
    background: #f9e000;
    border-radius: 100px;
  }

  input[type='range'] {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
  }

  input[type='range']::-moz-range-thumb {
    width: 0;
    height: 0;
    border: 0;
  }

  input[type='range']:focus {
    outline: 0;
  }
}

.range-slider[data-vertical] {
  height: 100%;
  width: 10px;
}

.range-slider[data-disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.range-slider[data-vertical] .range-slider__thumb {
  left: 50%;
}

.range-slider[data-vertical] .range-slider__range {
  left: 50%;
  transform: translate(-50%, 0);
}

.tag-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #666;
}

.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px;
  background: #eee;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin: 0 5px 5px 0;
  color: #666;
  justify-content: center;
}

.edu-item {
  border-radius: 20px;
  margin: 10px;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;

  .card-body {
    background-color: var(--neutral-3, #f5f5f5) !important;
  }

  .edu-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // height: 48px;
    // line-height: 30px;
  }

  .edu-text {
    overflow: hidden;
    color: var(--neutral-11, #292929);
    text-overflow: ellipsis;
    width: 95px;
    white-space: nowrap;
    text-align: left;
  }

  .thumbnail1-group {
    display: flex;
    justify-content: space-between;
  }

  .thumbnail1 {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .remain-day {
    font-weight: 700;
    color: var(--neutral-7, #8f8f8f);
  }

  .end-day {
    color: var(--neutral-5, #ccc);
    text-align: right;
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .remain-led {
    color: #c23934;
  }

  .logobg {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .logobg img {
    width: '100%';
    height: '100%';
    object-fit: cover;
  }

  .item-img {
    width: 100%;
    height: 200px;
    background: 0 0 / cover no-repeat #eee;
    border-radius: 20px 20px 0 0;
    position: relative;
  }

  .img-wrap {
    position: relative;

    .deem {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.7);
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: #fff;
    }

    .online {
      font-weight: 700;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      gap: 10px;
      position: absolute;
      width: 63px;
      height: 32px;
      right: 11px;
      bottom: 14px;
      background: #262327;
      border-radius: 100px;
    }
  }

  .edu-type {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    height: 29px;
    background: #f9e000;
    border-radius: 100px;
    width: 110px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #262327;
  }

  .edu-type.end {
    background: #cccccc;
    color: white;
  }

  .edu-type.free {
    background: #ff6610;
    color: white;
  }

  .edu-type.cond-free {
    background: #2db400;
    color: white;
  }

  .edu-type.sponsored {
    background: #1d1693;
    color: white;
    border-radius: 6px;
  }

  // 교육찾기
  .img-row-wrap {
    width: 200px;
    margin: 20px 0 0 20px;

    img {
      border-radius: 10px;
      margin-bottom: 15px;
    }
  }

  .text133 {
    color: var(--orange, #ffa940);

    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .text1-1 {
    color: var(--orange, #ffa940);

    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    width: 77px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text2 {
    overflow: hidden;
    color: var(--tag-green, #287c58);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    position: relative;
    padding-right: 10px;

    .text2-plus {
      position: absolute;
      right: 2px;
      top: -6px;
      color: var(--tag-green, #287c58);
    }
  }

  .label1 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: var(--tag-light-green, #4cbb88);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .label-1 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--funtion-blue-light, #1890ff);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .label-2 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--tag-orange, #ff7e47);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .label-3 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--tag-light-green, #4cbb88);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .label-4 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--tag-green, #287c58);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .label2 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--black, #222);
    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .text3 {
    color: var(--neutral-11, #292929);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .pickBtnNoPick {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background-color: #fff;
    transition: background-color 0.2s, color 0.2s;
  }

  .pickBtnNoPick:hover {
    background-color: rgba(252, 229, 0, 0.4);
    /* new background color */
  }

  .text4 {
    color: var(--neutral-7, #8f8f8f);
    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .pickBtnNoPick:hover .text4 {
    color: var(--black, #222);
  }

  .pickBtnPick {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background-color: #fce500;
    transition: background-color 0.2s, color 0.2s;
  }

  .pickBtnPick:hover {
    background-color: rgba(252, 229, 0, 0.4);
    /* new background color */
  }

  .text5 {
    color: var(--black, #222);
    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .pickBtnPick1 {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    transition: background-color 0.2s, color 0.2s;
  }

  .pickBtnPick1:hover {
    border: 1px solid #fce500;

    // background-color: rgba(252, 229, 0, 0.4); /* new background color */
  }

  .text15 {
    color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }
}

.mb-edu-item {
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 152px !important;
  max-height: 260px !important;

  .card-body {
    background-color: var(--neutral-3, #f5f5f5) !important;
  }

  .edu-title {
    align-self: stretch;
    color: var(--neutral-11, #292929);
    /* Mo/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 137.5% */
    height: 66px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .edu-text {
    overflow: hidden;
    color: var(--neutral-11, #292929);
    text-overflow: ellipsis;
    width: 88px;
    white-space: nowrap;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-11, #292929);
  }

  .thumbnail1-group {
    display: flex;
    justify-content: space-between;
  }

  .thumbnail1 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  .remain-day {
    font-weight: 700;
    color: var(--neutral-7, #8f8f8f);
  }

  .end-day {
    color: var(--neutral-5, #ccc);
    text-align: right;
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .remain-led {
    color: #c23934;
  }

  .logobg {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .logobg img {
    width: '100%';
    height: '100%';
    object-fit: cover;
  }

  .item-img {
    width: 100%;
    height: 200px;
    background: 0 0 / cover no-repeat #eee;
    border-radius: 20px 20px 0 0;
    position: relative;
  }

  .img-wrap {
    position: relative;

    .deem {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.7);
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: #fff;
    }

    .online {
      font-weight: 700;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      gap: 10px;
      position: absolute;
      width: 63px;
      height: 32px;
      right: 11px;
      bottom: 14px;
      background: #262327;
      border-radius: 100px;
    }
  }

  .edu-type {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    height: 29px;
    background: #f9e000;
    border-radius: 100px;
    width: 110px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #262327;
  }

  .edu-type.end {
    background: #cccccc;
    color: white;
  }

  .edu-type.free {
    background: #ff6610;
    color: white;
  }

  .edu-type.cond-free {
    background: #2db400;
    color: white;
  }

  .edu-type.sponsored {
    background: #1d1693;
    color: white;
    border-radius: 6px;
  }

  // 교육찾기
  .img-row-wrap {
    width: 200px;
    margin: 20px 0 0 20px;

    img {
      border-radius: 10px;
      margin-bottom: 15px;
    }
  }

  .text133 {
    color: var(--orange, #ffa940);

    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 171.429% */
    position: relative;

    .text133-plus {
      position: absolute;
      right: -9px;
      top: -6px;
      color: var(--orange, #ffa940);
    }
  }

  .text1-1 {
    color: var(--orange, #ffa940);

    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    width: 77px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text2 {
    overflow: hidden;
    color: var(--tag-green, #287c58);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    position: relative;
    padding-right: 10px;

    .text2-plus {
      position: absolute;
      right: 2px;
      top: -6px;
      color: var(--tag-green, #287c58);
    }
  }

  .label1 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: var(--tag-light-green, #4cbb88);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .label-1 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--funtion-blue-light, #1890ff);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .label-2 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--tag-orange, #ff7e47);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .label-3 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--tag-light-green, #4cbb88);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .label-4 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--tag-green, #287c58);
    color: var(--white, #fff);
    /* PC/Bodym/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
  }

  .label2 {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--black, #222);
    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .text3 {
    color: var(--neutral-11, #292929);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 171.429% */
  }

  .text31 {
    color: var(--neutral-11, #292929);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 171.429% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pickBtnNoPick {
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background-color: #fff;
    transition: background-color 0.2s, color 0.2s;
  }

  .pickBtnNoPick:hover {
    background-color: rgba(252, 229, 0, 0.4);
    /* new background color */
  }

  .text4 {
    color: var(--neutral-7, #8f8f8f);
    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 162.5% */
  }

  .pickBtnNoPick:hover .text4 {
    color: var(--black, #222);
  }

  .pickBtnPick {
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background-color: #fce500;
    transition: background-color 0.2s, color 0.2s;
  }

  .pickBtnPick:hover {
    background-color: rgba(252, 229, 0, 0.4);
    /* new background color */
  }

  .text5 {
    color: var(--black, #222);
    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 162.5% */
  }

  .pickBtnPick1 {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    transition: background-color 0.2s, color 0.2s;
  }

  .pickBtnPick1:hover {
    border: 1px solid #fce500;

    // background-color: rgba(252, 229, 0, 0.4); /* new background color */
  }

  .text15 {
    color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }
}

.one-item-wrap .edu-item {
  width: 42%;

  .item-img {
    height: 11.5vw;
  }
}

.two-item-wrap .edu-item .item-img {
  height: 207px;
}

.three-item-wrap .edu-item .item-img {
  height: 207px;
}

.four-item-wrap .edu-item .item-img {
  height: 161px;
}

.mypage-re-myinfo {
  border-radius: 16px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--neutral-2, #fafafa);
  padding: 32px;
}

.re-myinfo-save {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
}

.data-wrap {
  display: flex;

  .label1 {
    width: 100px;
    padding-top: 9px;

    span {
      color: #db2121;
      font-size: 16px;
    }
  }

  .data {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.data-wrap1 {
  display: flex;
  gap: 24px;

  .label1 {
    width: 180px;
    padding-top: 9px;
    color: var(--black, #222);
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    span {
      color: #db2121;
      font-size: 16px;
    }
  }

  .data {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.mb-data-wrap1 {
  display: flex;
  gap: 8px;

  .label1 {
    width: 180px;
    padding-top: 9px;
    color: var(--black, #222);
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    span {
      color: #db2121;
      font-size: 12px;
    }
  }

  .data {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.mb-data-wrap1 .form-control {
  height: 30px !important;
  font-size: 12px !important;
  color: #333;
  border-radius: 10px !important;
  border: 1px solid #ccc;
}

.tag-outline {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  margin: 0 10px 10px 0;
}

.btn-showmore {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--neutral-7, #8f8f8f);

  /* PC/Bodyl/bold */
  // font-family: NanumSquare Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 162.5% */
}

.alarm-modal {
  background: rgba(38, 35, 39, 0.9);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  width: 100%;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
  bottom: -100%;
  position: fixed;
  left: 0;

  .left-wrap {
    padding-right: 7rem;
  }
}

.alarm-modal.show {
  bottom: 0;
}

.modal .radio-check .form-check-label {
  font-size: 16px;
  margin-top: 3px;
}

.timerStr {
  position: absolute;
  top: 15px;
  right: 20px;
}

.render-html p {
  margin-bottom: 5px;
}

.avatar-img {
  height: auto;
}

.home-slide {
  position: relative;

  .swiper {
    margin: 0 -10px;
    padding: 10px 0;
  }

  .swiper-slide {
    height: auto;

    .edu-item {
      width: 90% !important;
      height: 93%;
    }
  }

  .slider-btn {
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    position: absolute;
    top: 35%;
    cursor: pointer;
    background-size: 36px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .prev {
    left: -104px;
    background-image: url('../assets/img/ico_slide_prev_on.png');
  }

  .next {
    right: -104px;
    background-image: url('../assets/img/ico_slide_next_on.png');
  }

  .pagenation {
    font-size: 16px;
    line-height: 23px;
    color: #999999;
    margin-top: 10px;
    width: 74px;
    text-align: right;

    span {
      font-weight: 700;
      color: #262327;
    }
  }
}

.back_to_top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 200;
}

.header {
  height: 114px;
  border: none;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 40px;

  .middle {
    width: 41%;
  }

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
  }

  .btmline {
    position: absolute;
    bottom: 0px;
    /* width: 100%; */
    width: 0px;
    height: 2px;
    background-color: transparent;
  }

  .nav-item:hover,
  .nav-link.active {
    font-weight: 600;
    animation-name: topmenufont;
    animation-duration: 0.3s;
    color: #262327;
  }

  .nav-item:hover .btmline,
  .nav-link.active .btmline {
    background-color: #262327;
    width: calc(100%);
    transform: scaleX(1);
    transition-duration: 0.2s;
  }

  @keyframes topmenufont {
    from {
      font-weight: 400;
    }

    to {
      font-weight: 700;
    }
  }

  .nav-link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #262327;
  }

  .nav-link.active,
  .nav-link:hover {
    font-weight: 700;
    color: #262327;
  }

  .nav-item:last-child .nav-link:hover {
    letter-spacing: -0.55px;
  }

  .header-nav {
    display: flex;
    gap: 40px;

    .dropdown-menu {
      border-radius: 5px;
      border: 1px solid #ccc;
      margin: 14px 0 0 -2.3rem;
      min-width: 130px;
      padding: 18px 20px 10px;
      text-align: left;

      li {
        margin-bottom: 8px;
      }

      .dropdown-item {
        color: #262327;
        padding: 0;
      }

      .dropdown-item.on {
        font-weight: bold;
      }
    }
  }

  .btn-login {
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    padding: 12px 24px;
  }

  .btn-login:hover .login-text {
    color: #fce500 !important;
    font-weight: 400 !important;
  }

  .btn-login:hover {
    background: #222;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    padding: 12px 24px;
  }

  .header-nav .nav-link:hover,
  .header-nav .nav-link:focus {
    color: #262327;
  }

  .btn-register {
    height: 44px;
    padding: 0 20px;
    border: 1px solid #cccccc !important;
    border-radius: 10rem;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px !important;
    font-weight: 400;

    span,
    .i_down {
      display: block;
    }
  }

  .btn-register:hover {
    font-weight: 700;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .sitemap-menu {
    display: none;
  }

  .btn-my .nav-link {
    height: auto;
    padding: 0;
  }
}

.mobile-header {
  display: none;
}

.footer-menu {
  width: 100%;
  height: 66px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  // font-family: NanumSquare Neo;
  font-style: normal;
  z-index: 999;
}

.footer {
  padding: 40px;
  background-color: #333333;
  white-space: break-spaces;
  border-top: 0 !important;

  .footer-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }

  .footer-logo {
    display: flex;
    gap: 12px;
    position: absolute;
    top: 0;
    left: -226px;
  }
}

.left-logo {
  display: flex;
  justify-content: flex-start;
}

.titleL {
  // font-family: NanumSquare Neo;
  font-size: 40px;
  font-style: normal;
  font-weight: 350 !important;
  line-height: 64px;
}

.titleML {
  color: var(--black, #222);
  /* PC/H4/light */
  // font-family: NanumSquare Neo;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 36px;
  /* 150% */
}

.titleM {
  color: var(--black, #222);
  /* PC/Bodyl/regular */
  // font-family: NanumSquare Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 24px;
}

.task-box {
  margin-top: 72px;
}

.card-template {
  border-radius: 16px 120px 16px 16px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  /* shadow_1 */
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 100px 0 !important;
  width: auto;
  max-width: 632px;
  min-width: 388px;

  .skip-btn {
    width: 1000vw;
    max-width: 387px;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--neutral-7, #8f8f8f);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .accordion-box {
    width: 100vw;
    max-width: 387px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);

    .accordion-item1 {
      padding: 16px;
      border-bottom: 1px solid var(--neutral-4, #ebebeb);
    }

    .accordion-item1:last-child {
      border-bottom: none;
    }

    .img1 {
      width: 12px;
      height: 12px;
    }

    .text1 {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .stack-box {
      padding: 8px 16px 0 16px;
    }

    .stack-item {
      display: flex;
      padding: 1px 8px;
      align-items: center;
      gap: 3px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #ccc);
      background: var(--neutral-2, #fafafa);
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .stack-item.on {
      border-radius: 8px;
      padding: 1px 8px;
      border: 1px solid var(--yeloow, #fce500);
      background: var(--yeloow, #fce500);
      color: var(--black, #222);
      /* Mo/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 133.333% */
    }
  }
}

.mb-card-template {
  .form-check {
    .form-select:disabled {
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--neutral-3, #f5f5f5) !important;
      color: var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2)) !important;

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 171.429% */
    }

    display: flex;
    max-width: 320px;
    color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    align-items: center !important;

    .form-check-input[type='checkbox'] {
      visibility: hidden;
      position: relative;
      margin-top: 2px !important;
    }

    .form-check-input[type='checkbox']::before {
      content: '';
      visibility: visible;
      display: block;
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
      border: 1px solid #cccccc;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
    }

    .form-check-input[type='checkbox']:checked::before {
      border: 1px solid #287c58;
      // border: none;
    }

    .form-check-input[type='checkbox']:checked::after {
      content: '';
      visibility: visible;
      display: block;
      width: 10px !important;
      height: 10px !important;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      border: 1px solid #287c58;
      cursor: pointer;
      position: absolute;
      background: #287c58;
    }

    // .form-check-input[type='checkbox']:checked::before {
    //   border: 1px solid #000;
    //   // border: none;
    // }
    .form-check-label {
      font-size: 12px !important;
      margin-left: 10px;
      flex: 1;
      text-align: start;
    }
  }

  .area-box {
    width: 100%;
    height: 32px !important;
    max-width: 320px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--neutral-4, #ebebeb);
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 171.429% */
  }

  border: 1px solid var(--neutral-4, #ebebeb);
  border-top: 1px solid var(--yeloow, #fce500) !important;
  background: var(--white, #fff);
  /* shadow_1 */
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 100px 0 !important;
  width: auto;
  max-width: 632px;
  min-width: 388px;
  padding: 0 40px;

  .form-check {
    display: flex;
    max-width: 320px;
    color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    align-items: center !important;

    .form-check-input[type='checkbox'] {
      visibility: hidden;
      position: relative;
      margin-top: 2px !important;
    }

    .form-check-input[type='checkbox']::before {
      content: '';
      visibility: visible;
      display: block;
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
      border: 1px solid #cccccc;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
    }

    .form-check-input[type='checkbox']:checked::before {
      border: 1px solid #287c58;
      // border: none;
    }

    .form-check-input[type='checkbox']:checked::after {
      content: '';
      visibility: visible;
      display: block;
      width: 10px !important;
      height: 10px !important;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      border: 1px solid #287c58;
      cursor: pointer;
      position: absolute;
      background: #287c58;
    }

    // .form-check-input[type='checkbox']:checked::before {
    //   border: 1px solid #000;
    //   // border: none;
    // }
    .form-check-label {
      font-size: 16px;
      margin-left: 10px;
      flex: 1;
      text-align: start;
    }
  }

  .mb-big-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    background: #f9e000;
    border-radius: 8px;

    font-weight: 400;
    font-size: 12px !important;
    color: #262327;

    span {
      font-weight: 700;
      margin-left: 6px;
    }
  }

  .big-btn:hover {
    background: #262327;
    color: white;
  }

  .mb-btn88 {
    font-size: 12px !important;
    padding: 14px 20px !important;
  }

  .mb-btn88::placeholder {
    font-size: 12px !important;
  }

  .mb-template-pickerroo {
    width: 126px;
    height: 123px;
    flex-shrink: 0;
    object-fit: cover;
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .mb-job-pickerroo {
    width: 126px;
    height: 123px;
    flex-shrink: 0;
    object-fit: cover;
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px !important;

    .btn-prev {
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff) !important;
      font-size: 12px !important;
      color: var(--neutral-7, #8f8f8f) !important;
      padding: 16px 12px !important;
      height: auto !important;
      width: 136px !important;
      height: 40px !important;
    }

    .btn-prev:hover {
      font-size: 12px !important;
      color: #222222 !important;
      padding: 16px 12px;
      height: auto !important;
      width: 136px !important;
      height: 40px !important;
      border-radius: 10px;
      border: 1px solid var(--yeloow, #fce500);
      background: rgba(255, 214, 0, 0.4) !important;
    }

    .btn-next {
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--yeloow, #fce500) !important;
      font-size: 12px !important;
      color: #222222 !important;
      padding: 16px 12px !important;
      height: auto !important;
      width: 136px !important;
      height: 40px !important;
    }

    .btn-next:hover {
      font-size: 12px !important;
      color: #fce500 !important;
      padding: 16px 12px !important;
      height: auto !important;
      width: 136px !important;
      height: 40px !important;
      border-radius: 10px;
      background: #222222 !important;
    }

    .btn-next:disabled {
      font-size: 12px !important;
      color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
      padding: 16px 12px !important;
      height: auto !important;
      width: 136px !important;
      height: 40px !important;
      border-radius: 10px;
      background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
    }
  }

  .mb-task-name {
    margin-top: 20px;
    color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
  }

  .skip-btn {
    width: 1000vw;
    max-width: 387px;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--neutral-7, #8f8f8f);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .mb-skip-btn {
    width: 100%;
    display: flex;
    padding: 12px 16px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--neutral-7, #8f8f8f);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    /* 162.5% */
  }

  .accordion-box {
    width: 100vw;
    max-width: 387px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);

    .accordion-item1 {
      padding: 16px;
      border-bottom: 1px solid var(--neutral-4, #ebebeb);
    }

    .accordion-item1:last-child {
      border-bottom: none;
    }

    .img1 {
      width: 12px;
      height: 12px;
    }

    .text1 {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .stack-box {
      padding: 8px 16px 0 16px;
    }

    .stack-item {
      display: flex;
      padding: 1px 8px;
      align-items: center;
      gap: 3px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #ccc);
      background: var(--neutral-2, #fafafa);
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .stack-item.on {
      border-radius: 8px;
      padding: 1px 8px;
      border: 1px solid var(--yeloow, #fce500);
      background: var(--yeloow, #fce500);
      color: var(--black, #222);
      /* Mo/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 133.333% */
    }
  }

  .mb-accordion-box {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);

    .accordion-item1 {
      padding: 16px;
      border-bottom: 1px solid var(--neutral-4, #ebebeb);
    }

    .accordion-item1:last-child {
      border-bottom: none;
    }

    .img1 {
      width: 12px;
      height: 12px;
    }

    .text1 {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 162.5% */
    }

    .stack-box {
      padding: 8px 16px 0 16px;
    }

    .stack-item {
      display: flex;
      padding: 1px 8px;
      align-items: center;
      gap: 3px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #ccc);
      background: var(--neutral-2, #fafafa);
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .stack-item.on {
      border-radius: 8px;
      padding: 1px 8px;
      border: 1px solid var(--yeloow, #fce500);
      background: var(--yeloow, #fce500);
      color: var(--black, #222);
      /* Mo/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 133.333% */
    }
  }
}

.card-template2 {
  border-radius: 16px 120px 16px 16px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  /* shadow_1 */
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 240px 0 !important;
  width: auto;
  max-width: 792px;
}

.card-template3 {
  border-radius: 16px 120px 16px 16px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  /* shadow_1 */
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 72px 0 !important;
  width: auto;
  max-width: 792px;
}

.card-template4 {
  border-radius: 16px;
  border: 1px solid var(--neutral-5, #ccc);
  background: var(--White, #fff);
  position: relative;
  margin: 72px 0 !important;
  width: auto;
  max-width: 792px;
}

.mb-card-template4 {
  border-radius: 16px;
  border: 1px solid var(--neutral-5, #ccc);
  background: var(--White, #fff);
  position: relative;
  margin: 16px 50px !important;
  width: auto;
  max-width: 792px;

  .mb-result-text4 {
    color: var(--black, #222);
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 162.5% */
  }

  .mb-result-text3 {
    color: var(--black, #222);
    text-align: center;
    /* PC/H5/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 160% */
  }

  .mb-result-text2 {
    display: flex;
    padding: 1px 6px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--neutral-2, #fafafa);
    white-space: nowrap;
    color: var(--black, #222);
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }

  .mb-result-pay-text1 {
    width: auto;
    display: flex;
    color: var(--white, #fff);
    text-align: center;
    /* PC/Bodyl/extrabold */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 162.5% */
  }

  .mb-result-pay-text2 {
    width: auto;
    border-radius: 4px;
    padding: 4px 12px;

    background: var(--tag-green, #287c58);
  }

  .mb-pay-stick1 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 60px 5px 0;
    width: 100%;
    border-radius: 40px;
    background: var(--tag-orange, #ff7e47);
    display: flex;
    justify-content: flex-end;
  }

  .mb-pay-stick2 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 0px 5px 50px;
    width: 60%;
    border-radius: 40px;
    background: var(--yeloow, #fce500);
    display: flex;
    justify-content: center;
  }

  .mb-pay-stick3 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 10px;
    width: 15%;
    border-radius: 40px;
    background: var(--neutral-3, #f5f5f5);
    display: flex;
    justify-content: center;
  }

  .mb-paybox-text {
    display: inline-flex;
    padding: 1px 6px;
    align-items: center;
    border-radius: 58px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }

  .mb-paybox1 {
    margin-left: -4%;
  }

  .mb-paybox2 {
    margin-left: 1%;
  }

  .mb-paybox3 {
    margin-left: 9%;
  }

  .mb-paybox4 {
    margin-left: 6%;
  }

  .mb-paybox5 {
    margin-left: 6%;
  }

  .mb-paybox6 {
    margin-left: 6%;
  }
}

.favorite-job {
  color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  margin-bottom: 24px;
  text-align: start;
}

.form-check {
  display: flex;
  max-width: 320px;
  color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
  /* PC/Bodyl/regular */
  // font-family: NanumSquare Neo;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  align-items: center;

  .form-check-input[type='checkbox'] {
    visibility: hidden;
    position: relative;
  }

  .form-check-input[type='checkbox']::before {
    content: '';
    visibility: visible;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }

  .form-check-input[type='checkbox']:checked::before {
    border: 1px solid #287c58;
    // border: none;
  }

  .form-check-input[type='checkbox']:checked::after {
    content: '';
    visibility: visible;
    display: block;
    width: 14px;
    height: 14px;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    border: 1px solid #287c58;
    cursor: pointer;
    position: absolute;
    background: #287c58;
  }

  // .form-check-input[type='checkbox']:checked::before {
  //   border: 1px solid #000;
  //   // border: none;
  // }
  .form-check-label {
    font-size: 16px;
    margin-left: 10px;
    flex: 1;
    text-align: start;
  }
}

.area-box {
  width: 100%;
  height: 40px !important;
  max-width: 320px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-4, #ebebeb);
  color: var(--black, #222);

  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.area-box {
  width: 100%;
  height: 40px !important;
  max-width: 320px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-4, #ebebeb);
  color: var(--black, #222);

  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.form-select:disabled {
  border-radius: 8px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--neutral-3, #f5f5f5) !important;
  color: var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2)) !important;

  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.form-check.on {
  color: var(--black, #222);
  /* PC/Bodyl/regular */
  // font-family: NanumSquare Neo;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  /* 162.5% */
}

.template-pickerroo {
  width: 210px;
  height: 205px;
  transform: rotate(33.335deg);
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  top: -100px;
  right: -55px;
  z-index: -1;
}

.template-pickerroo111 {
  width: 210px;
  height: 205px;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  top: -100px;
  right: -55px;
  z-index: -1;
}

.mb-template-pickerroo {
  width: 126px;
  height: 123px;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.mb-template-pickerroo111 {
  width: 126px;
  height: 123px;
  flex-shrink: 0;
  transform: rotate(33.335deg);
  object-fit: cover;
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.job-pickerroo {
  width: 214px;
  height: 234px;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  bottom: 40px;
  left: -130px;
  z-index: -1;
}

.template-pickerroo-hand {
  width: 210px;
  height: 205px;
  transform: rotate(33.335deg);
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  top: 40px;
  right: -50px;
  z-index: 1;
}

.flex-col-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.subscript {
  color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.start-email {
  display: flex;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--neutral-7, #8f8f8f);

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 160% */
}

.btn20 {
  display: flex;
  height: 64px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--yeloow, #fce500);
  color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 160% */
}

.mb-btn20 {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--yeloow, #fce500);
  color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 160% */
}

.btn21 {
  display: flex;
  height: 64px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--black, #222);
  color: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  /* 160% */
}

.mb-btn21 {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--black, #222);
  color: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 160% */
}

.btn22 {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--neutral-7, #8f8f8f);

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 160% */
}

.mb-btn22 {
  display: flex;
  height: 48px;

  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--neutral-7, #8f8f8f);

  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 160% */
}

.template-btn-text {
  /* PC/H5/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px !important;
  font-style: normal;
  display: flex;
  align-items: center;
}

// .email-start {
//   margin-top: 8px !important;
// }

.kakao-btn {
  padding: 18px;
  // max-width: 344px;
}

.task-name {
  color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
  margin-left: 20px;
}

.loader-template {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  // background-color: yellow;
  position: fixed;
  left: 0;
  top: 0;

  .pickerroo-img {
    width: 240px;
    height: 240px;
  }

  .text1 {
    color: var(--black, #000);

    /* PC/H5/bold */
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
  }

  .text2 {
    color: var(--black, #000);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }
}

.login {
  .gender-wrap {
    .item {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 4px;
      height: 42px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      font-size: 16px;
      // line-height: 23px;
      color: #ccc;
      cursor: pointer;
    }

    .item.active {
      background: var(--yeloow, #fce500);
      color: #262327;
    }
  }
}

.mb-login {
  .gender-wrap {
    .item {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 16px;
      gap: 4px;
      height: 42px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      font-size: 16px;
      // line-height: 23px;
      color: #ccc;
      cursor: pointer;
    }

    .item.active {
      background: var(--yeloow, #fce500);
      color: #262327;
    }
  }
}

.review-modal-body {
  .review-title {
    display: flex;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: center;

    .title1 {
      color: var(--black, #222);

      /* PC/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      /* 150% */
    }

    .cancleBtn {
      width: 20px;
      height: 20px;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: #ebebeb;
  }

  .text1 {
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .text2 {
    margin-left: 4px;
    color: var(--tag-orange, #ff7e47);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .input2 {
    width: 100%;
    max-width: 320px;
    height: 40px !important;
  }

  .custom-select {
    height: 40px !important;
    display: flex;
    width: 100%;
    max-width: 320px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: #fff;
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .custom-select.on {
    color: var(--opacity-black-opacity-30, rgba(34, 34, 34, 0.3));
  }

  // .custom-select option {
  //   display: flex;
  //   padding: 8px 16px;
  //   align-items: center;
  //   gap: 4px;
  //   align-self: stretch;
  //   border-radius: 8px 8px 0px 0px;
  //   border: 1px solid var(--neutral-4, #ebebeb);
  //   background: #fff;
  // }
  .thumb-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
  }

  .text3 {
    color: var(--black, #222);

    /* Body_14/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .text4 {
    color: var(--black, #222);

    /* PC/H5/bold */
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
  }

  .input1 {
    position: relative;
    width: 100%;
    max-width: 302px;
    display: flex;
    padding: 9px 12px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--White, #fff);
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .input1::placeholder {
    color: var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .input4 {
    width: 100%;
    max-width: 302px;
  }

  .state-label1 {
    display: flex;
    padding: 5px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    background: var(--white, #fff);
    color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .state-label1.selected {
    border-radius: 8px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .text-input-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .text-input {
      width: 100%;
      padding: 8px 16px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      font-size: 16px;
      min-height: 68px; // 최소 높이 설정
      resize: vertical; // 세로 리사이즈 허용
    }

    .text-input2 {
      width: 100%;
      padding: 8px 16px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      font-size: 16px;
      min-height: 198px; // 최소 높이 설정
      resize: vertical; // 세로 리사이즈 허용
    }

    .character-counter {
      position: absolute;
      bottom: -25px;
      right: 25px;
      color: var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));
      text-align: right;

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }
  }

  .text5 {
    color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .file-upload-container {
    display: flex;
    height: 100%;
    max-height: 80px;
    padding: 40px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px dashed var(--neutral-4, #ebebeb);
    background: var(--neutral-2, #fafafa);
    margin-bottom: 10px;

    &:hover {
      border-color: #aaa;
    }

    .file-input {
      display: none; // input 타입 file 숨기기
    }

    .upload-icon {
      font-size: 24px; // 아이콘 크기 조절
    }

    .upload-instructions {
      // margin-top: 4px;
      color: var(--black, #222);
      text-align: center;

      /* PC/Bodym/bold */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
    }
  }

  .file-details {
    display: flex;
    gap: 8px;

    .file-name {
      color: var(--funtion-blue-light, #1890ff);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .file-icon {
      width: 14px;
      height: 14px;
    }
  }
}

.review-modal-footer {
  .confirmBtn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--yeloow, #fce500) !important;
    color: var(--black, #222) !important;

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }
}

.home.main {
  background-color: #f5f4fb;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.home {
  .bottom-banner {
    width: 100%;
    height: 100%;
    max-width: 1248px;
    max-height: 380px;
    border-radius: 40px;
    background: #d9d9d9;
    overflow: hidden;
  }

  .mb-bottom-banner {
    width: 100%;

    border-radius: 16px;
    background: #d9d9d9;
    overflow: hidden;
  }

  .home-swiper-banner {
    width: 100%;
    max-width: 1248px;
    height: 100%;
    max-height: 470px;

    .my-swiper-container {
      border-radius: 40px !important;
      position: relative;
      overflow: visible !important;

      .swiper-slide {
        flex-shrink: 0;
        width: 1248px;
        height: 470px;
        border-radius: 40px !important;

        // overflow: hidden !important;
        .banner {
          border-radius: 40px !important;
          width: 100%;
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 20px !important;
        height: 20px !important;
        border-radius: 50%;
        position: absolute;
        top: 50% !important;
        transform: translateY(-50%) !important;
        z-index: 12341245124 !important;
        cursor: pointer !important;
        background-color: #efefef !important;
      }

      .swiper-button-prev {
        left: 15px !important;
        background: url(../../public/img/navigate-left.png) no-repeat center;
      }

      .swiper-button-next {
        right: 15px !important;
        background: url(../../public/img/navigate-right.png) no-repeat center;
      }

      /* Navigation buttons disabled state */
      .swiper-button-disabled {
        opacity: 0.3 !important;
        cursor: not-allowed !important;
      }

      /* Scrollbar and progress bar */
      .swiper-scrollbar {
        background: rgba(255, 255, 255, 0.5) !important;
        border-radius: 10px !important;
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 10px !important;
        height: 4px !important;
      }

      .swiper-scrollbar-drag {
        background: #000 !important;
        height: 4px !important;
        border-radius: 10px !important;
      }

      /* Navigation Buttons */
    }
  }

  .home-banner {
    width: 100%;
    padding: 100px 0;
    background-image: url(../assets/img/home_top_bg.svg);
    background-color: #f5f4fb;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .banner-wrap {
    .matchseciotn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 80px 50px;
      gap: 10px;
      background: #d7e6ff;
      border-radius: 20px;

      .blockinf {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 40px;
        flex: 1;
      }

      .description {
        -webkit-user-select: auto;
        /* Safari */
        -ms-user-select: auto;
        /* IE 10 and IE 11 */
        user-select: auto;
        /* Standard syntax */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        width: 100%;
      }

      .blocktitle {
        font-weight: 900;
        font-size: 36px;
        line-height: 42px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #262327;
      }

      .destext {
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: #262327;
      }

      .gopagebtn {
        cursor: pointer;
        -webkit-user-select: auto;
        /* Safari */
        -ms-user-select: auto;
        /* IE 10 and IE 11 */
        user-select: auto;
        /* Standard syntax */
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        gap: 10px;
        background-color: transparent;
        border: 2px solid #262327;
        border-radius: 100px;

        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #262327;
      }

      .gopagebtn:hover {
        background-color: #262327;
        color: white;
        animation-name: matchseciotnbtn;
        animation-duration: 0.3s;
      }

      .markimg {
        width: 114.15px;
        height: 160px;
        background-image: url(../assets/img/home_top_match.svg);
        background-size: cover;
      }
    }

    .matchseciotn.jobtestblock {
      background: #fff491;

      .markimg {
        width: 200px;
        height: 185.9px;
        background-image: url(../assets/img/home_top_test.svg);
      }
    }

    @keyframes matchseciotnbtn {
      from {
        background-color: transparent;
        color: #262327;
      }

      to {
        background-color: #262327;
        color: white;
      }
    }
  }

  .section-padding {
    padding-bottom: 40px;
  }

  .section {
    background-color: white;
    position: relative;

    // padding: 100px 0;
    .contact h1 {
      // font-family: NanumSquare Neo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 32px;
    }

    .contract-wrap {
      padding: 32px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);
      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);

      .data-wrap {
        width: 100%;
      }
    }

    .submit-no-text {
      color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08));
      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      pointer-events: none;
      margin-top: 8px;
      margin-bottom: 22px;
    }

    .submit-text {
      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      margin-top: 8px;
      margin-bottom: 22px;
    }
  }

  .contract-bottom {
    background: var(--neutral-2, #fafafa);
    display: flex;
    justify-content: center;

    .bottom-group {
      padding: 56px 0;
      display: flex;
      gap: 64px;

      .bottom-group1 {
        .start-btn {
          display: flex;
          width: 240px;
          padding: 16px;
          border-radius: 16px;
          background-color: #fce500;
          justify-content: space-between;
          margin-top: 24px;

          .btn-text {
            // font-family: NanumSquare Neo;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            display: flex;
            align-items: center;
          }
        }
      }

      .bottom-header {
        color: var(--neutral-13, #000);
        /* PC/H3/bold */
        // font-family: NanumSquare Neo;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
      }

      .bottom1 {
        margin-top: 8px;
        color: var(--neutral-13, #000);
        /* PC/H5/regular */
        // font-family: NanumSquare Neo;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }

      .bottom2 {
        margin-top: 24px;
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;

        .bottom2-a {
          display: flex;

          .emphasis {
            color: var(--black, #222);
            /* PC/Bodyl/regular */
            // font-family: NanumSquare Neo;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
          }
        }
      }
    }
  }

  .mb-contract-bottom {
    background: var(--neutral-2, #fafafa);
    display: flex;
    justify-content: center;
    padding: 0 20px;

    .bottom-group {
      padding: 56px 0;
      display: flex;
      gap: 64px;

      .bottom-group1 {
        .start-btn {
          max-width: 160px;
          display: flex;
          padding: 10px 16px;
          align-items: center;
          gap: 24px;
          border-radius: 8px;
          background: var(--yeloow, #fce500);

          .btn-text {
            color: var(--black, #222);

            /* Mo/Bodym/bold */
            // font-family: NanumSquare Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            /* 166.667% */
          }
        }
      }

      .bottom-header {
        color: var(--neutral-13, #000);
        /* PC/H3/bold */
        // font-family: NanumSquare Neo;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      .bottom1 {
        margin-top: 8px;
        color: var(--neutral-13, #000);
        /* PC/H5/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .bottom2 {
        margin-top: 16px;
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .bottom2-a {
          display: flex;

          .emphasis {
            color: var(--black, #222);
            /* PC/Bodyl/regular */
            // font-family: NanumSquare Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }

  .edumainitem {
    position: sticky;
    top: 100px;
    cursor: pointer;
    user-select: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;

    .educontext {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      gap: 20px;

      .eduinfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
      }

      .eduinfo .head {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .eduinfo .head .toporder {
        font-weight: 900;
        font-size: 40px;
        line-height: 58px;
        color: #262327;
      }

      .eduinfo .head .company {
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #262327;
      }

      .eduinfo .edutitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 46px;
        color: #262327;
      }

      .taglist {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;
      }

      .taglist .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 10px;
        background: #eeeeee;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }
    }

    .introimg {
      width: 100%;
      height: 340px;

      background: #eeeeee;
      /* border-radius: 0px 0px 20px 20px;   */
      background-size: cover;
    }
  }

  .edumainitem.subitem {
    position: relative;
    top: 0px;
    margin-bottom: 20px;

    .educontext {
      padding: 20px 30px;

      .eduinfo {
        .edutitle,
        .head .toporder {
          font-size: 24px;
        }
      }
    }
  }

  //
  .pickIt_select {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;

    .educompany {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 30px;
      gap: 20px;
      width: 305px;
      background: #ffffff;
      border-radius: 20px;
      cursor: pointer;

      .logobg {
        box-sizing: border-box;
        width: 96px;
        height: 96px;
        border: 1px solid #cccccc;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .logobg img {
        width: 56px;
        height: 53.29px;
      }

      .todayinf {
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .todayinf .companyname {
        font-weight: 700;
        font-size: 20px;
        color: #262327;
      }

      .todayinf .lesscnt {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 14px;
        background: #1d1693;
        border-radius: 100px;

        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }

      .todayinf .lesscnt span {
        font-weight: 700;
      }
    }
  }

  .big-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    background: #f9e000;
    border-radius: 100px;

    font-weight: 400;
    font-size: 20px !important;
    color: #262327;

    span {
      font-weight: 700;
      margin-left: 6px;
    }
  }

  .big-btn:hover {
    background: #262327;
    color: white;
  }

  // banner
  .bannerslide {
    border-radius: 20px;
    overflow: auto !important;

    .swiper-button-next {
      position: absolute !important;
      width: 30% !important;
      height: 100% !important;
      top: 0px !important;
      right: 0px !important;
      cursor: url(../assets/img/ico_next_s.cur), pointer !important;
      margin: 0px !important;
      display: block !important;
    }

    .swiper-button-next::after {
      content: none !important;
    }

    .swiper-button-prev {
      position: absolute !important;
      width: 30% !important;
      height: 100% !important;
      top: 0px !important;
      left: 0px !important;
      cursor: url(../assets/img/ico_prev_s.cur), pointer !important;
      margin: 0px !important;
      display: block !important;
    }

    .swiper-button-prev::after {
      content: none !important;
    }

    .swiper-button-lock {
      display: none !important;
    }

    //
    .banner {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      background-size: cover;

      .banner_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .banner_info .banner_title {
        font-weight: 900;
        font-size: 44px;
        line-height: 64px;
        letter-spacing: -0.02em;
        color: #ffffff;
      }

      .banner_info .banner_option {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 6px;

        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  // 알아두면 쓸모 있는 IT 직무의 모든 정보, 직무 백서
  .jobpaper-list.pickIt_select {
    gap: 40px;
  }

  .jobpaper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    max-width: 25%;

    .job-logo {
      height: auto;
      background-color: #f9e000;
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .paperinfo {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      gap: 20px;
      width: 100%;
      flex: 1;

      .detailview {
        position: relative;
        cursor: pointer;
        user-select: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        gap: 10px;
        width: 100%;
        height: 44px;
        background-color: transparent;
        border: 1px solid #1d1693;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #1d1693;
      }

      .detailview:hover {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        background-color: #1d1693;
        color: white;
        border-radius: 39px 0px 39px 0px;
      }

      .detailview::before {
        top: calc(50% + 2px);
        right: calc(50% - 45px);
        width: 21px;
        content: '';
        position: absolute;
        display: block;
        background: #1d1693;
        height: 1px;
      }

      .detailview::after {
        top: calc(50%);
        width: 5px;
        right: calc(50% - 45px);
        transform: rotate(45deg);
        content: '';
        position: absolute;
        display: block;
        background: #1d1693;
        height: 1px;
      }

      .detailview:hover::before {
        background: white;
      }

      .detailview:hover::after {
        background: white;
      }

      .detailview > div {
        width: 21px;
        height: 1px;
      }

      .papercontxt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        width: 100%;
      }

      .papercontxt .papertxt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
      }

      .papercontxt .papertxt .papgename {
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #262327;
      }

      .papercontxt .papertxt .papgedes {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
      }

      .papercontxt .papertag {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;
        flex-wrap: wrap;
      }

      .papercontxt .papertag .tag {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 6px 20px;
        gap: 10px;
        background: #eeeeee;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }
    }
  }

  // bottom
  .bottomblock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 20px;

    .btmitem {
      cursor: pointer;
      user-select: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 20px;
      flex: 1;
      height: 200px;
      background-color: #f5f4fb;
    }

    .btm-mark {
      width: 56px;
      height: 56px;
      background-size: 56px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .blockname {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: #262327;
    }
  }

  // reivew
  .reviewBlock {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px;
    gap: 20px;
    width: 100%;
    background: #f9e000;
    border-radius: 20px;
    margin-top: 100px;
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    color: #262327;

    img {
      width: 128px;
      height: 104px;
    }

    .writervbtn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 40px;
      border: 2px solid #262327;
      border-radius: 100px;
      background-color: transparent;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      color: #262327;
    }

    .writervbtn:hover {
      background-color: #262327;
      color: white;
    }
  }

  //
  .bottom-search {
    .img1 {
      width: 200px;
    }

    .img2 {
      width: 128px;
    }

    h1 {
      line-height: 64px;
    }

    .btn {
      height: 88px;
      border-radius: 10rem;
      font-size: 32px !important;
    }

    .form-control {
      height: 88px;
      font-size: 24px !important;
      padding: 10px 40px;
    }

    input::placeholder {
      font-size: 24px !important;
    }

    .btn-search {
      top: 21px;
      right: 26px;
    }
  }

  .menu-box1 {
    .menu1 {
      display: flex;
      width: 100%;
      max-width: 120px;
      padding: 12px 32px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      color: var(--neutral-7, #8f8f8f);
      text-align: center;

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */

      cursor: pointer;
    }

    .mobile-menu-box {
      display: flex;
      gap: 10px;
      width: 100vw;
      padding: 0 20px;
    }

    .mobile-menu1 {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      color: var(--neutral-7, #8f8f8f);
      text-align: center;

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */

      cursor: pointer;
    }
  }

  .home-list {
    .home-list1 {
      .text1 {
        color: var(--opacity-black-opacity-70, rgba(34, 34, 34, 0.7));

        /* PC/H4/light */
        // font-family: NanumSquare Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 350;
        line-height: 36px;
        /* 150% */
      }

      .card1 {
        width: 100%;
        max-width: 384px;
        justify-content: space-between;

        .card-img1 {
          width: 100%;
          height: 100%;
          max-height: 178px;
          border-radius: 16px;
          overflow: hidden;
        }

        .card-img2 {
          width: 100%;
          height: 100%;
          max-height: 286px;
          border-radius: 16px;
          overflow: hidden;
        }

        .text1 {
          color: var(--black, #222);

          /* PC/H5/regular */
          // font-family: NanumSquare Neo;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          /* 160% */
        }

        .text2 {
          color: var(--neutral-7, #8f8f8f);

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }
      }

      .btn1 {
        display: flex;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 16px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* PC/H4/bold */
        // font-family: NanumSquare Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
      }
    }

    .home-list2 {
      .text1 {
        color: var(--neutral-6, #b8b8b8);
        text-align: center;
        /* Body_16/regular-underline */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
        text-decoration-line: underline;
      }

      .home-list2-label {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        border: 1px solid var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));
        color: var(--black, #222);
        text-align: center;

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }
    }

    .home-list3 {
      .pick-intro {
        display: flex;
        height: 400px;
        width: 588px;
        padding: 32px 32px 24px 32px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 16px;
        border: 1px solid #e0e0e0;

        .text1 {
          color: var(--neutral-7, #8f8f8f);

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }

        .pick-btn {
          width: 100%;
          display: flex;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 10px;
          background: var(--yeloow, #fce500);
          color: var(--black, #222);

          /* PC/Bodyl/bold */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%;
          /* 25.6px */
        }

        .list3-card .edu-item {
          margin-top: 0 !important;
          margin: 0 !important;
          max-height: 389px;
        }
      }
    }
  }
}

.collection {
  .nologin-popup {
    position: fixed;
    display: inline-flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    left: 50%;
    // transform: translateY(-50%);
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--yeloow, #fce500);
    background: var(--conditional-pop-over, #fff);
    z-index: 99999;

    /* shadow_1 */
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    color: #000;

    /* H4/light */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: 36px;

    /* 150% */
    .go-to-result {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 32px;
      border-radius: 16px;
      background: var(--yeloow, #fce500);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .mb-nologin-popup {
    position: fixed;
    display: inline-flex;
    width: 320px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    left: 50%;
    // transform: translateY(-50%);
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--yeloow, #fce500);
    background: var(--conditional-pop-over, #fff);
    z-index: 99999;

    /* shadow_1 */
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    color: #000;

    /* H4/light */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: 36px;

    /* 150% */
    .go-to-result {
      display: flex;
      padding: 10px;
      align-items: center;
      gap: 32px;
      border-radius: 16px;
      background: var(--yeloow, #fce500);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 162.5% */
    }
  }

  .banner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fce500;
    padding: 58px 0;
  }

  .mb-banner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fce500;
    padding: 24px 0;
  }

  .match-box {
    .jobtype-item {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--tag-light-green, #4cbb88);
      background: #edf7f3;
      color: var(--tag-green, #287c58);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .mb-jobtype-item {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 2px;
      border-radius: 8px;
      border: 1px solid var(--tag-light-green, #4cbb88);
      background: #edf7f3;
      color: var(--tag-green, #287c58);

      /* Mo/other/tag */
      // font-family: NanumSquare Neo;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      /* 160% */
    }

    .stack-itme {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-2, #fafafa);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .mb-stack-itme {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 2px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-2, #fafafa);
      color: var(--black, #222);

      /* Mo/other/tag */
      // font-family: NanumSquare Neo;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      /* 160% */
    }

    .info-modal {
      width: 24px;
      height: 24px;
    }

    .info {
      display: flex;
      position: relative;
      align-items: center;
      text-align: center;

      .cost-popup {
        position: absolute;
        top: 35px;
        right: -175px;
        border-radius: 8px;
        border: 1px solid var(--yeloow, #fce500);
        background: var(--white, #fff);
        /* shadow_1 */
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
        padding: 9px 16px;
        width: 189px;
        height: auto;
        z-index: 100;

        .main-text {
          color: var(--black, #222);
          /* PC/Bodym/bold */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 171.429% */
          text-align: start !important;
        }

        .text {
          color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
          text-align: start !important;
        }
      }
    }

    .card-body {
      max-width: 282px;
    }
  }

  .modify-btn {
    // max-width: 127px;
    white-space: nowrap;
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .mb-modify-btn {
    white-space: nowrap;

    // max-width: 91px;
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 171.429% */
  }

  .collection4 {
    margin-top: 72px !important;
  }

  .btn-start {
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--yeloow, #fce500) !important;
    font-size: 16px !important;
    color: #222222 !important;
    padding: 16px 24px;
    height: auto;
    width: 100%;
    margin-top: 48px !important;
  }

  .btn-start:hover {
    font-size: 16px;
    color: #fce500 !important;
    padding: 16px 24px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    background: #222222 !important;
  }

  .mb-btn-start {
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--yeloow, #fce500) !important;
    font-size: 12px !important;
    color: #222222 !important;
    padding: 12px 16px;
    height: auto;
    width: 100%;
    margin-top: 48px !important;
  }

  .mb-btn-start:hover {
    font-size: 12px;
    color: #fce500 !important;
    padding: 12px 16px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    background: #222222 !important;
  }

  .btn-prev {
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff) !important;
    font-size: 16px;
    color: var(--neutral-7, #8f8f8f) !important;
    padding: 16px 24px;
    height: auto !important;
    width: 150px;
  }

  .btn-prev:hover {
    font-size: 16px;
    color: #222222 !important;
    padding: 16px 24px;
    height: auto !important;
    width: 150px;
    border-radius: 10px;
    border: 1px solid var(--yeloow, #fce500);
    background: rgba(255, 214, 0, 0.4) !important;
  }

  .btn-next {
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--yeloow, #fce500) !important;
    font-size: 16px !important;
    color: #222222 !important;
    padding: 16px 24px;
    height: auto !important;
    width: 150px;
  }

  .btn-next:hover {
    font-size: 16px;
    color: #fce500 !important;
    padding: 16px 24px;
    height: auto !important;
    width: 150px;
    border-radius: 10px;
    background: #222222 !important;
  }

  .btn-next:disabled {
    font-size: 16px;
    color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
    padding: 16px 24px;
    height: auto !important;
    width: 150px;
    border-radius: 10px;
    background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
  }

  .mb-btn-prev {
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff) !important;
    font-size: 12px !important;
    color: var(--neutral-7, #8f8f8f) !important;
    padding: 12px 16px !important;
    height: auto !important;
    width: 136px !important;
  }

  .mb-btn-prev:hover {
    font-size: 12px !important;
    color: #222222 !important;
    padding: 12px 16px !important;
    height: auto !important;
    width: 136px !important;
    border-radius: 10px;
    border: 1px solid var(--yeloow, #fce500);
    background: rgba(255, 214, 0, 0.4) !important;
  }

  .mb-btn-next {
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--yeloow, #fce500) !important;
    font-size: 12px !important;
    color: #222222 !important;
    padding: 12px 16px !important;
    height: auto !important;
    width: 136px !important;
  }

  .mb-btn-next:hover {
    font-size: 12px !important;
    color: #fce500 !important;
    padding: 12px 16px !important;
    height: auto !important;
    width: 136px !important;
    border-radius: 10px;
    background: #222222 !important;
  }

  .mb-btn-next:disabled {
    font-size: 12px !important;
    color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
    padding: 12px 16px !important;
    height: auto !important;
    width: 136px !important;
    border-radius: 10px;
    background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
  }

  .collection-pickerroo {
    width: 214px;
    height: 234px;
    flex-shrink: 0;
    object-fit: cover;
    position: absolute;
    bottom: 32px;
    left: -130px;
    z-index: -1;
  }

  .mb-collection-pickerroo {
    width: 126px;
    height: 123px;
    flex-shrink: 0;
    object-fit: cover;
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .nickNm {
    color: var(--black, #222);
    /* PC/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    // line-height: 36px; /* 150% */
  }

  .nickNm-sub {
    color: var(--black, #222);
    /* PC/H4/light */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: 36px;
  }

  .btn-start:disabled {
    font-size: 16px;
    color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
    padding: 16px 24px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
  }

  .mb-btn-start:disabled {
    font-size: 12px;
    color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
    padding: 12px 16px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
  }

  .input-nick {
    // height: 80px;
    font-size: 16px !important;
    font-weight: 400;
    text-align: center;
    padding: 15px 16px;
  }

  .input-nick::placeholder {
    font-size: 16px !important;
    font-weight: 400;
  }

  .item1 {
    text-align: center;
    padding: 24px 32px;
    // border: 1px solid #eee;
    border-radius: 16px;
    cursor: pointer;
  }

  .item1.selected {
    border: 1px solid var(--yeloow, #fce500);
  }

  .item1:hover {
    border: 1px solid var(--yeloow, #fce500);
  }

  .item2 {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #eee;
    border-radius: 16px;
    cursor: pointer;
    margin-bottom: 8px;
    // text-align: left;
    justify-content: center;
  }

  .item2.on {
    background-color: rgba(252, 229, 0, 0.4);
  }

  @media (hover: hover) and (pointer: fine) {
    .item2:hover {
      background-color: rgba(252, 229, 0, 0.4);
    }
  }

  .item3 {
    display: flex;
    flex-wrap: wrap;

    div {
      border: 1px solid #ccc;
      border-radius: 10rem;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    div.on {
      background-color: $yellow;
      border-color: transparent;
    }
  }

  .item2.item5 {
    span {
      display: inline-block;
      width: 220px;
      text-align: left;
    }
  }

  .item2.item5.on,
  .item2.item5:hover {
    background-image: none;
  }

  .item2.item6 {
    span {
      display: inline-block;
      width: 200px;
      text-align: left;
    }
  }

  .item4 {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
  }

  .item4-text {
    color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .item4.on {
    background-color: rgba(252, 229, 0, 0.4);
  }

  @media (hover: hover) and (pointer: fine) {
    .item4:hover {
      background-color: rgba(252, 229, 0, 0.4);
    }
  }

  .mb-item4 {
    display: flex;
    padding: 12px 16px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    font-size: 12px;
    line-height: 20px;
  }

  .mb-item4-text {
    color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 171.429% */
  }

  .mb-item4.on {
    background-color: rgba(252, 229, 0, 0.4);
  }

  @media (hover: hover) and (pointer: fine) {
    .mb-item4:hover {
      background-color: rgba(252, 229, 0, 0.4);
    }
  }

  .item5 {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    max-width: 168px;

    .img1 {
      width: 104px;
      height: 104px;
    }

    .mb-img1 {
      width: 56px;
      height: 56px;
    }

    .text1 {
      color: var(--black, #222);
      text-align: center;

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }

    .text2 {
      color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
      text-align: center;

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }
  }

  .item5.on {
    border-radius: 16px;
    border: 1px solid var(--yeloow, #fce500);
    background: var(--white, #fff);
  }

  @media (hover: hover) and (pointer: fine) {
    .item4:hover {
      border-radius: 16px;
      border: 1px solid var(--yeloow, #fce500);
      background: var(--white, #fff);
    }
  }

  .tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 20px;
    background: #f1f1f1;
    border-radius: 100px;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
    margin: 0 10px 10px 0;

    img {
      cursor: pointer;
    }
  }

  .big-select {
    height: 75px;
    margin-bottom: 20px;
    background-size: 40px;
    padding: 20px;
  }
}

.jobkind {
  .mb-result-text4 {
    color: var(--black, #222);
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 162.5% */
  }

  .mb-result-text3 {
    color: var(--black, #222);
    text-align: center;
    /* PC/H5/bold */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 160% */
  }

  .mb-result-pay-text2 {
    width: auto;
    border-radius: 4px;
    padding: 4px 12px;

    background: var(--tag-green, #287c58);
  }

  .mb-pay-stick1 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 60px 5px 0;
    width: 100%;
    border-radius: 40px;
    background: var(--tag-orange, #ff7e47);
    display: flex;
    justify-content: flex-end;
  }

  .mb-pay-stick2 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 0px 5px 50px;
    width: 60%;
    border-radius: 40px;
    background: var(--yeloow, #fce500);
    display: flex;
    justify-content: center;
  }

  .mb-pay-stick3 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 10px;
    width: 15%;
    border-radius: 40px;
    background: var(--neutral-3, #f5f5f5);
    display: flex;
    justify-content: center;
  }

  .mb-paybox-text {
    display: inline-flex;
    padding: 1px 6px;
    align-items: center;
    border-radius: 58px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }

  .mb-paybox1 {
    margin-left: -4%;
  }

  .mb-paybox2 {
    margin-left: 1%;
  }

  .mb-paybox3 {
    margin-left: 11%;
  }

  .mb-paybox4 {
    margin-left: 8%;
  }

  .mb-paybox5 {
    margin-left: 10%;
  }

  .mb-paybox6 {
    margin-left: 8%;
  }

  .mb-login-after-btn {
    display: inline-flex;
    width: 213px;
    padding: 10px 16px;
    align-items: center;
    border-radius: 16px;
    background: var(--yeloow, #fce500);
  }

  .login-after-btn {
    display: inline-flex;
    padding: 16px;
    align-items: center;
    gap: 32px;
    border-radius: 16px;
    background: var(--yeloow, #fce500);
  }

  .bottom-box {
    width: 100%;
    max-width: 792px;
    display: flex;
    justify-content: center;

    .lets-go-home {
      display: flex;
      width: 368px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--neutral-7, #8f8f8f);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .form-check {
    display: flex;
    max-width: 320px;

    .form-check-input[type='checkbox'] {
      visibility: hidden;
      position: relative;
    }

    .form-check-input[type='checkbox']::before {
      content: '';
      visibility: visible;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #cccccc;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
    }

    .form-check-input[type='checkbox']:checked::before {
      border: 1px solid #287c58;
      // border: none;
    }

    .form-check-input[type='checkbox']:checked::after {
      content: '';
      visibility: visible;
      display: block;
      width: 14px;
      height: 14px;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      border: 1px solid #287c58;
      cursor: pointer;
      position: absolute;
      background: #287c58;
    }

    // .form-check-input[type='checkbox']:checked::before {
    //   border: 1px solid #000;
    //   // border: none;
    // }
    .form-check-label {
      font-size: 14px;
      margin-left: 10px;
      flex: 1;
      text-align: start;
    }
  }

  .maskblock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .button-group {
    display: flex;
    justify-content: space-between;

    .btn-prev {
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff) !important;
      font-size: 16px;
      color: var(--neutral-7, #8f8f8f) !important;
      padding: 16px 24px;
      height: auto !important;
      width: 150px;
    }

    .btn-prev:hover {
      font-size: 16px;
      color: #222222 !important;
      padding: 16px 24px;
      height: auto !important;
      width: 150px;
      border-radius: 10px;
      border: 1px solid var(--yeloow, #fce500);
      background: rgba(255, 214, 0, 0.4) !important;
    }

    .btn-next {
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--yeloow, #fce500) !important;
      font-size: 16px;
      color: #222222 !important;
      padding: 16px 24px;
      height: auto !important;
      width: 150px;
    }

    .btn-next:hover {
      font-size: 16px;
      color: #fce500 !important;
      padding: 16px 24px;
      height: auto !important;
      width: 150px;
      border-radius: 10px;
      background: #222222 !important;
    }

    .btn-next:disabled {
      font-size: 16px;
      color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
      padding: 16px 24px;
      height: auto !important;
      width: 150px;
      border-radius: 10px;
      background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
    }
  }

  .result-text1 {
    color: var(--opacity-black-opacity-30, rgba(34, 34, 34, 0.3));
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .sharing-btn-kakao {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #fce500;
    position: relative;
  }

  .sharing-btn-link {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #287c58;
    position: relative;
  }

  .kakaoIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .linkIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .result-line {
    padding: 1px 0px;
    background-color: #ebebeb;
    width: 100%;
  }

  .result-text2 {
    display: flex;
    padding: 0 8px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--neutral-2, #fafafa);
    white-space: nowrap;
    color: var(--black, #222);
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .result-text3 {
    color: var(--black, #222);
    text-align: center;
    /* PC/H5/bold */
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
  }

  .result-text4 {
    color: var(--black, #222);
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .result-img1 {
    width: 14px;
    height: 14px;
    margin-top: 4px;
  }

  .pay-stick {
    position: relative;
  }

  .result-pay-text1 {
    width: auto;
    display: flex;
    color: var(--white, #fff);
    text-align: center;
    /* PC/Bodyl/extrabold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .result-pay-text2 {
    width: auto;
    border-radius: 4px;
    padding: 4px 12px;

    background: var(--tag-green, #287c58);
  }

  .pay-stick1 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 100px 5px 0;
    width: 100%;
    border-radius: 40px;
    background: var(--tag-orange, #ff7e47);
    display: flex;
    justify-content: flex-end;
  }

  .pay-stick2 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 0px 5px 50px;
    width: 60%;
    border-radius: 40px;
    background: var(--yeloow, #fce500);
    display: flex;
    justify-content: center;
  }

  .pay-stick3 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 10px;
    width: 15%;
    border-radius: 40px;
    background: var(--neutral-3, #f5f5f5);
    display: flex;
    justify-content: center;
  }

  .paybox-text {
    display: inline-flex;
    padding: 2px 10px;
    align-items: center;
    border-radius: 58px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
  }

  .paybox1 {
    margin-left: 2%;
  }

  .paybox2 {
    margin-left: 5%;
  }

  .paybox3 {
    margin-left: 11%;
  }

  .paybox4 {
    margin-left: 15%;
  }

  .paybox5 {
    margin-left: 15%;
  }

  .paybox6 {
    margin-left: 10%;
  }

  .result-retryBtn {
    display: flex;
    width: 368px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--yeloow, #fce500);
    background: var(--white, #fff);
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }
}

.matching-test-bottom {
  opacity: 0.85;
  background: var(--black, #222);
  padding: 80px 0;

  .bottom-text1 {
    color: var(--white, #fff);
    text-align: center;
    /* PC/H2/bold */
    // font-family: NanumSquare Neo;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* 160% */
  }

  .bottom-btn1 {
    display: flex;
    width: 368px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .bottom-btn1:hover {
    border-radius: 10px;
    background: black;
    color: var(--yeloow, #fce500);
  }

  .bottom-btn2 {
    display: flex;
    width: 368px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--neutral-7, #8f8f8f);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .bottom-btn2:hover {
    border-radius: 10px;
    border: 1px solid var(--yeloow, #fce500);
    background: rgba(255, 214, 0, 0.4);
    color: black;
  }
}

.findjob {
  .top-banner {
    width: 100%;
    height: 100%;
    max-height: 200px;
    background: var(--yeloow, #fce500);

    .banner-box {
      justify-content: center;
      align-items: center;

      .text-group1 {
        color: var(--black, #222);

        /* PC/H2/light */
        // font-family: NanumSquare Neo;
        font-size: 2.5vw;
        font-style: normal;
        font-weight: 350;
        line-height: 64px;
        /* 160% */
        white-space: nowrap;
      }
    }
  }

  .job-box {
    position: -webkit-sticky;
    /* Safari 브라우저 지원 */
    position: sticky;
    top: 150px;
    /* 상단 고정 */
    z-index: 100;
    width: 100%;
    max-width: 334px;

    .text1 {
      color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .text2.on {
      color: var(--black, #222);

      /* PC/Bodyl/extrabold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .filter-box {
    width: 100%;
    max-width: 893px;

    .info-modal2 {
      position: relative;
      width: 24px;
      height: 24px;
    }

    .find-popup {
      position: absolute;
      top: 80px;
      left: 200px;
      display: flex;
      width: 189px;
      padding: 9px 16px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--yeloow, #fce500);
      background: var(--white, #fff);
      z-index: 999999;

      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    }

    .three-item-wrap {
      display: flex;
      flex-wrap: wrap;

      .edu-item {
        width: 31%;

        .item-img {
          height: 124px;
        }
      }
    }

    .three-item-wrap-mb {
      display: flex;
      flex-wrap: wrap;

      // margin: 0 -10px;
      .edu-item {
        width: 45%;

        .item-img {
          height: 124px;
        }
      }
    }

    .filter-box3 {
      display: flex;
      padding: 8px 16px;
      width: 120px;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      // background: #fff;
      color: var(--black, #222);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .stack-label2 {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-2, #fafafa);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
      height: 34px;
    }

    .filter-group {
      display: flex;
      width: 100%;
      max-width: 893px;
      padding: 24px;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      background: var(--Neutral-3, #f5f5f5);

      .search-input {
        height: auto !important;
      }

      .stack-label {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 21px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* Body_14/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        // line-height: 24px; /* 171.429% */
      }

      .search-btn {
        display: flex;
        width: 120px;
        height: 48px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 16px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .reset-btn {
        display: flex;
        width: 120px;
        height: 48px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 16px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--white, #fff);
        color: var(--black, #222);

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: rgba(34, 34, 34, 0.1);
      }

      .select-label {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
        color: var(--black, #222);

        /* Body_14/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .select-label2 {
        height: 28px;
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* Body_14/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .select-label.on {
        background: var(--yeloow, #fce500);
      }

      .label-section-line {
        color: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
      }

      .course-period {
        width: 140px;
        height: 40px !important;
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid var(--neutral-4, #ebebeb);
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .addBtn {
        display: flex;
        padding: 8px 20px;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }
    }
  }

  .filter-wrap {
    .white-bg {
      background-color: white !important;
    }

    .filterrow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 10px;
      margin-bottom: 10px;
    }

    .filteritem {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 20px;
      flex: 1;
      height: 60px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 16px;
    }

    .filterrow.three {
      flex-wrap: wrap;

      .filteritem {
        width: 32.8%;
        flex: unset;
      }
    }

    .filteritem.area-item {
      display: none;
    }

    .filterrow.open-filter .filteritem.area-item {
      display: flex;
    }

    .filteritem.input {
      justify-content: space-between;

      input {
        flex: 1;
        border: none;
        margin-left: 10px;
        padding: 0;
      }
    }

    .radio-check .form-check-label {
      font-size: 16px;
      font-weight: 700;
      margin-top: 3px;
    }

    .filterlabel {
      width: 80px;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      text-align: left;
      color: #262327;
    }

    .filtervalrow {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #262327;
    }

    .filterempval {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #999999;
    }

    .filtervallist {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 6px;
    }

    .filtertag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 10px;
      background: #eeeeee;
      border-radius: 100px;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #262327;
    }

    .tagremaincount {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #262327;
    }
  }

  .jobmodalbg {
    width: 100%;
    height: 100%;
    min-width: 1920px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    .jobmodalhead {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
    }

    .jobmodaltitle {
      flex: 1;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #262327;
    }

    .jobmodaltool {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 10px;
    }

    .jobmodal {
      position: absolute;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      gap: 20px;

      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 10px;
    }

    .m-header,
    .jobbottom {
      display: none;
    }

    //
    .jobfiltermodal {
      .jobwraparea {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        flex-wrap: wrap;
      }

      .joboffitem {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border: 1px solid #cccccc;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
      }

      .jobonitem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: #f9e000;
        border: 1px solid #f9e000;
        border-radius: 100px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
      }

      @media (hover: hover) and (pointer: fine) {
        .joboffitem:hover {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;
          background: #f9e000;
          border: 1px solid #f9e000;
          border-radius: 100px;
          font-weight: 700;
          font-size: 12px;
          line-height: 17px;
          color: #262327;
        }
      }
    }

    .jobclear {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 4px;
      background-color: #ffffff;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }

    .jobclear img {
      width: 24px;
      height: 24px;
    }

    .jobapply {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      background: #f9e000;
      border-radius: 100px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }

    .costfiltermodal {
      .costrangerow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .costoffitem {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border: 1px solid #cccccc;
        border-radius: 100px;
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
      }

      // .costoffitem:hover,
      .costonitem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: #f9e000;
        border: 1px solid #f9e000;
        border-radius: 100px;
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
      }

      .rangevalue {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 14px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 10px;
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #262327;
        width: inherit;
        color: #999999;
      }
    }

    .areafiltermodal {
      .areaselarea {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
      }

      .areseldes {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }

      .areselrow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .areselrow select {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 14px;
        height: 50px;
        background: #ffffff;
        border-radius: 10px;

        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #262327;

        appearance: none;
        background-image: url(../assets/img/i_down_arrow.png);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 14px);
        background-position-y: center;
        border: 1px solid #ccc;
      }

      .selarerow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .selaretag {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 10px;
        gap: 10px;
        background: #f1f1f1;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
      }
    }

    .methodfiltermodal {
      .methodlistarea {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
      }

      .methodoffitem {
        box-sizing: border-box;
        width: 110px;
        height: 37px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 1px solid #cccccc;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        cursor: pointer;
      }

      .methodoffitem:hover,
      .methodonitem {
        box-sizing: border-box;
        width: 110px;
        height: 37px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f9e000;
        border: 1px solid #f9e000;
        border-radius: 100px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
        cursor: pointer;
      }

      .methodrow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .methodkeydes {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }
    }

    .schedule {
      .react-datepicker {
        border: none;
        width: 100%;
      }

      .react-datepicker__month-container {
        width: 100%;
      }

      .react-datepicker__current-month {
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #262327;
      }

      .react-datepicker__day-name {
        padding: 4px 0px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
        margin: 0px;
      }

      .react-datepicker__navigation {
        top: -3px;
      }

      .react-datepicker__navigation--previous span {
        display: none;
      }

      .react-datepicker__navigation--previous {
        width: 32px;
        height: 32px;
        background-image: url(../assets/img/ico_prev_off.png);
        background-size: 32px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .react-datepicker__navigation--next {
        width: 32px;
        height: 32px;
        background-image: url(../assets/img/ico_next_off.png);
        background-size: 32px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .react-datepicker__navigation--next span {
        display: none;
      }

      .react-datepicker__header {
        background-color: white;
        padding: 0px;
        border: none;
      }

      .react-datepicker__day-names {
        margin-top: 10px;
        border: 1px solid #dddddd;
        border-bottom: none;
        display: flex;
        flex-direction: row;
      }

      .react-datepicker__month {
        border: 1px solid #dddddd;
        border-top: none;
        margin: 0px;
        background-color: #eeeeee;
        margin-top: 8px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        flex: 1;
      }

      .react-datepicker__week {
        display: flex;
        flex-direction: row;
      }

      .react-datepicker__day {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding: 0px 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
        background-color: white;
        margin: 0.5px;
      }

      .react-datepicker__day--outside-month {
        background: #f8f8f8;
        color: #999999;
      }

      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
        background: #fff8b9;
      }

      .react-datepicker__day--selected:hover {
        background: #f9e000;
      }

      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        background: #f9e000;
        font-weight: 700;
      }
    }

    .schedulefiltermodal {
      position: absolute;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      gap: 20px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 10px;

      .schedulemodalhead {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
      }

      .schedulemodaltitle {
        flex: 1;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #262327;
      }

      .schedulemodaltool {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .schedulelistarea {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
      }

      .schedulelistarea > div {
        width: 100%;
      }

      .datehead {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        width: 100%;
      }

      .dateheaddes {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }

      .dateseldate {
        height: 23px;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #262327;
      }

      .tabrow {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        width: 100%;
      }

      .taboffitem {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 2px solid #eeeeee;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
        background-color: white;
      }

      .tabonitem {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 2px solid #f9e000;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #262327;
        background-color: white;
        cursor: pointer;
      }

      .monthrow {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
      }

      .goprevmonth {
        width: 32px;
        height: 32px;
        background-image: url('../assets/img/ico_prev_off.png');
        background-size: 32px;
        background-position: center;
        background-color: white;
        cursor: pointer;
      }

      .gonextmonth {
        width: 32px;
        height: 32px;
        background-image: url('../assets/img/ico_next_off.png');
        background-size: 32px;
        background-position: center;
        background-color: white;
      }

      .monthlist {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .monthoffitem {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        border: 1px solid #cccccc;
        border-radius: 10px;
        background-color: white;
      }

      .monthonitem {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        border-radius: 10px;
        background: #f9e000;
        border: 1px solid #f9e000;
      }

      .monthyear {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
      }

      .monthval {
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #262327;
      }
    }

    .diffcultfiltermodal {
      .diffcultlistarea {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
      }

      .diffcultoffitem {
        box-sizing: border-box;
        width: 60px;
        height: 37px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 1px solid #cccccc;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        cursor: pointer;
      }

      .diffcultonitem {
        box-sizing: border-box;
        width: 60px;
        height: 37px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f9e000;
        border: 1px solid #f9e000;
        border-radius: 100px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
        cursor: pointer;
      }

      .diffcultrow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
      }

      .diffcultkeydes {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }
    }

    .techfiltermodal {
      .teachgrouparea {
        width: 100%;
        max-width: 480px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        gap: 20px;
        height: auto;
        max-height: 700px;
        overflow-y: auto;
      }

      // .teachgrouparea::-webkit-scrollbar {
      //     width: 0px;
      // }

      .teachgrouparea::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      }

      .teachgrouparea::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
      }

      .techgroupinf {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
      }

      .techgroupname {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
      }

      .techgroupitem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        width: 100%;
      }

      .techname {
        width: 180px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
      }

      .techlist {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
        flex-wrap: wrap;
      }

      .techoffitem {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border: 1px solid #cccccc;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
      }

      .techonitem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: #f9e000;
        border: 1px solid #f9e000;
        border-radius: 100px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #262327;
      }

      .devider {
        width: 100%;
        height: 1px;
        background: #eeeeee;
      }

      .close-btn {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1;
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--white, #fff);
        color: var(--neutral-7, #8f8f8f);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .confirm-btn {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1;
        border-radius: 10px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .accordion-box {
        width: 100vw;
        max-width: 425px;
        border-radius: 16px;
        border: 1px solid var(--neutral-4, #ebebeb);

        .accordion-item1 {
          // width: 100%;
          padding: 16px;
          border-bottom: 1px solid var(--neutral-4, #ebebeb);
        }

        .accordion-item1:last-child {
          border-bottom: none;
        }

        .img1 {
          width: 12px;
          height: 12px;
        }

        .text1 {
          color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }

        .stack-box {
          padding: 8px 16px 0 16px;
        }

        .stack-item {
          display: flex;
          padding: 1px 8px;
          align-items: center;
          gap: 3px;
          border-radius: 8px;
          border: 1px solid var(--neutral-5, #ccc);
          background: var(--neutral-2, #fafafa);
          color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .stack-item.on {
          border-radius: 8px;
          padding: 1px 8px;
          border: 1px solid var(--yeloow, #fce500);
          background: var(--yeloow, #fce500);
          color: var(--black, #222);
          /* Mo/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 133.333% */
        }
      }

      .filteritem {
        display: flex;
        width: 98%;
        padding: 12px;
        align-items: center;
        flex: 1 0 0;
        border-radius: 16px;
        background: var(--neutral-3, #f5f5f5);
        color: var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        /* 171.429% */
        .search-input {
          height: auto !important;
          background-color: inherit;
          padding: 0 !important;
          border: none !important;
        }
      }
    }

    .more-stack {
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--black, #222);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }
  }

  //
  .slider-wrap {
    .edu-item {
      display: flex;
      flex-direction: row;

      .item-img {
        width: 200px;
        height: 106px;
        border-radius: 10px;
        margin: 20px 0 0 20px;
      }

      .card-body {
        flex: 1;
        padding: 24px;
      }
    }
  }

  .middle-banner-wrap {
    height: 310px;
    overflow: hidden;
  }

  .four-item-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .edu-item {
      width: 23.1%;
    }
  }
}

.member-exit {
  .card-template5 {
    .text-filed {
      padding: 8px 16px !important;
    }

    .reason-box {
      margin-top: 16px;
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
    }

    .text7 {
      color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    border-radius: 16px 120px 16px 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    /* shadow_1 */
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 100px 0 !important;
    width: auto;
    max-width: 617px;
    min-width: 518px;

    .skip-btn {
      width: 1000vw;
      max-width: 387px;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--neutral-7, #8f8f8f);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .accordion-box {
      width: 100vw;
      max-width: 387px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);

      .accordion-item1 {
        padding: 16px;
        border-bottom: 1px solid var(--neutral-4, #ebebeb);
      }

      .accordion-item1:last-child {
        border-bottom: none;
      }

      .img1 {
        width: 12px;
        height: 12px;
      }

      .text1 {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .stack-box {
        padding: 8px 16px 0 16px;
      }

      .stack-item {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--neutral-2, #fafafa);
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .stack-item.on {
        border-radius: 8px;
        padding: 1px 8px;
        border: 1px solid var(--yeloow, #fce500);
        background: var(--yeloow, #fce500);
        color: var(--black, #222);
        /* Mo/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
      }
    }

    .button-group {
      .btn-prev {
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
        font-size: 16px;
        color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
        padding: 16px 24px;
        height: auto !important;
        width: 100%;
      }

      .btn-prev:hover {
        font-size: 16px;
        color: #222222 !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        border: 1px solid var(--yeloow, #fce500);
        background: rgba(255, 214, 0, 0.4) !important;
      }

      .btn-prev:disabled {
        font-size: 16px;
        color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
      }

      .btn-next {
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--yeloow, #fce500) !important;
        font-size: 16px !important;
        color: #222222 !important;
        padding: 16px 24px;
        height: auto !important;
        width: 100%;
      }

      .btn-next:hover {
        font-size: 16px;
        color: #fce500 !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        background: #222222 !important;
      }

      .btn-next:disabled {
        font-size: 16px;
        color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
      }
    }
  }

  .goodbye-container {
    background: var(--neutral-2, #fafafa);
    width: 100vw;
    height: 100vh;

    .goodbye {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .lets-go-home {
      display: flex;
      width: 100%;
      max-width: 368px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--neutral-7, #8f8f8f);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }
  }
}

.edu-detail {
  background-color: #fff;

  .nav-box {
    border-bottom: 1px solid rgba(34, 34, 34, 0.08);
    display: flex;
    justify-content: center;
  }

  .nav-tabs {
    display: flex;
    gap: 32px;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .nav-item {
    flex: none;
  }

  .nav-link {
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-bottom: none;
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .left-card {
    width: 282px;
    margin-top: 40px;
    // margin-left: auto;
    border-radius: 16px;
    // overflow: hidden;
    border: 1px solid var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    background: var(--white, #fff);
    /* shadow_1 */
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);

    .card-img {
      border-radius: 10px 10px 0px 0px;
    }

    .card-body {
      padding: 24px;

      .end-date {
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        color: var(--neutral-7, #8f8f8f);
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .bootNm {
        color: var(--black, #222);
        /* PC/H5/bold */
        // font-family: NanumSquare Neo;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 160% */
        margin-top: 8px;
        margin-bottom: 24px;
      }

      .boot-cost {
        display: flex;
        justify-content: space-between;
        color: var(--black, #222);
        text-align: center;
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        align-items: center;

        .info {
          display: flex;
          position: relative;
          color: var(--neutral-7, #8f8f8f);
          align-items: center;
          text-align: center;

          .cost-popup {
            position: absolute;
            top: 25px;
            left: 40px;
            border-radius: 8px;
            border: 1px solid var(--yeloow, #fce500);
            background: var(--white, #fff);
            /* shadow_1 */
            box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
            padding: 9px 16px;
            width: 189px;
            height: 114px;
            z-index: 100;

            .main-text {
              color: var(--black, #222);
              /* PC/Bodym/bold */
              // font-family: NanumSquare Neo;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              /* 171.429% */
              text-align: start !important;
            }

            .text {
              color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
              /* PC/Bodym/regular */
              // font-family: NanumSquare Neo;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              /* 171.429% */
              text-align: start !important;
            }
          }
        }

        .title {
          color: var(--neutral-7, #8f8f8f);
        }
      }

      .period {
        // width: 100%;
        display: flex;
        justify-content: flex-end;
        color: var(--neutral-7, #8f8f8f);
        text-align: right;
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .application {
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb) !important;
        background: var(--white, #fff);
        color: var(--black, #222) !important;
        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
      }

      .application:hover {
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #fce500) !important;
        background: var(--white, #fff);
        color: var(--black, #222) !important;
        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
      }
    }

    // .btn-warning:hover { background: $yellow !important; color: #262327 !important; }
  }

  .ing-boot {
    color: var(--black, #222);
    /* PC/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  .ing-boot-num {
    color: var(--orange, #ffa940) !important;
    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .logobg {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .logobg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .edu-banner {
    background-color: #525252;
    display: flex;
    justify-content: center;
    padding-top: 56px;

    .group1 {
      width: 100%;
      max-width: 1140px;
      position: relative;

      .banner-img {
        width: 371px;
        height: 184px;
        border-radius: 20px 20px 0px 0px;
        position: absolute;
        right: 40px;
        bottom: 0;
      }

      .text {
        margin-bottom: 58px;
      }
    }
  }

  .filter {
    display: flex;
    padding: 8px 16px !important;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-4, #ebebeb);
    width: 120px;
  }

  .edu-content {
    color: var(--white, #fff);
    /* PC/H3/bold */
    // font-family: NanumSquare Neo;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 150% */
    max-width: 581px;
    margin-bottom: 30px;
  }

  .detail-wrap {
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;

    // .logobg {
    //   box-sizing: border-box;
    //   width: 48px;
    //   height: 48px;
    //   border: 1px solid #cccccc;
    //   border-radius: 100px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   img {
    //     width: 31px;
    //     height: 31px;
    //   }
    // }

    .top-info {
      padding: 16px 24px 24px 24px;
      display: flex;
      gap: 24px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: #fff;
      margin-bottom: 60px;

      .icon {
        display: flex;
        align-items: flex-start;
        width: 32px;
        height: 32px;
      }

      .t1 {
        color: var(--black, #222);
        text-align: start;
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
        // display: flex;
      }

      .t2 {
        color: var(--black, #222);
        text-align: center;
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
      }

      .tech-item {
        padding: 1px 8px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #d9d9d9);
        background: var(--neutral-2, #fafafa);
        color: var(--black, #222);
        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .base-item {
      padding: 32px 24px;
      border-radius: 16px;
      background: var(--neutral-3, #f5f5f5);
      width: 100%;
      max-width: 282px;
      height: 100%;
      max-height: 240px;

      .item1 {
        padding: 30px;
        margin-bottom: 16px;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid var(--neutral-3, #f5f5f5);
        max-width: 104px;
      }

      .item2-group {
        display: flex;
        align-items: center;
        gap: 3px;
        margin-bottom: 3px;
        flex-wrap: wrap;
      }

      .item2-text {
        color: var(--black, #222);
        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .itme2 {
        padding: 1px 8px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #d9d9d9);
        background: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08));
        white-space: nowrap;

        .item2-img {
          margin-right: 5px;
          margin-bottom: 1px;
        }
      }
    }

    .precautions {
      padding: 9px 16px;
      border-radius: 8px;
      border: 1px solid #ffefbc;
      background: #fffdf2;
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 4px;

      .text2 {
        padding: 4px 8px;
        align-items: center;
        border-radius: 8px;
        background: #fff4db;
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        display: flex;
        align-items: center;
      }
    }

    .parent {
      position: relative;

      .pickerroo {
        position: absolute;
        left: -12%;
        bottom: 5%;
        width: 20%;
        z-index: 1;
      }

      .recommend-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--neutral-2, #fafafa);
        z-index: 2;
        border-radius: 16px;
      }
    }

    .recommend {
      padding: 40px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 56px;
      position: relative;
      z-index: 3;

      .pick-text {
        color: var(--orange, #ffa940);
        /* PC/H4/bold */
        // font-family: NanumSquare Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-top: 24px;
      }

      .text1 {
        margin-top: 16px;
      }
    }

    .introduce {
      padding: 32px 40px;
      margin: 16px 0;
      border-bottom: 1px solid var(--neutral-4, #ebebeb);

      .text1 {
        color: var(--black, #222);

        /* PC/H4/regular */
        // font-family: NanumSquare Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        /* 150% */
      }

      .text2 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
      }
    }

    .tab-nav {
      position: sticky;
      top: 80px;
      background: white;
      z-index: 8;
    }

    .category-wrap {
      border: 1px solid #eeeeee;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;
    }

    .benefit-wrap {
      background-color: #fff8b9;
      padding: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 89px;
        height: 100px;
      }

      .boolean {
        font-size: 20px;
        background-color: white;
        border-radius: 20px;
        position: relative;
        padding: 20px;
        width: 80%;
        white-space: pre-wrap;

        .arrow {
          position: absolute;
          top: 55%;
          left: -20px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .edu-notice-text1 {
      color: var(--neutral-7, #8f8f8f);
      text-align: center;

      /* Body_16/regular-underline */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
      text-decoration-line: underline;
    }

    .accordion.curry {
      .accordion-item {
        margin-bottom: 0px !important;
        border-radius: 0 !important;

        border: 0 !important;
        border-top: 1px solid #ebebeb !important;
        border-left: 1px solid #ebebeb !important;
        border-right: 1px solid #ebebeb !important;

        .title {
          color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));
        }

        .item1 {
          padding: 24px;
          border-radius: 8px;
          background: var(--neutral-2, #fafafa);
        }

        .answer-text {
          color: var(--black, #000);

          /* Body_16/bold */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          /* 162.5% */
        }
      }

      :first-child {
        border-radius: 10px 10px 0 0 !important;
      }

      :last-child {
        border-radius: 0 0 10px 10px !important;
      }

      .accordion-button {
        border: none !important;
        position: relative;
      }

      .accordion-button:not(.collapsed) {
        background-color: transparent;
      }

      .accordion-button:not(.collapsed)::after {
        background-image: var(--cui-accordion-btn-icon);
        position: absolute;
        left: 16px !important;
        width: 12px;
        height: 12px;
        background-size: contain;
        /* or 'cover' */
      }

      .accordion-button::after {
        background-image: var(--cui-accordion-btn-icon);
        position: absolute;
        left: 16px !important;
        width: 12px;
        height: 12px;
        background-size: contain;
        /* or 'cover' */
      }
    }

    .accordion.curry:last-child {
      border-bottom: 1px solid #ebebeb !important;
      border-radius: 0 0 10px 10px !important;
    }

    .lesson-reivew {
      .item {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;

        .thumb {
          width: 96px;
          max-height: 96px;
          border-radius: 4px;
        }
      }
    }

    .reivew-item {
      .content {
        flex: 1;
        background-color: #f1f1f1;
        border-radius: 10px;
        padding: 20px;
      }
    }

    .banner {
      .banner_title {
        font-size: 32px;
      }

      .banner_option {
        font-size: 20px;
      }
    }
  }

  .mypage-info {
    .title {
      color: var(--black, #222);
      /* PC/H3/bold */
      // font-family: NanumSquare Neo;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      /* 150% */
    }

    .reBtn {
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
    }

    .mb-group1 {
      display: flex;
      width: 100%;
      padding: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      border-radius: 16px;
      background: var(--neutral-3, #f5f5f5);

      .text1 {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text2 {
        display: flex;
        padding: 2px 10px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--white, #fff);
        white-space: nowrap;
      }
    }

    .group1 {
      display: flex;
      width: 45%;
      padding: 32px 24px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      border-radius: 16px;
      background: var(--neutral-3, #f5f5f5);

      .text1 {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text2 {
        display: flex;
        padding: 2px 10px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--white, #fff);
        white-space: nowrap;
      }
    }

    .group2 {
      display: flex;
      width: 52%;
      padding: 32px 24px;
      flex-direction: column;
      // justify-content: center;
      align-items: flex-start;
      gap: 24px;
      border-radius: 16px;
      background: var(--neutral-3, #f5f5f5);

      .text1 {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text3 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text4 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--funtion-blue-light, #1890ff);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-1 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--funtion-blue-light, #1890ff);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-2 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--tag-orange, #ff7e47);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-3 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--tag-light-green, #4cbb88);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-4 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--tag-green, #287c58);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text5 {
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        display: flex;
        padding: 2px 10px;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--white, #fff);
        white-space: nowrap;
      }
    }

    .mb-group2 {
      display: flex;
      width: 100%;
      padding: 16px;
      flex-direction: column;
      // justify-content: center;
      align-items: flex-start;
      gap: 12px;
      border-radius: 16px;
      background: var(--neutral-3, #f5f5f5);

      .text1 {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text3 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text4 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--funtion-blue-light, #1890ff);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-1 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--funtion-blue-light, #1890ff);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-2 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--tag-orange, #ff7e47);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-3 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--tag-light-green, #4cbb88);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text4-4 {
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 21px;
        background: var(--tag-green, #287c58);
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text5 {
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        display: flex;
        padding: 2px 10px;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--white, #fff);
        white-space: nowrap;
      }
    }

    .line {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      stroke-width: 1px;
      stroke: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08));
      height: 0px;
      align-self: stretch;
    }
  }

  .no-match {
    display: flex;
    width: 100%;
    max-width: 1140px;
    padding: 32px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: var(--neutral-3, #f5f5f5);

    .pickerro-no {
      margin-top: 16px;
      width: 128px;
      height: 140px;
    }

    .text1 {
      color: var(--black, #222);

      /* PC/H5/regular */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      /* 160% */
    }

    .text2 {
      color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
      text-align: center;

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .matchBtn {
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 109px;
    }
  }

  .three-item-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .edu-item {
      width: 31%;

      .item-img {
        height: 124px;
      }
    }
  }

  .three-item-wrap1 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .edu-item {
      width: 23%;

      .item-img {
        height: 124px;
      }
    }
  }

  .popover {
    position: absolute;
    top: -17px;
    left: 39%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 6px;

    .popover-arrow {
      position: absolute;
      left: -30px;
      transform: translate(105px, 0px);
    }

    .popover-body {
      font-size: 12px;
      color: white;
      padding: 5px 10px;
      text-align: center;
    }

    .popover-arrow::before {
      border-top-color: rgba(0, 0, 0, 0.7);
    }

    .popover-arrow::after {
      border-top-color: rgba(0, 0, 0, 0);
    }
  }

  .popover1 {
    position: absolute;
    top: -17px;
    left: 39%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 6px;

    .popover1-arrow {
      position: absolute;
      left: -50px;
      transform: translate(105px, 0px);
    }

    .popover1-body {
      font-size: 12px;
      color: white;
      padding: 5px 10px;
      text-align: center;
    }

    .popover1-arrow::before {
      border-top-color: rgba(0, 0, 0, 0.7);
    }

    .popover1-arrow::after {
      border-top-color: rgba(0, 0, 0, 0);
    }
  }

  .popover2 {
    position: absolute;
    top: 230px;
    left: 39%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 6px;

    // .popover-arrow2 {
    //   position: absolute;
    //   left: -30px;
    //   transform: translate(105px, 0px);
    // }
    .popover-body2 {
      font-size: 12px;
      color: white;
      padding: 5px 10px;
      text-align: center;
    }

    // .popover-arrow2::before {
    //   border-top-color: rgba(0, 0, 0, 0.7);
    // }
    // .popover-arrow2::after {
    //   border-top-color: rgba(0, 0, 0, 0);
    // }
  }

  .maskblock {
    background: rgba(255, 255, 255, 0.9);
  }

  #intro {
    ul {
      list-style: disc;
      padding-left: 40px;
    }
  }
}

.review-container {
  width: 100%;
  // background: var(--neutral-2, #fafafa);

  .review-first-write-btn {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .group1 {
    .group1-1 {
      .text1 {
        color: var(--black, #222);

        /* PC/H4/bold */
        // font-family: NanumSquare Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }

      .text2 {
        color: var(--orange, #ffa940);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .group1-2 {
      .text3 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .review-form {
    .label1 {
      display: flex;
      padding: 2px 10px;
      align-items: center;
      gap: 3px;
      border-radius: 58px;
      background: var(--neutral-4, #ebebeb);
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .text1 {
      color: var(--black, #222);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }

    .text2 {
      color: var(--black, #222);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text3 {
      color: var(--opacity-black-opacity-30, rgba(34, 34, 34, 0.3));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text4 {
      color: var(--black, #222);

      /* PC/Bodyl/extrabold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px;
      /* 162.5% */
    }

    .review-main {
      display: flex;
      width: 100%;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--White, #fff);

      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        overflow: revert;
      }

      .label2-1 {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--sunset-orange-3, #ffd591);
        background: var(--sunset-orange-1, #fff7e6);
        color: var(--funtion-warning, #ff9d00);

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        max-height: 25px;
      }

      .label2-2 {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--cyan-3, #87e8de);
        background: var(--cyan-1, #e6fffb);
        color: var(--cyan-6, #13c2c2);

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        max-height: 25px;
      }

      .label2-3 {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid #ea9b8c;
        background: #faf1f0;
        color: var(--tag-red, #ce472d);
        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        max-height: 25px;
      }

      .label2-4 {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--sunset-orange-3, #ffd591);
        background: var(--sunset-orange-1, #fff7e6);
        color: var(--funtion-warning, #ff9d00);

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        max-height: 25px;
      }

      .text1 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .text2 {
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text3 {
        color: var(--black, #222);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text4 {
        color: var(--orange, #ffa940);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text5 {
        color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
        text-align: center;

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text5-group {
        display: flex;
        flex-wrap: nowrap;
      }

      .text6 {
        color: var(--opacity-black-opacity-70, rgba(34, 34, 34, 0.7));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .line1 {
        width: 100%;
        padding: 1px;
        background: #ebebeb;
      }

      .group2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        height: 74px;
      }

      .group3 {
        display: flex;
        flex-direction: column;
        padding: 0 56px;
        gap: 16px;
        justify-content: flex-start;
        width: 100%;
      }

      .group4 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
        height: 74px;
      }

      .text7 {
        color: var(--black, #222);

        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text8 {
        color: var(--neutral-8, #7a7a7a);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        word-break: break-all;
      }

      .premium_review-rating-group {
        padding: 24px 40px;

        .text1 {
          color: var(--neutral-8, #7a7a7a);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .text2 {
          color: var(--orange, #ffa940);

          /* PC/H1/bold */
          // font-family: NanumSquare Neo;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 64px;
          /* 160% */
        }

        .text3 {
          color: var(--tag-green, #287c58);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .text4 {
          color: var(--black, #222);

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }

        .rating-bar {
          width: 100%;
          max-width: 400px;
        }

        .num-color {
          color: var(--orange, #ffa940);
        }

        .review-color {
          background-color: #fce500 !important;
        }

        .review-color.on {
          background-color: #ffa940 !important;
        }
      }

      .proportion-group {
        .proportion-graph {
          padding: 24px 0;
          width: 100%;
          max-width: 510px;

          .graph-box {
            width: 100%;
            max-width: 488px;
          }
        }

        .information-group {
          padding: 24px 0;

          .text1 {
            width: 78px;
            color: var(--neutral-7, #8f8f8f);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }

          .text2 {
            color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

            /* PC/Bodyl/bold */
            // font-family: NanumSquare Neo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
          }

          .text3 {
            color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

            /* PC/Bodys/regular */
            // font-family: NanumSquare Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 166.667% */
            width: 50%;
            text-align: center;
          }

          .text2.on {
            color: var(--tag-green, #287c58) !important;

            /* PC/Bodyl/bold */
            // font-family: NanumSquare Neo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
          }

          .text3.on {
            color: var(--tag-green, #287c58) !important;

            /* PC/Bodyl/bold */
            // font-family: NanumSquare Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 162.5% */
          }
        }
      }
    }

    .letsgo-boot {
      width: 100%;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .review-moreBtn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);

    .text1 {
      color: var(--neutral-7, #8f8f8f);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }

    .more-img {
      width: 16px;
      height: 16px;
    }
  }

  .write-review {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }
}

.company-detail {
  .popover {
    top: -57px;
    left: 11.2%;
  }

  .three-item-wrap .edu-item {
    width: 30.7%;
  }

  .logobg {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .eduCompany-banner {
    background-color: #525252;
    display: flex;
    align-items: center;

    .banner1 {
      width: 100%;
      max-width: 1140px;

      .thumb-img {
        cursor: pointer;
        box-sizing: border-box;
        width: 56px;
        height: 56px;
        border: 1px solid #cccccc;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 56px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .banner-text1 {
      color: var(--white, #fff);
      cursor: pointer;

      /* PC/H2/bold */
      // font-family: NanumSquare Neo;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      /* 160% */
    }

    .banner-text2 {
      color: var(--white, #fff);
      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
      max-width: 788px;
    }

    .banner-alarm-btn {
      margin-top: 56px;
      width: 114px;
      padding: 8px 20px;
      position: absolute;
      left: 0;
      top: 0;

      .alarm-text {
        border-radius: 8px !important;
        border: 1px solid var(--white, #fff) !important;
        color: var(--white, #fff) !important;

        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 700;
        width: 114px !important;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: auto !important;
        gap: 4px;
      }

      .alarm-img {
        width: 16px !important;
        height: 16px !important;
      }
    }

    .alarm-btn {
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--white, #fff);
      width: 114px;
      gap: 4px;
      margin-top: 48px;
      margin-bottom: 56px;
      cursor: pointer;
      position: relative;

      .alarm-text {
        color: var(--white, #fff);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }

      .alarm-img {
        width: 16px !important;
        height: 16px !important;
      }
    }

    .alarm-btn:hover {
      border: 1px solid #fce500;
    }

    .alarm-btn:hover .alarm-text {
      color: #fce500;
    }

    .alarm-btn:hover .alarm-img {
      width: 16px !important;
      height: 16px !important;
    }

    .alarm-btn1 {
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--white, #fce500);
      width: 114px;
      gap: 4px;
      margin-top: 48px;
      margin-bottom: 56px;
      cursor: pointer;
      position: relative;

      .alarm-text1 {
        color: var(--white, #fce500);
        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }

      .alarm-img1 {
        width: 16px !important;
        height: 16px !important;
      }
    }

    .alarm-btn1:hover {
      border: 1px solid #fff;
    }

    .alarm-btn1:hover .alarm-text1 {
      color: #fff;
    }

    .alarm-btn1:hover .alarm-img1 {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.withdrawal {
  color: var(--neutral-7, #8f8f8f);
  text-align: center;
  /* Body_16/regular-underline */
  // font-family: NanumSquare Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
  text-decoration-line: underline;
}

.mypage-alarm {
  color: var(--black, #222);

  /* PC/H3/bold */
  // font-family: NanumSquare Neo;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 150% */
}

.mypage-alarm-group {
  max-width: 894px;

  .nav-item {
    max-width: 143px;
  }

  .writing-review {
    color: var(--black, #222);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    border-bottom: 2px solid #222;

    .pencil_img {
      width: 16px;
      height: 16px;
      margin-bottom: 3px;
    }
  }

  .nav-link.active {
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--yeloow, #fce500);
    color: #292929;
    text-align: center;
    border: none;
    height: 42px;

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .nav-link {
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    height: 42px;
    white-space: nowrap;

    border: 1px solid var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));
    color: var(--black, #222);
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }
}

.mb-mypage-alarm-group {
  .nav-tabs {
    display: flex;
    gap: 8px;
    width: 100%;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }

  max-width: 894px;

  .nav-item {
    max-width: 143px;
  }

  .writing-review {
    color: var(--black, #222);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    border-bottom: 2px solid #222;

    .pencil_img {
      width: 16px;
      height: 16px;
      margin-bottom: 3px;
    }
  }

  .nav-link.active {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--yeloow, #fce500);
    color: #292929;
    text-align: center;
    border: none;
    height: 20px;

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 162.5% */
  }

  .nav-link {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    height: 20px;

    border: 1px solid var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));
    color: var(--black, #222);
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 162.5% */
  }
}

.white-paper {
  .text66 {
    color: var(--black, #222);
    text-align: center;
    /* PC/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    /* 150% */
  }

  .mb-text66 {
    color: var(--black, #222);
    text-align: left;
    /* PC/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 150% */
  }

  .login-after-btn {
    display: inline-flex;
    padding: 16px;
    align-items: center;
    gap: 32px;
    border-radius: 16px;
    background: var(--yeloow, #fce500);
  }

  .mb-login-after-btn {
    display: inline-flex;
    width: 213px;
    padding: 10px 16px;
    align-items: center;
    border-radius: 16px;
    background: var(--yeloow, #fce500);
  }

  .result-text1 {
    color: var(--opacity-black-opacity-30, rgba(34, 34, 34, 0.3));
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .sharing-btn-kakao {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #fce500;
    position: relative;
  }

  .sharing-btn-link {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #287c58;
    position: relative;
  }

  .kakaoIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .linkIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .result-line {
    padding: 1px 0px;
    background-color: #ebebeb;
    width: 100%;
  }

  .result-text2 {
    display: flex;
    padding: 0 8px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--neutral-2, #fafafa);
    white-space: nowrap;
    color: var(--black, #222);
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .result-text3 {
    color: var(--black, #222);
    text-align: center;
    /* PC/H5/bold */
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
  }

  .result-text4 {
    color: var(--black, #222);
    text-align: center;
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .result-img1 {
    width: 14px;
    height: 14px;
    margin-top: 4px;
  }

  .pay-stick {
    position: relative;
  }

  .result-pay-text1 {
    width: auto;
    display: flex;
    color: var(--white, #fff);
    text-align: center;
    /* PC/Bodyl/extrabold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .result-pay-text2 {
    width: auto;
    border-radius: 4px;
    padding: 4px 12px;

    background: var(--tag-green, #287c58);
  }

  .pay-stick1 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 100px 5px 0;
    width: 100%;
    border-radius: 40px;
    background: var(--tag-orange, #ff7e47);
    display: flex;
    justify-content: flex-end;
  }

  .pay-stick2 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 0px 5px 50px;
    width: 60%;
    border-radius: 40px;
    background: var(--yeloow, #fce500);
    display: flex;
    justify-content: center;
  }

  .pay-stick3 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 10px;
    width: 15%;
    border-radius: 40px;
    background: var(--neutral-3, #f5f5f5);
    display: flex;
    justify-content: center;
  }

  .paybox-text {
    display: inline-flex;
    padding: 2px 10px;
    align-items: center;
    border-radius: 58px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));
    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
  }

  .paybox1 {
    margin-left: 2%;
  }

  .paybox2 {
    margin-left: 5%;
  }

  .paybox3 {
    margin-left: 11%;
  }

  .paybox4 {
    margin-left: 15%;
  }

  .paybox5 {
    margin-left: 15%;
  }

  .paybox6 {
    margin-left: 10%;
  }

  .result-retryBtn {
    display: flex;
    width: 368px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--yeloow, #fce500);
    background: var(--white, #fff);
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .result-line {
    padding: 1px 0px;
    background-color: #ebebeb;
    width: 100%;
  }

  .steck-group {
    max-width: 400px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .job-list {
    // overflow-x: auto;
    // overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    gap: 24px;
    z-index: 1;
    max-width: 1400px;
    overflow: auto;
    margin-left: 590px;
    position: relative;
    scroll-snap-type: x mandatory;
    cursor: grab;

    .hidden {
      visibility: hidden !important;
    }

    .item {
      // opacity: 0.5;
      // scroll-snap-align: start; /* 스크롤 스냅 정렬 */
      transition: opacity 0.3s;
      text-align: center;
      width: 180px;
      height: 180px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);

      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
      transition: 0.3s ease;

      .txt {
        color: var(--black, #222);
        text-align: center;
        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }
    }

    // .item.active {
    //   opacity: 1; /* 활성 아이템 투명도 */
    // }

    // .item:nth-child(n + 5) {
    //   opacity: 0.5;
    // }
    .item:hover,
    .item.on {
      border-radius: 16px;
      border: 4px solid var(--yeloow, #fce500);
      background: var(--white, #fff);

      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .mb-job-list {
    // overflow-x: auto;
    // overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    gap: 12px;
    z-index: 1;
    // max-width: 1400px;
    overflow: auto;
    position: relative;
    scroll-snap-type: x mandatory;
    cursor: grab;

    .hidden {
      visibility: hidden !important;
    }

    .item {
      // opacity: 0.5;
      // scroll-snap-align: start; /* 스크롤 스냅 정렬 */
      transition: opacity 0.3s;
      text-align: center;
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);

      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
      transition: 0.3s ease;

      .txt {
        color: var(--black, #222);
        text-align: center;
        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 162.5% */
      }
    }

    // .item.active {
    //   opacity: 1; /* 활성 아이템 투명도 */
    // }

    // .item:nth-child(n + 5) {
    //   opacity: 0.5;
    // }
    .item:hover,
    .item.on {
      border-radius: 16px;
      border: 4px solid var(--yeloow, #fce500);
      background: var(--white, #fff);

      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .item:first-child {
    margin-left: 10px;
  }

  .item:last-child {
    margin-right: 10px;
  }

  .yellow-bg {
    background: var(--yeloow, #fce500);
    padding-top: 126px;
    padding-bottom: 169px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: -80px 0 -200px 0;

    .bg-text1 {
      width: 100%;
      max-width: 792px;
      justify-content: flex-start;
      color: var(--black, #222);

      /* PC/H3/bold */
      // font-family: NanumSquare Neo;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      /* 150% */
    }
  }

  .mb-yellow-bg {
    background: var(--yeloow, #fce500);
    padding-top: 188px;
    padding-bottom: 194px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: -90px 0 -200px 0;

    .bg-text1 {
      width: 100%;
      // max-width: 792px;
      justify-content: flex-start;
      color: var(--black, #222);

      /* PC/H3/bold */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 150% */
      margin-left: 30px;
    }

    .mb-sharing-btn-kakao {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: #fce500;
      position: relative;
      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
      // border: 1px solid rgba(34, 34, 34, 0.3);
    }

    .mb-sharing-btn-link {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: #287c58;
      position: relative;
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .job-list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  .info-wrap {
    padding: 80px;
    border-radius: 20px;
    text-align: center;
    background-color: white;
  }

  .maskblock2 {
    background-color: rgba(0, 0, 0, 0.4);

    .txt {
      padding: 10px;
      background-color: #262327;
      color: white;
      border-radius: 1px;
      text-align: center;
    }
  }

  .info-wrap2 {
    padding: 0 19rem;
  }

  .three-item-wrap .edu-item .item-img {
    height: 207px;
  }
}

.contactus {
  .data-wrap .label1 {
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  .img-wrap {
    display: flex;
    flex-wrap: wrap;

    .img-upload,
    .item {
      width: 100px;
      height: 100px;
      background-color: white;
      border-radius: 6px;
      margin: 0 10px 10px 0;
      position: relative;
      border-radius: 8px;
      border: 1px dashed var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);
    }

    .img-upload {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .text {
        color: var(--black, #222);
        text-align: center;
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .item {
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid #ccc;

      .btn-delete {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
}

.mb-contactus {
  .textarea1 {
    height: 32px !important;
    font-size: 12px !important;
    line-height: 20px !important;
    padding: 10px !important;
  }

  .textarea1::placeholder {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .top-banner {
    width: 100%;
    display: flex;
    padding: 24px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--yeloow, #fce500);

    .text1 {
      color: var(--black, #222);

      /* Mo/H2/regular */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      /* 150% */
    }

    .text2 {
      color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
      text-align: center;
      /* Mo/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }
  }

  .data-wrap .label1 {
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  .img-wrap {
    display: flex;
    flex-wrap: wrap;

    .img-upload,
    .item {
      width: 90px;
      height: 90px;
      background-color: white;
      border-radius: 6px;
      margin: 0 10px 10px 0;
      position: relative;
      border-radius: 8px;
      border: 1px dashed var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);
    }

    .img-upload {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .text {
        color: var(--black, #222);
        text-align: center;
        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .item {
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid #ccc;

      .btn-delete {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }

  .mb-section {
    background-color: white;
    position: relative;

    // padding: 100px 0;
    .contact h1 {
      // font-family: NanumSquare Neo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 32px;
    }

    .contract-wrap {
      padding: 16px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);
      /* shadow_1 */
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);

      .data-wrap {
        width: 100%;
      }
    }

    .submit-no-text {
      color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08));
      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      pointer-events: none;
      height: auto !important;
      padding: 12px 16px;
      // margin-top: 8px;
      // margin-bottom: 22px;
    }

    .submit-text {
      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      height: auto !important;
      padding: 12px 16px;

      // margin-top: 8px;
      // margin-bottom: 22px;
    }
  }
}

.event {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .home-slide {
    width: 82%;
    margin: auto;
  }

  .edu-item .item-img {
    height: 320px;
  }

  .event-banner {
    width: 100%;
    display: flex;
    height: 176px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--black, #222);

    .text1 {
      color: var(--white, #fff);

      /* PC/H2/light */
      // font-family: NanumSquare Neo;
      font-size: 40px;
      font-style: normal;
      font-weight: 350;
      line-height: 64px;
      /* 160% */
    }
  }

  .mb-event-banner {
    width: 100%;
    display: flex;
    padding: 24px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--black, #222);

    .text1 {
      color: var(--white, #fff);

      /* PC/H2/light */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 350;
      line-height: 30px;
      /* 160% */
    }
  }

  .event-box {
    width: 100%;
    max-width: 1050px;
    display: flex;
    flex-direction: column;
    gap: 66px;
    margin: 48px 0;

    .event-card {
      border-radius: 16px;
      width: 996px;
      height: 178px;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;

      // border: 1px solid #222222;
      .card-text-box {
        z-index: 99999999;
        width: 286px;
        height: 128px;
        position: absolute;
        top: 24px;
        left: 24px;

        .event-label {
          display: flex;
          padding: 2px 10px;
          align-items: center;
          gap: 3px;
          border-radius: 58px;
          background: var(--tag-red, #ce472d);
          color: var(--white, #fff);

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .event-label2 {
          display: flex;
          padding: 2px 10px;
          align-items: center;
          gap: 3px;
          border-radius: 58px;
          background: var(--opacity-whithe-opacity-10, rgba(255, 255, 255, 0.1));
          color: var(--opacity-whithe-opacity-80, rgba(255, 255, 255, 0.8));

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .text2 {
          color: var(--white, #fff);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .text3 {
          color: var(--white, #fff);

          /* PC/H5/extrabold */
          // font-family: NanumSquare Neo;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 32px;
          /* 160% */
        }
      }
    }
  }

  .mb-event-box {
    width: 100%;
    max-width: 1050px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .event-card {
      border-radius: 16px;
      width: 100%;
      height: 140px;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;

      // border: 1px solid #222222;
      .card-text-box {
        z-index: 99999999;
        width: 201px;
        height: 62px;
        position: absolute;
        top: 24px;
        left: 24px;

        .event-label {
          display: flex;
          padding: 2px 6px;
          align-items: center;
          gap: 3px;
          border-radius: 58px;
          background: var(--tag-red, #ce472d);

          color: var(--white, var(--White, #fff));

          /* Mo/other/tag */
          // font-family: NanumSquare Neo;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          /* 160% */
        }

        .event-label2 {
          display: flex;
          padding: 2px 6px;
          align-items: center;
          gap: 3px;
          border-radius: 58px;
          background: var(--opacity-whithe-opacity-10, rgba(255, 255, 255, 0.1));

          color: var(--opacity-whithe-opacity-80, rgba(255, 255, 255, 0.8));

          /* Mo/other/tag */
          // font-family: NanumSquare Neo;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          /* 160% */
        }

        .text2 {
          color: var(--white, #fff);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 171.429% */
        }

        .text3 {
          color: var(--white, #fff);

          /* PC/H5/extrabold */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 800;
          line-height: 20px;
          /* 160% */
        }
      }
    }
  }
}

.search {
  .three-item-wrap .edu-item {
    width: 31.2%;
  }

  .pickIt_select .educompany {
    width: 260px;
  }
}

.mypage-top-banner {
  background: var(--yeloow, #fce500);
  height: 176px;

  .text1 {
    color: var(--black, #222);
    // font-family: NanumSquare Neo;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4px;
  }

  .text2 {
    color: var(--black, #222);
    // font-family: NanumSquare Neo;
    font-size: 40px;
    font-style: normal;
    font-weight: 350;
    letter-spacing: -0.4px;
  }
}

.mb-mypage-top-banner {
  background: var(--yeloow, #fce500);
  padding: 24px 10px;

  .text1 {
    color: var(--black, #222);
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4px;
  }

  .text2 {
    color: var(--black, #222);
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    letter-spacing: -0.4px;
  }
}

.mypage {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  min-height: 700px;

  .review-form {
    display: flex;
    width: 100%;
    max-width: 894px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--neutral-2, #fafafa);

    .label1 {
      display: flex;
      padding: 2px 10px;
      align-items: center;
      gap: 3px;
      border-radius: 58px;
      background: var(--neutral-4, #ebebeb);
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .text1 {
      color: var(--black, #222);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }

    .text2 {
      color: var(--black, #222);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text3 {
      color: var(--opacity-black-opacity-30, rgba(34, 34, 34, 0.3));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text4 {
      color: var(--black, #222);

      /* PC/Bodyl/extrabold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px;
      /* 162.5% */
    }

    .review-main {
      display: flex;
      width: 100%;
      max-width: 846px;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--White, #fff);

      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        overflow: revert;
      }

      .label2 {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--sunset-orange-3, #ffd591);
        background: var(--sunset-orange-1, #fff7e6);
        color: var(--funtion-warning, #ff9d00);

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .text1 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .text2 {
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text3 {
        color: var(--black, #222);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text4 {
        color: var(--orange, #ffa940);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text5 {
        color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
        text-align: center;

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text5-group {
        display: flex;
        flex-wrap: nowrap;
      }

      .text6 {
        color: var(--opacity-black-opacity-70, rgba(34, 34, 34, 0.7));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .line1 {
        width: 100%;
        padding: 1px;
        background: #ebebeb;
      }

      .group2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        height: 74px;
      }

      .group3 {
        display: flex;
        flex-direction: column;
        padding: 0 56px;
        gap: 16px;
        justify-content: flex-start;
        width: 100%;
      }

      .text7 {
        color: var(--black, #222);

        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .text8 {
        color: var(--neutral-8, #7a7a7a);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        word-break: break-all;
      }
    }

    .letsgo-boot {
      width: 100%;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .my-notice {
    padding: 5px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--neutral-2, #fafafa);
    width: 100%;

    .notice-item {
      display: flex;
      padding: 16px;
      align-items: center;
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      /* 162.5% */
      .answer-label {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--neutral-2, #fafafa);
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .answer-label2 {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--tag-light-green, #4cbb88);
        background: #edf7f3;
        color: var(--tag-green, #287c58);

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .answer-title {
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
        max-width: 453px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }

      .text1 {
        color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));
        text-align: center;

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }
    }

    .qa-box {
      display: flex;
      margin: 0 16px 16px 16px;
      padding: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      border-radius: 8px;
      background: var(--white, #fff);

      .text2 {
        color: var(--black, #000);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text3 {
        color: var(--neutral-9, #525252);
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text4 {
        color: var(--opacity-black-opacity-40, rgba(34, 34, 34, 0.4));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .text5 {
        color: var(--neutral-7, #8f8f8f);
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
      }
    }

    .qa-box.open {
    }
  }

  .nodata {
    font-size: 50px;
    display: flex;
    width: 100%;
    max-width: 894px;
    padding: 32px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: var(--neutral-3, #f5f5f5);

    .text1 {
      color: var(--black, #222);

      /* PC/H5/regular */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      /* 160% */
    }

    .text2 {
      color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
      text-align: center;
      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text3 {
      color: var(--black, #222);

      /* PC/H5/regular */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      /* 160% */
    }

    .btn1 {
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .btn2 {
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--yeloow, #fce500);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .result-line {
    padding: 1px 0px;
    background-color: #ebebeb;
    width: 100%;
  }

  .mypage-middle {
    padding: 0 !important;
  }

  .left-card2 {
    // border-radius: 10px;
    border: none !important;

    ul li {
      padding: 8px 24px 8px 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 20px;
      cursor: pointer;
      gap: 8px;
      color: var(--neutral-7, #8f8f8f);
      /* PC/H4/regular */
      // font-family: NanumSquare Neo;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 400;

      .img-size {
        width: 24px;
        height: 24px;
      }

      .text-size {
        margin-top: 5px;
      }
    }

    .main {
      color: var(--black, #222);
      /* PC/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 700;
      border-radius: 16px;
      background: var(--neutral-2, #fafafa);
    }
  }

  .mb-left-card2 {
    // border-radius: 10px;
    border: none !important;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;

    div {
      padding: 3px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 12px;
      cursor: pointer;
      gap: 8px;
      color: var(--neutral-7, #8f8f8f);
      /* PC/H4/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;

      .img-size {
        width: 16px;
        height: 16px;
      }

      .text-size {
      }
    }

    .main {
      color: var(--black, #222);
      /* PC/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 700;
      // background: var(--neutral-2, #fafafa);
      border-bottom: 2px solid #fce500;
    }
  }

  .detail-wrap {
    .mem-mark {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      gap: 10px;
      border: 1px solid #cccccc;
      border-radius: 100px;
    }
  }

  .detail-wrap.up-wrap {
    padding-bottom: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .detail-wrap.down-wrap {
    padding-top: 0;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .home-slide {
    .slider-btn {
      top: 43%;
    }

    .prev {
      left: 20px;
    }

    .next {
      right: 20px;
    }
  }

  .data-list1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-start;
    gap: 24px;

    .data-list-item1 {
      width: 32%;
      max-width: 282px;

      .educompany-box {
        display: flex;
        width: 100%;
        max-width: 282px;
        padding: 24px 16px;
        align-items: flex-start;
        align-content: flex-start;
        gap: 16px 174px;
        flex-wrap: wrap;
        border-radius: 16px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--neutral-2, #fafafa);

        .thumb-box {
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          background-color: #fff;
        }

        .text1 {
          color: var(--black, #222);

          /* PC/H5/bold */
          // font-family: NanumSquare Neo;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          /* 160% */
        }

        .cancleBtn {
          width: 100%;
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--neutral-4, #ebebeb);
          background: var(--white, #fff);
        }

        .text2 {
          color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .bell-off-img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .data-list-item1111 {
      width: 48%;
      max-width: 152px;
      display: flex;
      justify-content: center;

      .educompany-box {
        display: flex;
        width: 100%;
        max-width: 282px;
        padding: 24px 16px;
        align-items: flex-start;
        align-content: flex-start;
        gap: 16px 174px;
        flex-wrap: wrap;
        border-radius: 16px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--neutral-2, #fafafa);

        .thumb-box {
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          background-color: #fff;
        }

        .text1 {
          color: var(--black, #222);

          /* PC/H5/bold */
          // font-family: NanumSquare Neo;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          /* 160% */
        }

        .cancleBtn {
          width: 100%;
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--neutral-4, #ebebeb);
          background: var(--white, #fff);
        }

        .text2 {
          color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .bell-off-img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.premium-modify {
  .banner {
    width: 100%;
    height: 100vh;
    max-height: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--yeloow, #fce500);

    .text1 {
      color: var(--black, #222);
      /* PC/H2/light */
      // font-family: NanumSquare Neo;
      font-size: 40px;
      font-style: normal;
      font-weight: 350;
      line-height: 64px;
      /* 160% */
    }

    .text2 {
      color: var(--black, #222);

      /* PC/H2/bold */
      // font-family: NanumSquare Neo;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      /* 160% */
    }
  }

  .modify-col {
    display: flex;
    width: 100%;
    max-width: 996px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--White, #fff);

    .line {
      width: 100%;
      height: 1px;
      background-color: #ebebeb;
    }

    .text3 {
      color: rgba(1, 1, 1, 0.5);

      /* Body_14/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text4 {
      color: var(--black, #222);

      /* PC/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      /* 150% */
    }

    .thumb-img {
      width: 18px;
      height: 18px;
    }

    .text1 {
      color: var(--black, #222);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }

    .text2 {
      color: var(--tag-orange, #ff7e47);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .input1 {
      width: 100%;
      max-width: 350px;
      display: flex;
      height: 56px;
      padding: 16px;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
    }

    // .input4 .invalid-feedback {
    //   text-align: right;
    // }
    .input4 {
      width: 100%;
      max-width: 350px;
    }

    .state-label1 {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
      background: var(--white, #fff);
      color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .state-label1.selected {
      border-radius: 8px;
      background: var(--yeloow, #fce500);
      color: var(--black, #222);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .file-upload-container {
      display: flex;
      height: 100%;
      max-height: 80px;
      padding: 40px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 1px dashed var(--neutral-4, #ebebeb);
      background: var(--neutral-2, #fafafa);
      margin-bottom: 10px;

      &:hover {
        border-color: #aaa;
      }

      .file-input {
        display: none; // input 타입 file 숨기기
      }

      .upload-icon {
        font-size: 24px; // 아이콘 크기 조절
      }

      .upload-instructions {
        // margin-top: 4px;
        color: var(--black, #222);
        text-align: center;

        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }
    }

    .file-details {
      display: flex;
      gap: 8px;

      .file-name {
        color: var(--funtion-blue-light, #1890ff);

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .file-icon {
        width: 14px;
        height: 14px;
      }
    }

    .text5 {
      color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .text-input-container {
      display: flex;
      flex-direction: column;
      position: relative;

      .text-input {
        width: 100%;
        padding: 8px 16px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--white, #fff);
        font-size: 16px;
        min-height: 68px; // 최소 높이 설정
        resize: vertical; // 세로 리사이즈 허용
      }

      .text-input2 {
        width: 100%;
        padding: 8px 16px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--white, #fff);
        font-size: 16px;
        min-height: 198px; // 최소 높이 설정
        resize: vertical; // 세로 리사이즈 허용
      }

      .character-counter {
        position: absolute;
        bottom: -25px;
        right: 15px;
        color: var(--opacity-black-opacity-20, rgba(34, 34, 34, 0.2));
        text-align: right;

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }
    }
  }

  .btn1 {
    max-width: 486px;
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--neutral-7, #8f8f8f);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  .btn2 {
    max-width: 486px;
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 10px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* PC/Bodyl/bold */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 162.5% */
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}

.update-interest {
  .tag-outline {
    color: #999;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
  }

  .tag-outline.on {
    border: 1px solid transparent;
    background-color: #f9e000;
    font-size: 20px;
    font-weight: 700;
    color: #262327;
  }

  .banner {
    display: flex;
    width: 100%;
    height: 140px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: var(--yeloow, #fce500);

    color: var(--black, #222);

    /* PC/H2/light */
    // font-family: NanumSquare Neo;
    font-size: 40px;
    font-style: normal;
    font-weight: 350;
    line-height: 64px;
    /* 160% */
  }

  .mb-banner {
    display: flex;
    width: 100%;
    padding: 24px 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: var(--yeloow, #fce500);

    color: var(--black, #222);

    /* PC/H2/light */
    // font-family: NanumSquare Neo;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: 30px;
    /* 160% */
  }

  .select-stack-banner {
    background: var(--neutral-2, #fafafa) !important;
    height: 134px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .select-banner-box {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      color: var(--black, #222);
      /* PC/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      /* 150% */

      .select-num {
        color: rgba(255, 169, 64, 0.5);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }
    }
  }

  .mb-select-stack-banner {
    background: var(--neutral-2, #fafafa) !important;
    padding: 12px 20px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .select-banner-box {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      color: var(--black, #222);
      /* PC/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      /* 150% */

      .select-num {
        color: rgba(255, 169, 64, 0.5);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 162.5% */
      }
    }
  }

  .stack-item-group {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;

    .stack-item {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-2, #fafafa);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      /* 162.5% */
      .close-img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .mb-stack-item-group {
    width: 100%;
    max-width: 360px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .stack-item {
      display: flex;
      padding: 6px 10px;
      align-items: center;
      gap: 2px;
      border-radius: 8px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-2, #fafafa);
      color: var(--black, #222);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      /* 162.5% */
      .close-img {
        width: 12px;
        height: 12px;
      }
    }
  }

  .text1 {
    width: 100%;
    max-width: 282px;
    color: var(--black, #222);

    /* PC/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    /* 150% */
  }

  .technology-stack {
    width: 100%;
    align-items: flex-start;

    .accordion-box {
      width: 100vw;
      max-width: 894px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);

      .accordion-item1 {
        padding: 16px;
        border-bottom: 1px solid var(--neutral-4, #ebebeb);
      }

      .accordion-item1:last-child {
        border-bottom: none;
      }

      .img1 {
        width: 12px;
        height: 12px;
      }

      .text1 {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .stack-box {
        padding: 8px 16px 0 16px;
      }

      .stack-item {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--neutral-2, #fafafa);
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .stack-item.on {
        padding: 1px 8px;
        border-radius: 8px;
        border: 1px solid var(--volcano-3, #ffbb96);
        background: var(--volcano-1, #fff2e8);
        color: var(--volcano-6, #fa541c);
        /* Mo/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
      }
    }
  }

  .mb-technology-stack {
    width: 100%;
    align-items: flex-start;

    .accordion-box {
      width: 100%;
      max-width: 894px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);

      .accordion-item1 {
        padding: 16px 12px;
        border-bottom: 1px solid var(--neutral-4, #ebebeb);
      }

      .accordion-item1:last-child {
        border-bottom: none;
      }

      .img1 {
        width: 12px;
        height: 12px;
      }

      .text1 {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 162.5% */
      }

      .stack-box {
        padding: 8px 16px 0 16px;
      }

      .stack-item {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--neutral-2, #fafafa);
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .stack-item.on {
        padding: 1px 8px;
        border-radius: 8px;
        border: 1px solid var(--volcano-3, #ffbb96);
        background: var(--volcano-1, #fff2e8);
        color: var(--volcano-6, #fa541c);
        /* Mo/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
      }
    }
  }

  .area-box {
    display: flex;
    width: 100%;
    max-width: 282px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #d9d9d9);
  }

  .mb-area-box {
    display: flex;
    width: 100%;
    max-width: 150px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    padding: 6px 8px !important;
    font-size: 12px !important;
    line-height: 20px !important;
    height: 32px !important;
  }

  .label-box {
    flex-wrap: wrap;

    .label {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 58px;
      background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    }

    .label.on {
      border-radius: 58px;
      background: var(--yeloow, #fce500);
    }
  }

  .mb-label-box {
    flex-wrap: wrap;

    .label {
      display: flex;
      padding: 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: 58px;
      background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    }

    .label.on {
      border-radius: 58px;
      background: var(--yeloow, #fce500);
    }
  }

  .select-area .form-check-label {
    font-size: 12px !important;
    margin-left: 10px;
    flex: 1;
    text-align: start;
  }

  .noreal-box {
    margin-left: 24px;
    display: flex;
    width: 100%;
    max-width: 540px;
    padding: 9px 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--neutral-2, #fafafa);
    color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

    /* PC/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
  }

  .save-banner {
    display: flex;
    width: 100%;
    padding: 20px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--black, #222) !important;

    .save-text-box {
      width: 100%;
      max-width: 1200px;

      .text1 {
        color: #fff;
        text-align: center;

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .save-btn {
        display: flex;
        flex-wrap: nowrap;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text2 {
        width: auto;
        white-space: nowrap;
      }
    }
  }
}

.mypick {
  position: relative;

  .curriclumModal-bg {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .curriclumModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 588px;
    padding: 24px;
    border-radius: 16px;
    background: var(--white, #fff);
    max-height: 700px;
    overflow-y: scroll;
    z-index: 900000;

    .curriclum {
      .curriclum-text {
        color: var(--black, #222);
        /* PC/H4/bold */
        // font-family: NanumSquare Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
      }
    }

    .line {
      background: #ebebeb;
      width: 100%;
      margin: 24px 0;
      height: 1px;
    }

    .cancleBtn {
      margin-top: 24px;
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
    }
  }

  .accordion.curry {
    .accordion-item {
      margin-bottom: 0px !important;
      border: none !important;

      .title {
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));
      }

      .item1 {
        padding: 24px;
        border-radius: 8px;
        background: var(--neutral-2, #fafafa);
      }
    }

    .accordion-button {
      border: none !important;
      position: relative;
    }

    .accordion-button:not(.collapsed) {
      background-color: transparent;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: var(--cui-accordion-btn-icon);
      position: absolute;
      left: 16px !important;
      width: 12px;
      height: 12px;
      background-size: contain;
      /* or 'cover' */
    }

    .accordion-button::after {
      background-image: var(--cui-accordion-btn-icon);
      position: absolute;
      left: 16px !important;
      width: 12px;
      height: 12px;
      background-size: contain;
      /* or 'cover' */
    }
  }
}

.mypick-container {
  width: 100% !important;
  background: white !important;
  max-width: 100% !important;
  padding: 0 !important;

  .mypick-banner {
    background: #fce500;
    padding: 56px 0;

    .banner-text1 {
      color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }
  }

  .pick-main-box {
    max-width: 1205px;
    width: 100%;
    min-height: 881px;

    .top-list-group {
      padding-bottom: 12px;
      border-bottom: 2px solid #ebebeb;
    }

    .select-pick-group {
      width: 100%;
      max-width: 894px;
      height: 100%;

      .select-list-group {
        .text12 {
          width: 32%;
          color: var(--neutral-7, #8f8f8f);
          text-align: center;

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }
      }

      .mb-select-item {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: var(--neutral-2, #fafafa);
        color: var(--black, #222);

        /* PC/H5/regular */
        // font-family: NanumSquare Neo;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;

        /* 160% */
        .text11 {
          color: var(--black, #222);
          /* PC/H5/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 160% */
          margin-top: 8px;
        }

        .text2 {
          max-width: 160px;
          margin-top: 4px;
          color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
          text-align: center;

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 171.429% */
        }

        .reload {
          display: flex;
          height: 24px;
          padding: 4px 6px 4px 10px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 6px;
          border: 1px solid var(--neutral-4, #ebebeb);
          background: var(--neutral-2, #fafafa);
          color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

          /* Mo/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          /* 133.333% */
        }
      }

      .select-item-group {
        .select-item {
          width: 32%;
          height: 20vw;
          max-width: 282px;
          max-height: 282px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--neutral-2, #fafafa);
          color: var(--black, #222);

          /* PC/H5/regular */
          // font-family: NanumSquare Neo;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;

          /* 160% */
          .text11 {
            color: var(--black, #222);
            /* PC/H5/regular */
            // font-family: NanumSquare Neo;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            /* 160% */
            margin-top: 8px;
          }

          .text2 {
            max-width: 160px;
            margin-top: 4px;
            color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
            text-align: center;

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }

          .reload {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            margin-top: 4px;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--neutral-2, #fafafa);
            color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }

        .pick-select-item {
          width: 32%;
          height: 20vw;
          max-width: 282px;
          max-height: 282px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #222;

          .pick-select-box {
            width: 100%;
            height: 100%;
            max-width: 282px;
            max-height: 184px;
            padding: 24px 16px;
            align-items: flex-start;
            align-content: flex-start;
            gap: 16px;
            flex-wrap: wrap;
            border-radius: 16px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            flex-wrap: nowrap;

            .thumb {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-size: cover;
              /* 이미지를 요소의 크기에 맞게 확대/축소합니다. */
              background-position: center;
              /* 이미지를 가운데에 위치시킵니다. */
              background-repeat: no-repeat;
              /* 이미지를 반복하지 않습니다. */
            }

            .text1 {
              color: var(--black, #222);

              /* PC/H5/bold */
              // font-family: NanumSquare Neo;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px;
              /* 160% */
              overflow: hidden;
              /* 내용이 넘치는 부분을 잘라냅니다. */
              text-overflow: ellipsis;
              /* 잘라낸 텍스트를 ...으로 표시합니다. */
              width: 100%;
              max-width: 250px;
              // white-space: nowrap; /* 텍스트를 한 줄로 표시합니다. */
            }
          }

          .ApplicationBtn {
            margin-top: 10px;
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            background: var(--yeloow, #fce500);
            color: var(--black, #222);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }

          .detailBtn {
            margin-top: 8px;
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            color: var(--neutral-7, #8f8f8f);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }
      }

      .mb-select-item-group {
        .select-item {
          width: 100vw;
          height: 100vh;
          max-width: 152px;
          max-height: 152px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--neutral-2, #fafafa);
          color: var(--black, #222);

          /* PC/H5/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          /* 160% */
          .text11 {
            color: var(--black, #222);
            /* PC/H5/regular */
            // font-family: NanumSquare Neo;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            /* 160% */
            margin-top: 8px;
          }

          .text2 {
            max-width: 160px;
            margin-top: 4px;
            color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));
            text-align: center;

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }

          .reload {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            margin-top: 4px;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--neutral-2, #fafafa);
            color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }

        .pick-select-item {
          width: 100vw;
          height: 100vh;
          max-width: 152px;
          max-height: 152px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #222;

          .pick-select-box {
            width: 100vw;
            height: 100vh;
            max-width: 152px;
            max-height: 152px;
            padding: 16px;
            align-items: flex-start;
            align-content: flex-start;
            gap: 8px;
            flex-wrap: wrap;
            border-radius: 16px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            flex-wrap: nowrap;

            .thumb {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-size: cover;
              /* 이미지를 요소의 크기에 맞게 확대/축소합니다. */
              background-position: center;
              /* 이미지를 가운데에 위치시킵니다. */
              background-repeat: no-repeat;
              /* 이미지를 반복하지 않습니다. */
            }

            .text1 {
              color: var(--black, #222);

              /* PC/H5/bold */
              // font-family: NanumSquare Neo;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
              /* 160% */
              overflow: hidden;
              /* 내용이 넘치는 부분을 잘라냅니다. */
              text-overflow: ellipsis;
              /* 잘라낸 텍스트를 ...으로 표시합니다. */
              width: 100%;
              max-width: 120px;
              height: 40px;
              text-overflow: ellipsis;
              overflow: hidden;
              // white-space: nowrap; /* 텍스트를 한 줄로 표시합니다. */
            }
          }

          .ApplicationBtn {
            margin-top: 10px;
            display: flex;
            padding: 6px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            background: var(--yeloow, #fce500);
            color: var(--black, #222);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 171.429% */
          }

          .detailBtn {
            margin-top: 8px;
            display: flex;
            padding: 6px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            color: var(--neutral-7, #8f8f8f);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 171.429% */
          }
        }
      }

      .group-name {
        padding: 0 24px;
        color: var(--tag-green, #287c58);

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        margin-bottom: 8px;
      }

      .compare-group1 {
        border-radius: 12px;
        background: var(--neutral-2, #fafafa);
        display: flex;
        gap: 24px;
        padding: 12px 0;

        .detail-btn {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--neutral-4, #ebebeb);
          background: var(--white, #fff);
          color: var(--neutral-7, #8f8f8f);
          margin-top: 8px;
          width: 100%;
          max-width: 234px;

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .compare-item1 {
          width: 48%;
          max-width: 282px;
          text-align: left;
          padding: 0 24px;

          .review-write-btn {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            color: var(--black, #222);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }

        .compare-item2 {
          width: 48%;
          max-width: 282px;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0 24px;

          .review-write-btn {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            color: var(--black, #222);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }

        .compare-item3 {
          display: flex;
          height: 20px;
          padding: 1px 8px;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--tag-light-green, #4cbb88);
          background: #edf7f3;
          color: var(--tag-green, #287c58);

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .compare-item4 {
          display: flex;
          height: 20px;
          padding: 1px 8px;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--neutral-5, #d9d9d9);
          background: var(--neutral-2, #fafafa);
          color: var(--black, #222);

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .compare-title {
          color: var(--neutral-7, #8f8f8f);
          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }

        .compare-text1 {
          color: var(--black, #222);

          /* PC/Bodyl/bold */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          /* 162.5% */
        }

        .compare-text2 {
          color: var(--neutral-10, #3d3d3d);

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }
      }

      .compare-group2 {
        border-radius: 12px;
        background: #fff;
        display: flex;
        gap: 24px;
        padding: 12px 0;

        .detail-btn {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--neutral-4, #ebebeb);
          background: var(--white, #fff);
          color: var(--neutral-7, #8f8f8f);
          margin-top: 8px;
          width: 100%;
          max-width: 234px;

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .compare-item1 {
          width: 32%;
          max-width: 282px;
          text-align: left;
          padding: 0 24px;

          .review-write-btn {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            color: var(--black, #222);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }

        .compare-item2 {
          width: 48%;
          max-width: 282px;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0 24px;

          .review-write-btn {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid var(--neutral-4, #ebebeb);
            background: var(--white, #fff);
            color: var(--black, #222);

            /* PC/Bodym/regular */
            // font-family: NanumSquare Neo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
          }
        }

        .compare-item3 {
          display: flex;
          padding: 1px 8px;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--tag-light-green, #4cbb88);
          background: #edf7f3;
          color: var(--tag-green, #287c58);

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .compare-item4 {
          display: flex;
          height: 20px;
          padding: 1px 8px;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--neutral-5, #d9d9d9);
          background: var(--neutral-2, #fafafa);

          color: var(--black, #222);

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .compare-title {
          color: var(--neutral-7, #8f8f8f);
          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }

        .compare-text1 {
          color: var(--black, #222);

          /* PC/Bodyl/bold */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          /* 162.5% */
        }

        .compare-text2 {
          color: var(--neutral-10, #3d3d3d);

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }
      }

      .text1 {
        width: 100%;
        max-width: 78px;
        color: var(--neutral-7, #8f8f8f);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
      }

      .text144 {
        width: 42px;
        color: var(--neutral-7, #8f8f8f);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        /* 171.429% */
      }

      .text2 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text3 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        width: 50%;
        text-align: center;
      }

      .text344 {
        color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        text-align: center;
        width: 42px;
      }

      .text2.on {
        color: var(--tag-green, #287c58) !important;

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
      }

      .text3.on {
        color: var(--tag-green, #287c58) !important;

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 162.5% */
      }

      .average-score {
        .text1 {
          color: var(--neutral-7, #8f8f8f);

          /* PC/Bodyl/regular */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
        }

        .text2 {
          color: var(--neutral-10, #3d3d3d);

          /* PC/H3/bold */
          // font-family: NanumSquare Neo;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          /* 150% */
        }

        .text3 {
          width: 100%;
          max-width: 78px;
        }

        .star-img {
          width: 56px;
          height: 56px;
        }
      }

      .text9 {
        color: var(--black, #222);

        /* PC/Bodym/bold */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 171.429% */
      }

      .rating-group {
        .small-star-img {
          width: 16px;
          height: 16px;
        }

        .text10 {
          color: var(--orange, #ffa940);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }
      }

      .text11 {
        color: var(--neutral-8, #7a7a7a);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        word-break: break-all;
      }

      .text1111 {
        margin: 10px 0;
        color: var(--neutral-8, #7a7a7a);

        /* PC/Bodym/regular */
        // font-family: NanumSquare Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 171.429% */
        // height: 100vw;
        max-height: 70px;
        // overflow: auto;
        // max-width: 144px;
        word-break: break-all;
        // text-overflow: ellipsis;
      }

      .more-pre-review {
        color: var(--neutral-7, #8f8f8f);
        text-align: center;

        /* Body_16/regular-underline */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
        text-decoration-line: underline;
      }

      .more-pre-review1 {
        color: var(--neutral-7, #8f8f8f);
        text-align: center;

        /* Body_16/regular-underline */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;
      }
    }

    .mypick-group {
      width: 100%;
      max-width: 282px;
      height: 100%;

      .nopick-group {
        margin-top: 24px;
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        border-radius: 16px;
        background: var(--neutral-2, #fafafa);
        width: 100%;
        height: 100%;
        max-width: 282px;
        max-height: 424px;

        .text1 {
          color: var(--tag-green, #287c58);
          text-align: center;

          /* PC/H5/bold */
          // font-family: NanumSquare Neo;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          /* 160% */
        }

        .text2 {
          margin-top: 8px;
          color: var(--neutral-7, #8f8f8f);
          text-align: center;

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }

        .btn1 {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: var(--black, #222);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
          width: 100%;
          max-width: 150px;
          border-radius: 8px;
          background: var(--yeloow, #fce500);
        }
      }

      .search-box {
        margin: 16px 0;
        display: flex;
        padding: 12px;
        align-items: center;
        flex: 1 0 0;
        border-radius: 16px;
        background: var(--neutral-3, #f5f5f5);

        .search-group {
          width: 100%;
          max-width: 258px;
          gap: 8px;
          height: 24px;
        }

        .search-img {
          width: 20px;
          height: 20px;
        }

        .search-text {
          color: #222;

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          background: var(--neutral-3, #f5f5f5);
          border: none;
          width: 100%;
          padding: 0 !important;
        }
      }

      // min-height: 346px;
      .top-list1-group {
        padding-bottom: 10px;
        border-bottom: 2px solid #ebebeb;
      }

      .item-group {
        max-height: 507px;
        overflow: auto;
        min-height: 459px;

        .nonebox {
          width: 100%;
          padding: 12px 0;
        }
      }

      .item-group-wrapper {
        position: relative;
        /* 자식 요소의 위치를 기준으로 하기 위해 relative를 설정합니다. */
        max-height: 507px;
        overflow: hidden;
        /* 자식 요소가 넘치는 부분을 잘라냅니다. */
      }

      .item-group-wrapper::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 39px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 94.79%);
        pointer-events: none;
      }

      .select-list-group {
        color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
        text-align: center;

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
        display: flex;
        padding-bottom: 0 !important;

        .text1 {
          cursor: pointer;
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .text2.on {
          padding-bottom: 8px;
          border-bottom: 4px solid #fce500;
          font-weight: 700;
        }
      }

      .card {
        position: relative;
        padding: 24px;
        border-radius: 16px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--neutral-2, #fafafa);
        width: 100%;

        .jobtypebox {
          display: flex;
          padding: 2px 10px;
          align-items: center;
          border-radius: 58px;
          background: var(--neutral-4, #ebebeb);
          color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .card-text {
          color: var(--black, #222);

          /* PC/Bodyl/bold */
          // font-family: NanumSquare Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          /* 162.5% */
          max-width: 234px;
        }
      }

      .hover-card {
        display: none;
        /* 기본적으로 hover-card를 숨깁니다 */
        position: absolute;
        /* hover-card의 위치를 절대값으로 설정합니다 */
        width: 100%;
        height: 100%;
        top: 0;
        /* hover-card를 card의 상단에 위치시킵니다 */
        left: 0;
        /* hover-card를 card의 왼쪽에 위치시킵니다 */
        border-radius: 16px;
        border: 1px solid var(--yeloow, #fce500);
        background: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));
        padding: 29px 0 33px 0;

        .text1 {
          color: var(--black, #222);
          text-align: center;
          text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);

          /* PC/Bodys/regular */
          // font-family: NanumSquare Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
          max-width: 109px;
        }
      }

      .hover-card2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        /* hover-card의 위치를 절대값으로 설정합니다 */
        width: 100%;
        height: 100%;
        top: 0;
        /* hover-card를 card의 상단에 위치시킵니다 */
        left: 0;
        /* hover-card를 card의 왼쪽에 위치시킵니다 */
        border-radius: 16px;
        border: 1px solid var(--yeloow, #fce500);
        background: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));
        padding: 29px 0 33px 0;

        .text2 {
          color: var(--orange, #ffa940);
          text-align: center;
          text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);

          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }
      }

      .hover-card3 {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        /* hover-card의 위치를 절대값으로 설정합니다 */
        width: 100%;
        height: 100%;
        top: 0;
        /* hover-card를 card의 상단에 위치시킵니다 */
        left: 0;
        /* hover-card를 card의 왼쪽에 위치시킵니다 */
        border-radius: 16px;
        border: 1px solid var(--yeloow, #fce500);
        background: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));
        padding: 29px 0 33px 0;

        .text2 {
          color: var(--neutral-7, #8f8f8f);
          text-align: center;
          text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
          /* PC/Bodym/regular */
          // font-family: NanumSquare Neo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 171.429% */
        }
      }

      .card:hover .hover-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;

        .cancleBtn {
          position: absolute;
          top: 18px;
          right: 18px;
        }
      }

      .card:hover .hover-card2 {
        display: none;
      }

      .card:hover .hover-card3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        // z-index: 10;
      }
    }
  }
}

.mypick {
  .product-list {
    .item {
      border: 1px solid #eeeeee;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .thumb {
        width: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px;
        margin-right: 10px;
        position: relative;
      }

      .expire {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 3px 9px;
        height: 23px;
        background: #262327;
        border-radius: 100px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #fff;
        position: absolute;
        bottom: 2px;
        right: 2px;
      }
    }

    .item.on,
    .item:hover {
      border: 1px solid transparent;
      background-color: #f9e000;
    }
  }

  .compare-wrap {
    .item {
      border-bottom: 1px solid #eee;
      padding: 20px 0;
      display: flex;

      .label {
        width: 140px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #666;
      }

      .data {
        display: flex;
        flex: 1;
        text-align: center;
        align-items: center;
        justify-content: center;
      }

      .prod-item {
        border: 1px solid #cccccc;
        border-radius: 10px;
        padding: 20px;
        text-align: left;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.alarm-setting {
  .data-list {
    .item {
      padding: 20px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;

      .thumb {
        width: 92px;
        height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
      }

      .thumb2 {
        width: 72px;
        height: 72px;
        border: 1px solid #cccccc;
        border-radius: 100%;
        background-size: 47px;
      }

      .btn-secondary {
        background: #eeeeee !important;
        color: #666666 !important;
      }
    }

    .item:last-child {
      border-bottom: none;
    }
  }
}

.setting {
  .btn-cam {
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 32px;
    cursor: pointer;
  }

  .btn-secondary.btn-auth,
  .btn-secondary.btn-auth:hover {
    background-color: #eee !important;
    color: #262327 !important;
    font-size: 14px !important;
  }
}

.myhistory {
  .item {
    padding: 40px;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 25px;
    cursor: pointer;

    .thumb {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.partner-request {
  .label1 {
    width: 120px;
  }

  .label2 {
    width: 200px;
  }

  .btn-secondary {
    font-size: 14px;
    background-color: #eee;
    height: 40px;
    width: 190px;
    color: #666;
  }

  .item {
    border: 1px solid #eeeeee;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
  }

  .final-wrap {
    width: 600px;
  }
}

.notice {
  .notice-list {
    li {
      font-size: 16px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
    }

    .title {
      color: #666;
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      background-color: #f1f1f1;
    }
  }
}

.bamboo {
  .tag-outline {
    color: #ccc;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    width: 100px;
    margin-right: 10px;
    padding: 10px 5px;
  }

  .tag-outline.on {
    color: #262327;
    font-weight: 700;
    border: 1px solid transparent;
    background-color: $yellow;
  }

  .search-wrap input {
    border-radius: 10px !important;
  }

  .item {
    padding: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }

  .tag {
    padding: 7px 14px;
  }

  .i_no_bamboo {
    width: 160px;
  }

  .minw-7rem {
    min-width: 7rem;
  }
}

.bamboo-detail {
  .dropdown-menu.show {
    min-width: 5rem;
    width: 5.5rem;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .tag {
    padding: 7px 12px;
    font-size: 14px;
  }

  .nick-txt {
    position: absolute;
    top: 14px;
    left: 14px;
    font-weight: 700;
  }

  textarea.form-control {
    padding-top: 35px;
  }

  .btn-secondary {
    padding-top: 9px;
  }

  .btn-secondary:hover {
    color: #262327 !important;
  }

  .bamboo-comment {
    padding: 20px 0;
    border-bottom: 1px solid #eee;

    .thumb {
      width: 96px;
      height: 96px;
      border-radius: 4px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.bamboo-reg {
  .btn-delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

/* **************************************** *
  * MOBILE
  * **************************************** */
@media (min-width: 1400px) {
  .header .search-wrap {
    width: 552px;
  }

  .list-table-header .table-row {
    width: 760px;
  }
}

@media (min-width: 1400px) {
  .register {
    .px-md-5 {
      padding-right: 40px !important;
      padding-left: 40px !important;
    }
  }
}

@media (min-width: 1200px) {
  .sitevision .container {
    max-width: 1280px;
  }
}

@media (max-width: 1400px) {
  .header {
    .nav-item {
      justify-content: center;
    }

    .middle {
      width: 45%;
      //   margin-left: 3%;
    }

    // .r-wrap {
    //   width: 35%;
    // }
  }

  .one-item-wrap .edu-item {
    width: 45%;

    .item-img {
      height: 193px;
    }
  }

  .two-item-wrap .edu-item {
    .item-img {
      height: 207px;
    }
  }

  .three-item-wrap .edu-item {
    width: 31.3%;

    .item-img {
      height: 207px;
    }
  }

  .four-item-wrap .edu-item {
    .item-img {
      height: 139px;
    }
  }

  .company-detail .three-item-wrap .edu-item {
    width: 30.2%;

    .item-img {
      height: 109px;
    }
  }

  .edu-detail .three-item-wrap .edu-item .item-img {
    height: 104px;
  }

  .white-paper .three-item-wrap .edu-item .item-img {
    height: 179px;
  }

  .findjob .filter-wrap .filterrow.three .filteritem {
    width: 32.7%;
  }

  .home {
    .edumainitem .introimg {
      height: 280px;
    }

    .pickIt_select .educompany {
      padding: 30px 10px;
      width: calc(100% / 3 - 20px) !important;
    }

    .banner-wrap {
      .matchseciotn.jobtestblock .markimg {
        width: 160px;
        height: 149.9px;
      }

      .matchseciotn .blocktitle {
        font-size: 33px;
      }
    }

    .bannerslide .banner .banner_info {
      .banner_title {
        font-size: 24px;
        line-height: 24px;
      }

      .banner_option {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }

  div.list-table-header .table-row {
    width: 760px;
  }

  div.list-table-body .table-row {
    width: 760px;
  }

  div.list-table-body .title-cell {
    width: 230px;
  }
}

@media (max-width: 1200px) {
  .header {
    padding: 0 20px;

    .nav-item {
      justify-content: center;
    }

    .middle {
      width: 50%;
      margin-left: 3%;
    }

    // .r-wrap {
    //   width: 34%;
    // }
    .lang.btn-group .dropdown-toggle {
      width: 116px;
    }

    .header-nav .dropdown-menu {
      margin-left: 15px;
    }

    .btn-side {
      width: 109px;
    }

    .nav-link {
      font-size: 14px;
    }
  }

  .edu-item {
    .img-wrap {
      position: relative;

      .deem {
        font-size: 14px;
      }

      .online {
        width: 53px;
        height: 26px;
        font-size: 12px;
        bottom: 5px;
        right: 5px;
      }
    }

    .img-row-wrap {
      width: 150px;
    }

    .edu-type {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      height: 29px;
      background: #f9e000;
      border-radius: 100px;
      width: 110px;
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #262327;
    }

    .edu-type.end {
      background: #cccccc;
      color: white;
    }

    .edu-type.free {
      background: #ff6610;
      color: white;
    }

    .edu-type.cond-free {
      background: #2db400;
      color: white;
    }

    .edu-type.sponsored {
      background: #1d1693;
      color: white;
      border-radius: 6px;
      height: 24px;
      width: 80px;
      font-size: 10px;
    }

    .fs-md-20 {
      font-size: 16px !important;
    }
  }

  //
  .home {
    .edumainitem .introimg {
      height: 240px;
    }

    .banner-wrap {
      .matchseciotn {
        padding: 60px 30px;

        .blocktitle {
          font-size: 25px;
        }

        .destext {
          font-size: 15px;
        }
      }
    }

    .jobpaper {
      max-width: 50%;
    }
  }

  .one-item-wrap .edu-item {
    width: 59%;

    .item-img {
      height: 202px;
    }
  }

  .four-item-wrap .edu-item .item-img {
    height: 114px;
  }

  .edu-detail {
    .left-card {
      width: 300px;
    }

    .three-item-wrap .edu-item {
      width: 46.2%;

      .item-img {
        height: 133px;
      }
    }
  }

  .white-paper.edu-detail {
    .three-item-wrap .edu-item {
      width: 31.1%;

      .item-img {
        height: 157px;
      }
    }
  }

  .login {
    .col-md-5 {
      width: 75%;
    }
  }

  .login.register {
    .col-md-5 {
      width: 100%;
    }
  }

  //
  .findjob {
    .filter-wrap {
      .filterrow {
        gap: 5px;
        margin-bottom: 5px;
        align-items: flex-start;
      }

      .filteritem {
        height: auto;
        min-height: 60px;
        padding: 7px 10px;
        width: 49.6% !important;
        display: block;
      }

      .filteritem.withexit {
        width: 100% !important;
        display: flex;
      }

      .filteritem.area-item {
        display: unset !important;
      }

      .filtervalrow {
        gap: 2px;
        text-align: left;
      }

      .filterlabel {
        font-size: 12px;
        width: 100%;
      }

      .filtertag {
        font-size: 12px;
        padding: 0;
        background: none;
      }

      .filteritem.input {
        display: flex;
        padding: 0 10px;

        input {
          margin-left: 5px;
        }

        input::placeholder {
          font-size: 12px;
        }
      }

      .radio-check .form-check-label {
        font-size: 12px;
        margin-top: 6px;
        margin-left: 5px;
      }
    }

    .four-item-wrap .edu-item {
      width: 22.8%;
    }
  }

  //
  .collection2 .col-md-6 {
    width: 100%;
  }

  .collection7 .container {
    max-width: 100%;
  }

  div.list-table-header .table-row {
    width: 600px;
  }

  div.list-table-body .table-row {
    width: 600px;
  }

  div.list-table-body .title-cell {
    width: 230px;
  }
}

@media (max-width: 1024px) {
  .logo {
    font-size: 20px;
  }

  .logo-img {
    width: 77px;
  }

  .header {
    padding: 0 10px;

    .lang.btn-group .dropdown-toggle {
      width: 46px;

      span,
      .i_down {
        display: none;
      }
    }

    .dropdown-toggle {
      width: 60px;
    }

    .header-nav .dropdown-menu {
      margin-left: -22px;
    }

    // .header-my, .middle {display: none;}
    // .r-wrap { width: 67%; }
    .sitemap-menu {
      display: block;
      width: 24px;
      height: 44px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .sitemap-btn {
      width: 24px;
      height: 14px;
      z-index: 1001;
    }

    .sitemap-btn > div {
      width: 18px;
      height: 2px;
      background-color: #262327;
      transition: 0.3s;
      margin-bottom: 3px;
    }

    .sitemap-btn > div:last-of-type {
      margin-bottom: 0px;
    }

    .sitemap1 {
      display: block;
    }

    .sitemap1.active {
      display: none;
    }

    .mb-header-menu-text1 {
      color: var(--white, var(--White, #fff));

      /* Mo/H4/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      /* 137.5% */
    }

    // .sitemap-btn.active div{transform-origin:6px;background-color:#77787b;z-index:1001}
    // .sitemap-btn.active div:first-of-type{transform:rotate(45deg)}
    // .sitemap-btn.active div:last-of-type{transform:rotate(-45deg)}
    // .sitemap-btn.active div:nth-child(2){display: none;}
    .sitemap-menu .depth01 {
      // padding: 60px 20px 10px;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      position: absolute;
      top: -11px;
      left: calc(-100vw + 40px);
      flex-direction: column;
      background-image: none;
      background: var(--neutral-3, #f5f5f5);
      transform: translateX(100%);
      transition: none;
      opacity: 0;
      display: none;
      overflow: hidden;
    }

    .sitemap-menu .depth01.active {
      transform: translateX(0);
      opacity: 1;
      transition: 0.3s;
      display: flex;
      z-index: 1000;
    }

    .sitemap-menu .depth01 .i_close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }

    .sitemap-menu.active .deem {
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .sitemap-menu .depth01 li {
      width: 100%;
      padding: 12px 10px;
    }

    .sitemap-menu .depth02 li:last-of-type {
      margin-bottom: 0;
    }

    .sitemap-menu .depth01 li > a {
      color: #000;
      opacity: 100%;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 800;
      display: flex;
      align-items: center;
    }

    .sitemap-menu .depth01 li > a.active {
      font-weight: bold;
    }

    .mb-login-box {
      display: flex;
      width: 320px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--White, #fff);
      color: var(--black, #222);
      text-align: center;

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .mb-login-btn {
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--black, #222);
      /* Mo/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      /* 133.333% */
    }

    .mb-header-menu-listBox {
      display: flex;
      width: 320px;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-radius: 12px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--White, #fff);
      font-size: 12px;
      font-weight: 400;
    }

    .mb-line {
      width: 100%;
      height: 1px;
      background: rgba(34, 34, 34, 0.05);
    }
  }

  .footer {
    padding: 40px 0;
    margin-bottom: 50px;

    .logo {
      font-size: 28px;
    }
  }

  //
  .one-item-wrap .edu-item {
    width: 100%;

    .item-img {
      height: 335px;
    }
  }

  .collection7 .container {
    max-width: 100%;

    .col-md-9 {
      padding: 1.5rem 1rem !important;
    }

    .w-75 {
      width: 100% !important;
    }

    .item2.item6 span {
      width: 180px;
      font-size: 20px !important;
    }
  }

  .findjob {
    .slider-wrap {
      .edu-item {
        display: block;

        .item-img {
          width: 100%;
          border-radius: 20px 20px 0 0;
          margin: 0;
        }

        .card-body {
          flex: 1;
          padding: 5px 12px;
        }

        .img-row-wrap {
          width: 100%;
          margin: 0;

          img {
            border-radius: 0;
            margin-bottom: 10px;
          }

          .edu-type.sponsored {
            margin-left: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .header {
    .middle {
      width: 30%;
    }
  }

  .nav-item {
    justify-content: center;
  }

  .edu-detail .left-card {
    width: 100%;

    .card-body {
      padding: 20px;
    }
  }

  .edu-detail .three-item-wrap .edu-item {
    width: 44.7%;

    .item-img {
      height: 92px;
    }

    .deem {
      font-size: 14px;
    }
  }

  .findjob .four-item-wrap .edu-item {
    width: 22.6%;
  }

  //
  .home.search .container {
    max-width: 100%;
  }

  .white-paper.edu-detail .three-item-wrap .edu-item {
    width: 31%;
  }
}

@media (max-width: 880px) {
  .findjob .four-item-wrap .edu-item {
    width: 46% !important;
  }

  .white-paper.edu-detail .three-item-wrap .edu-item {
    width: 30%;
  }
}

@media (max-width: 767px) {
  html {
    scroll-padding-top: 100px;
  }

  body {
    background-color: white;
  }

  .body {
    overflow-x: hidden;
  }

  .header {
    padding: 0 16px;
    height: 56px;

    .nav-link {
      height: 56px;
    }

    .btn-register {
      height: 29px;
      padding: 0 10px;
      font-size: 12px !important;
    }

    .middle {
      display: none;
    }
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 10px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 999;

    .header-title {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .mobile-bottom-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 50;
    left: 0;
    transition: all, 0.3s;
    height: 66px;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  }

  .mobile-bottom-menu ul {
    width: 100%;
  }

  .mobile-bottom-menu li {
    float: left;
    background: #fff;
    width: 20%;
    text-align: center;
  }

  .mobile-bottom-menu li a {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    display: block;
    height: 66px;
    font-size: 10px;
    font-weight: 400;
    color: #999;
    // padding-top: 9px;
  }

  .mobile-bottom-menu li a.on {
    color: #000;
    font-weight: 700;
  }

  .mobile-bottom-menu li a i {
    font-size: 25px;
    width: 100%;
  }

  .mobile-bottom-menu li a svg {
    margin: 0 auto 0px;
    display: block;
    // width: 18px;
  }

  .nav-tabs .nav-link {
    font-size: 16px;
  }

  .btn-big {
    height: 50px;
    font-size: 16px !important;
  }

  .tag-outline {
    font-size: 14px;
  }

  .back_to_top {
    bottom: 4.7rem;
  }

  //
  .edu-item {
    margin: 10px 0 0 10px;

    .item-img {
      height: 24.4vw;

      div {
        font-size: 16px;
      }
    }

    .card-body {
      padding: 15px;
    }
  }

  .home-slide {
    padding-right: 25px;

    .swiper {
      overflow: unset;
    }

    .slider-btn,
    .prev,
    .next {
      display: none;
    }

    .pagenation {
      font-size: 12px;
      margin: auto;
    }
  }

  .four-item-wrap .edu-item {
    width: 45.6% !important;
  }

  .swiper .edu-item {
    .item-img {
      height: 24.4vw;
    }
  }

  .four-item-wrap .edu-item .item-img,
  .findjob .slider-wrap .edu-item .item-img,
  .three-item-wrap .edu-item .item-img {
    height: 24.4vw;
  }

  .one-item-wrap .edu-item .item-img {
    height: 49vw;
  }

  .introcontext .introinfo .text-sm-center {
    text-align: center !important;
  }

  //
  .home {
    .banner-wrap {
      padding: 20px 0;
      background: none;

      .matchseciotn {
        padding: 30px 20px;

        .blockinf {
          gap: 20px;
        }

        .blocktitle {
          font-size: 24px;
          line-height: 30px;
        }

        .destext {
          font-size: 14px;
          line-height: 20px;
        }

        .markimg {
          width: 72px;
          height: 100px;
        }
      }

      .matchseciotn.jobtestblock .markimg {
        width: 90px;
        height: 83px;
      }
    }

    .section {
      padding: 20px 0;
    }

    .edumainitem {
      position: relative;
      top: 0;

      .educontext {
        padding: 17px !important;
        gap: 10px;

        .eduinfo {
          .edutitle {
            font-size: 24px;
            line-height: 35px;
          }

          .head .toporder {
            font-size: 32px;
            line-height: 38px;
          }
        }
      }

      .introimg {
        height: 49vw;
      }
    }

    .edumainitem.subitem {
      margin-bottom: 10px;

      .educontext .eduinfo {
        gap: 0;

        .edutitle {
          font-size: 20px;
          line-height: 29px;
        }
      }
    }

    .pickIt_select {
      gap: 10px;

      .educompany {
        width: calc(50vw - 17px) !important;
        padding: 30px 10px;
        gap: 20px;

        .todayinf .companyname {
          font-size: 14px;
        }

        .todayinf .lesscnt {
          font-size: 14px;
        }
      }
    }

    .big-btn {
      font-size: 16px !important;
      padding: 30px 20px;
      letter-spacing: -0.5px;
    }

    .bannerslide {
      border-radius: 4px;

      .banner {
        .banner_info .banner_title {
          font-size: 17px;
          line-height: 15px;
        }

        .banner_info .banner_option {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }

    .service_intro {
      text-align: center;

      img {
        width: 128px;
      }
    }

    .jobpaper {
      .job-logo {
        background-size: cover;
      }

      .paperinfo {
        padding: 14px;

        .papercontxt {
          .papertxt .papgename {
            font-size: 14px;
          }

          .papertxt .papgedes {
            font-size: 12px;
          }

          .papertag .tag {
            padding: 6px 10px;
            font-size: 10px;
          }
        }

        .detailview {
          padding: 10px 0px;
        }
      }
    }

    .jobpaper-list.pickIt_select {
      gap: 10px;
    }

    .bottomblock {
      gap: 6px;

      .btmitem {
        height: 120px;
      }

      .btm-mark {
        width: 30px;
        height: 30px;
        background-size: 30px;
      }

      .blockname {
        font-size: 10px;
        letter-spacing: -0.5px;
        line-height: 0;
      }
    }

    .reviewBlock {
      margin-top: 20px;
      font-size: 24px;
      line-height: 36px;
      padding: 40px;

      img {
        width: 100px;
      }

      .writervbtn {
        font-size: 16px;
        line-height: 19px;
        width: 100%;
      }
    }

    .bottom-search {
      .img1 {
        width: 96px;
      }

      .img2 {
        width: 72px;
      }

      h1 {
        line-height: 26px;
      }

      .btn {
        font-size: 16px !important;
        height: 64px !important;

        img {
          width: 24px !important;
        }
      }

      .form-control {
        height: 51px;
        font-size: 14px !important;
        padding: 16px;
      }

      input::placeholder {
        font-size: 14px !important;
      }

      .btn-search {
        top: 13px;
        right: 16px;
        width: 24px !important;
      }
    }
  }

  //
  .collection {
    .input-nick {
      height: 64px;
      font-size: 24px !important;
    }

    .input-nick::placeholder {
      font-size: 24px !important;
    }

    .mb-input-nick {
      height: 48px;
      font-size: 12px !important;
    }

    .mb-input-nick::placeholder {
      font-size: 12px !important;
    }

    .item1 {
      display: flex;
      padding: 20px;
      text-align: left;
      margin-bottom: 10px;
      padding-right: 6px;

      .avatar-xxl {
        width: 64px;
        height: 64px;
      }
    }

    .item2 {
      display: block;
      text-align: left;
      padding: 20px;
      margin-bottom: 10px;

      div {
        margin-top: 5px;
      }
    }

    .item2 {
      display: flex;
      align-items: center;
      padding: 20px;
      border: 1px solid #eee;
      border-radius: 20px;
      cursor: pointer;
      margin-bottom: 20px;
      text-align: left;
    }

    .item3 {
      div {
        padding: 7px 17px;
        font-size: 14px;
        white-space: pre;
      }
    }

    .item2.item5 {
      span {
        width: 100%;
        flex: 1;
      }

      .text-intent-7 {
        text-indent: 0;
        padding-left: 0;
      }

      .pre-wrap {
        white-space: normal;
      }
    }

    .item2.item6.on,
    .item2.item6:hover {
      background-image: none;
    }

    .item2.item6.on img {
      display: block !important;
      margin-top: 5px;
    }

    .big-select {
      height: 60px;
      margin-bottom: 10px;
      background-size: 48px;
      padding: 5px 20px;
    }
  }

  .collection7 {
    .container .item2.item6 span {
      font-size: 16px !important;
      flex: 1;
    }
  }

  //
  .jobkind {
    .mb-bottom-box {
      width: 100%;
      max-width: 792px;
      display: flex;
      justify-content: center;

      .lets-go-home {
        display: flex;
        width: 100%;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--white, #fff);
        color: var(--neutral-7, #8f8f8f);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 162.5% */
      }
    }

    .mb-matching-test-bottom {
      opacity: 0.85;
      background: var(--black, #222);
      padding: 40px 0;

      .mb-bottom-text1 {
        color: var(--white, #fff);
        text-align: center;
        /* PC/H2/bold */
        // font-family: NanumSquare Neo;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        /* 160% */
      }

      .mb-bottom-btn1 {
        display: flex;
        width: 368px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: var(--yeloow, #fce500);
        color: var(--black, #222);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 162.5% */
      }

      .mb-bottom-btn1:hover {
        border-radius: 10px;
        background: black;
        color: var(--yeloow, #fce500);
      }

      .mb-bottom-btn2 {
        display: flex;
        width: 368px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--white, #fff);
        color: var(--neutral-7, #8f8f8f);

        /* PC/Bodyl/bold */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 162.5% */
      }

      .mb-bottom-btn2:hover {
        border-radius: 10px;
        border: 1px solid var(--yeloow, #fce500);
        background: rgba(255, 214, 0, 0.4);
        color: black;
      }
    }

    .form-check {
      .form-check-input[type='checkbox'] {
        width: 25px;
        height: 25px;
      }

      .form-check-label {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .edu-item {
      .item-img {
        height: 43vw;
      }

      .card-body {
        padding: 10px 15px;
      }

      .fs-12 {
        font-size: 14px !important;
      }

      .fs-14 {
        font-size: 16px !important;
      }

      .fs-16 {
        font-size: 24px !important;
      }
    }

    .btn-next {
      height: 54px;
      font-size: 16px !important;
    }
  }

  .jobkind.step1 {
    height: calc(100vh - 100px);
  }

  //
  .findjob {
    .filter-wrap {
      .filterrow {
        gap: 5px;
        margin-bottom: 5px;
        align-items: flex-start;
      }

      .filteritem {
        height: auto;
        min-height: 60px;
        padding: 7px 10px;
        width: 49.1% !important;
        display: block;
      }

      .filter-method {
        height: 85px;
      }

      .filteritem.withexit {
        width: 100% !important;
        display: flex;
      }

      .filtervalrow {
        gap: 2px;
        text-align: left;
      }

      .filterlabel {
        font-size: 12px;
        width: 100%;
      }

      .filtertag {
        font-size: 12px;
        padding: 0;
        background: none;
        display: none;
      }

      .filtertag:first-child {
        display: block;
      }

      .filteritem.input {
        display: flex;
        padding: 0 10px;

        input {
          margin-left: 5px;
        }

        input::placeholder {
          font-size: 12px;
        }
      }

      .radio-check .form-check-label {
        font-size: 12px;
        margin-top: 6px;
        margin-left: 5px;
      }
    }

    .jobmodalbg {
      min-width: 100vw;

      .jobmodal {
        left: 0 !important;
        top: 0 !important;
        width: 100vw !important;
        border-radius: 0 !important;
        padding: 13px 20px;
        position: fixed;
        height: 100vh;
        justify-content: flex-start;

        .jobmodaltool {
          display: none;
        }

        .m-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .header-title {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .jobwraparea {
          overflow-y: scroll;
          padding-bottom: 64px;
        }

        .jobbottom {
          display: flex;
          background-color: #cccccc;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;

          div {
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 64px;
          }

          div:first-child {
            background-color: #ccc;
            flex: 2;
          }

          div:last-child {
            background-color: #f9e000;
            flex: 3;
          }
        }

        .teachgrouparea {
          height: auto;
        }

        .areafiltermodal .areselrow {
          display: block;

          select {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .methodrow,
        .diffcultrow {
          display: block;
        }

        .methodkeydes,
        .diffcultkeydes {
          margin: 10px 0;
        }
      }

      .schedulefiltermodal {
        padding: 10px;
      }

      .techfiltermodal {
        padding: 10px;

        .techgroupitem {
          display: block;

          .techname {
            width: 100%;
            margin-bottom: 5px;
          }
        }

        .techmodaltitle {
          white-space: pre-wrap;
        }
      }
    }

    //
    .slider-wrap {
      .edu-item {
        display: block;

        .item-img {
          width: 100%;
          border-radius: 20px 20px 0 0;
          margin: 0;
        }

        .card-body {
          flex: 1;
          padding: 5px 12px;
        }

        .img-row-wrap {
          width: 100%;
          margin: 0;

          img {
            border-radius: 0;
            margin-bottom: 10px;
          }

          .edu-type.sponsored {
            margin-left: 13px;
          }
        }
      }
    }

    .middle-banner-wrap {
      height: 110px;
    }
  }

  //
  .edu-detail {
    .left-card {
      width: 100%;

      .card-body {
        padding: 10px;
      }
    }

    .detail-wrap {
      padding: 12px;
      border-radius: 0px;
      margin-bottom: 64px;

      .tab-nav {
        top: 48px;
      }

      .category-wrap {
        padding: 15px;

        .data-wrap {
          display: block;

          .label1 {
            width: 100%;
            margin: 10px 0 12px;
            padding-top: 2px;
            font-size: 15px;
            font-weight: 700;
          }
        }
      }

      .tag-outline {
        padding: 8px 17px;
      }

      .benefit-wrap {
        padding: 20px;

        img {
          width: 64px;
          height: 72px;
        }

        .boolean {
          font-size: 14px;

          .arrow {
            left: -12px;
            width: 15px;
            height: 15px;
          }
        }
      }

      .accordion {
        .accordion-button {
          padding: 14px 10px;
        }

        .accordion-body {
          padding: 15px;
        }
      }

      .reivew-item .content {
        padding: 10px;
      }
    }

    .three-item-wrap .edu-item {
      width: 45.6%;

      .item-img {
        height: 85px;
      }
    }

    .bottom-wrap {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      z-index: 9;
      height: 50px;
      background-color: white;

      .popover {
        top: -57px;
        left: 29%;
      }
    }

    .alarm-modal {
      padding: 13px;
      bottom: 56px;

      .left-wrap {
        padding-right: 10px;
      }
    }

    .alarm-modal.compare-pick {
      bottom: 64px;
    }

    .nav-tabs {
      .nav-link {
        font-size: 13px;
      }

      // .nav-link:hover, .nav-link:focus { border-color: #d8dbe0; color: #999;}
    }
  }

  .company-detail .alarm-modal {
    bottom: 0;
  }

  //
  .white-paper {
    background-color: white;

    .job-list {
      .item {
        width: calc(100vw / 3 - 20px);
        padding: 0 10px;

        .avatar {
          border: 2px solid transparent;
        }

        .txt {
          border-bottom: 2px solid transparent;
          padding-bottom: 5px !important;
          font-size: 14px !important;
        }
      }

      .item:hover {
        .avatar {
          border: 2px solid #262327;
        }

        .txt {
          border-bottom: 2px solid #262327;
          padding-bottom: 10px;
          font-weight: 700;
        }
      }
    }

    .info-wrap {
      padding: 25px 15px;
    }

    .info-wrap2 {
      padding: 0 15px;
    }

    .three-item-wrap .edu-item {
      width: 47% !important;
    }

    .alarm-modal {
      bottom: -100% !important;
    }

    .alarm-modal.show {
      bottom: 56px !important;
    }
  }

  //
  .contactus {
    .data-wrap {
      display: block;

      .label1 {
        margin: 10px 0 5px;
      }
    }

    .minh-400 {
      min-height: 212px !important;
    }

    .img-wrap {
      display: flex;
      flex-wrap: wrap;

      .img-upload,
      .item {
        width: 80px;
        height: 80px;
      }
    }
  }

  //
  .event {
    .home-slide {
      width: 100%;
    }

    .edu-item {
      border-radius: 10px;

      .item-img {
        height: 120px;
        border-radius: 10px 10px 0 0;
      }
    }
  }

  //
  .search {
    .pickIt_select .educompany {
      width: calc(50vw - 21px) !important;
    }

    .search-wrap input {
      border-radius: 6px !important;
    }

    .search-list li {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  //
  .update-interest {
    .col-md-4 {
      margin-bottom: 5px;
    }

    .tag-outline {
      padding: 5px 10px;
      font-size: 14px;
    }

    .tag-outline.on {
      font-size: 14px;
    }

    .btn-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f9e000;
      font-size: 16px;
      font-weight: 700;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
    }
  }

  //
  .mypick {
    .bottom-wrap {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .compare-wrap .item .label {
      display: none;
    }
  }

  //
  .alarm-setting {
    .data-list .item {
      padding: 10px 0;

      .thumb {
        width: 72px;
      }

      .thumb2 {
        width: 62px;
        height: 62px;
      }

      .btn-secondary {
        font-size: 12px !important;
        height: 32px !important;
      }
    }

    .nav-tabs .nav-link {
      font-size: 16px;
    }
  }

  //
  .setting {
    .btn.h-64 {
      height: 50px !important;
    }

    .btn-secondary.btn-auth,
    .btn-secondary.btn-auth:hover {
      font-size: 12px !important;
      width: 5.5rem !important;
      padding: 5px;
    }
  }

  .setting-modal {
    .modal-content {
      border-radius: 0;
    }

    .modal-footer button {
      font-size: 16px !important;
      margin: 0 5px !important;
      height: 50px !important;
    }
  }

  //
  .mypage {
    .home-slide {
      .slider-btn {
        top: 39%;
        width: 50px;
        height: 50px;
        display: block;
      }

      .prev {
        left: 10px;
      }

      .next {
        right: 10px;
      }
    }
  }

  .myhistory {
    background-color: #f5f4fb;

    .list-wrap {
      min-height: calc(100vh - 48px);
    }

    .list-wrap2 {
      min-height: calc(100vh - 97px);
    }

    .item {
      padding: 20px;
      margin-bottom: 10px;
    }
  }

  //
  .partner-request {
    .data-wrap {
      display: block;
    }

    .label1 {
      width: 100% !important;
      margin-bottom: 5px;
    }

    .btn-secondary {
      font-size: 12px;
      width: 180px;
    }

    .item {
      padding: 20px;
      margin-bottom: 10px;
      text-align: center;
      cursor: pointer;
    }

    .final-wrap {
      width: 100%;
    }

    .btn-next {
      position: fixed;
      bottom: 0;
      left: 0;
      border-radius: 0 !important;
      width: 100% !important;
      margin: 0;
    }

    .btn-warning:hover {
      background-color: #f9e000 !important;
      color: #262327 !important;
    }
  }

  .survey .data-wrap .label1 {
    text-align: left;
  }

  .bamboo {
    background-color: #f5f4fb;

    .tag-outline {
      font-size: 14px;
      display: inline-block;
      text-align: center;
    }

    .search-wrap input {
      width: 100%;
    }

    .search-tag-wrap {
      display: block !important;
      white-space: nowrap;
      overflow: scroll;
    }

    .data-list {
      .item {
        padding: 14px;

        .width10 {
          width: 3rem !important;
        }
      }
    }

    .i_no_bamboo {
      width: 120px;
    }

    .i_write {
      position: fixed;
      bottom: 120px;
      right: 00px;
      width: 80px;
    }

    .minw-7rem {
      min-width: 4rem;
    }
  }

  //
  .bamboo-detail {
    .bamboo-comment {
      .width8.btn {
        width: 4rem !important;
      }

      .thumb {
        width: 64px;
        height: 64px;
      }
    }

    .bottom-reg-comment {
      position: fixed;
      bottom: 0;
      left: 0;
      border-top: 1px solid #ccc;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      background-color: white;

      .form-control {
        border: none;
      }

      .form-control::placeholder {
        font-size: 14px;
        color: #333333;
      }

      .btn-warning {
        height: 60px;
        border-radius: 0;
      }
    }

    .modal-footer button {
      font-size: 16px !important;
      width: 100% !important;
      height: 50px !important;
    }
  }

  .bamboo-reg {
    .width18 {
      width: 100% !important;
      border-radius: 10px !important;
    }
  }

  .bamboo-profile {
    .width10 {
      width: 7rem !important;
    }
  }
}

.swiper-scrollbar {
  display: none !important;
}

.swiper-pagination-bullet {
  height: 12px !important;
  width: 12px !important;
  background-color: #efefef;
  transition: 0.2s ease-in-out;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  height: 12px !important;
  width: 25px !important;
  background-color: #222222 !important;
  border-radius: 10px !important;
  transition: 0.2s ease-in-out;
}

.swiper-wrapper {
  max-width: 1200px !important;
}

@media (max-width: 657px) {
}

@media (max-width: 576px) {
}

.mb-mypick-group {
  width: 100%;
  // max-width: 282px;
  height: 100%;

  .nopick-group {
    margin-top: 24px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 16px;
    background: var(--neutral-2, #fafafa);
    width: 100%;
    height: 100%;
    max-width: 282px;
    max-height: 424px;

    .text1 {
      color: var(--tag-green, #287c58);
      text-align: center;

      /* PC/H5/bold */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      /* 160% */
    }

    .text2 {
      margin-top: 8px;
      color: var(--neutral-7, #8f8f8f);
      text-align: center;

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .btn1 {
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--black, #222);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
      width: 100%;
      max-width: 150px;
      border-radius: 8px;
      background: var(--yeloow, #fce500);
    }
  }

  .search-box {
    margin: 16px 0;
    display: flex;
    padding: 12px;
    align-items: center;
    flex: 1 0 0;
    border-radius: 16px;
    background: var(--neutral-3, #f5f5f5);

    .search-group {
      width: 100%;
      max-width: 258px;
      gap: 8px;
      height: 24px;
    }

    .search-img {
      width: 20px;
      height: 20px;
    }

    .search-text {
      color: #222;

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      background: var(--neutral-3, #f5f5f5);
      border: none;
      width: 100%;
      padding: 0 !important;
    }
  }

  // min-height: 346px;
  .top-list1-group {
    padding-bottom: 10px;
    border-bottom: 2px solid #ebebeb;
  }

  .item-group {
    // max-height: 507px;
    overflow: auto;
    // min-height: 459px;
    flex-wrap: wrap;

    .nonebox {
      width: 100%;
      padding: 12px 0;
    }
  }

  .item-group-wrapper {
    position: relative;
    /* 자식 요소의 위치를 기준으로 하기 위해 relative를 설정합니다. */
    max-height: 507px;
    overflow: auto;
    /* 자식 요소가 넘치는 부분을 잘라냅니다. */
  }

  // .item-group-wrapper::after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 39px;
  //   background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 94.79%);
  //   pointer-events: none;
  // }
  .select-list-group {
    color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));
    text-align: center;

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    display: flex;
    padding-bottom: 0 !important;

    .text1 {
      cursor: pointer;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text2.on {
      padding-bottom: 8px;
      border-bottom: 4px solid #fce500;
      font-weight: 700;
    }
  }

  .card {
    position: relative;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--neutral-2, #fafafa);
    width: 100%;
    max-width: 170px;
    // height: 100px;

    .jobtypebox {
      display: flex;
      padding: 2px 6px;
      align-items: center;
      border-radius: 58px;
      background: var(--neutral-4, #ebebeb);
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      /* 166.667% */
    }

    .card-text {
      color: var(--black, #222);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      /* 162.5% */
      max-width: 120px;
      height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .hover-card {
    display: none;
    /* 기본적으로 hover-card를 숨깁니다 */
    position: absolute;
    /* hover-card의 위치를 절대값으로 설정합니다 */
    width: 100%;
    height: 100%;
    top: 0;
    /* hover-card를 card의 상단에 위치시킵니다 */
    left: 0;
    /* hover-card를 card의 왼쪽에 위치시킵니다 */
    border-radius: 16px;
    border: 1px solid var(--yeloow, #fce500);
    background: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));
    padding: 29px 0 33px 0;

    .text1 {
      color: var(--black, #222);
      text-align: center;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);

      /* PC/Bodys/regular */
      // font-family: NanumSquare Neo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
      max-width: 109px;
    }
  }

  .hover-card2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* hover-card의 위치를 절대값으로 설정합니다 */
    width: 100%;
    height: 100%;
    top: 0;
    /* hover-card를 card의 상단에 위치시킵니다 */
    left: 0;
    /* hover-card를 card의 왼쪽에 위치시킵니다 */
    border-radius: 16px;
    border: 1px solid var(--yeloow, #fce500);
    background: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));
    padding: 29px 0 33px 0;

    .text2 {
      color: var(--orange, #ffa940);
      text-align: center;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }
  }

  .hover-card3 {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* hover-card의 위치를 절대값으로 설정합니다 */
    width: 100%;
    height: 100%;
    top: 0;
    /* hover-card를 card의 상단에 위치시킵니다 */
    left: 0;
    /* hover-card를 card의 왼쪽에 위치시킵니다 */
    border-radius: 16px;
    border: 1px solid var(--yeloow, #fce500);
    background: var(--opacity-whithe-opacity-90, rgba(255, 255, 255, 0.9));
    padding: 29px 0 33px 0;

    .text2 {
      color: var(--neutral-7, #8f8f8f);
      text-align: center;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }
  }

  .card:hover .hover-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;

    .cancleBtn {
      position: absolute;
      top: 18px;
      right: 18px;
    }
  }

  .card:hover .hover-card2 {
    display: none;
  }

  .card:hover .hover-card3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    // z-index: 10;
  }
}

//맨밑 추가
.mb-select-label {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
  color: var(--black, #222);

  /* Body_14/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 171.429% */
}

.mb-select-label2 {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  background: var(--yeloow, #fce500);
  color: var(--black, #222);

  /* Body_14/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 171.429% */
}

.mb-select-label.on {
  background: var(--yeloow, #fce500);
}

.mb-stack-label2 {
  display: flex;
  padding: 5px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-2, #fafafa);
  color: var(--black, #222);

  /* PC/Bodyl/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  // line-height: 26px; /* 162.5% */
  // height: 34px;
}

.mb-stack-box {
  padding: 8px 16px 0 16px;
}

.mb-stack-item {
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 8px;
  border: 1px solid var(--neutral-5, #ccc);
  background: var(--neutral-2, #fafafa);
  color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

  /* PC/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.mb-stack-item.on {
  padding: 1px 8px;
  border-radius: 8px;
  border: 1px solid var(--volcano-3, #fce500);
  background: var(--volcano-1, #fce500);
  color: var(--volcano-6, #333);
  /* Mo/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 133.333% */
}

.mb-accordion-item1 {
  padding: 16px;
  border-bottom: 1px solid var(--neutral-4, #ebebeb);
}

.mb-close-btn {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--neutral-7, #8f8f8f);

  /* PC/Bodyl/bold */
  // font-family: NanumSquare Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  // line-height: 26px; /* 162.5% */
}

.mb-confirm-btn {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  background: var(--yeloow, #fce500);
  color: var(--black, #222);

  /* PC/Bodyl/bold */
  // font-family: NanumSquare Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  // line-height: 26px; /* 162.5% */
}

.more-stack {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--black, #222);

  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.mb-state-label1 {
  display: flex;
  padding: 3px 5px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
  background: var(--white, #fff);
  color: var(--opacity-black-opacity-50, rgba(34, 34, 34, 0.5));

  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.mb-state-label1.selected {
  border-radius: 8px;
  background: var(--yeloow, #fce500);
  color: var(--black, #222);

  /* PC/Bodym/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.edu-banner-mb {
  background-color: #525252;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 159px;

  .group1-mb {
    width: 100%;
    max-width: 1140px;
    position: relative;
    padding: 20px;

    .banner-img {
      width: 152px;
      height: 80px;
      border-radius: 20px 20px 0px 0px;
      position: absolute;
      right: 20px;
      bottom: 0;
    }

    .text {
      margin-bottom: 58px;
    }
  }
}

.edu-content-mb {
  color: var(--white, #fff);
  /* PC/H3/bold */
  // font-family: NanumSquare Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  max-width: 581px;
  margin-bottom: 30px;
}

.nav-box-mb {
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  display: flex;
  justify-content: center;

  .tab-nav-mb {
    position: sticky;
    top: 80px;
    background: white;
    z-index: 8;
    // padding-left: 10px !important;
  }
}

.base-item-mb {
  padding: 10px 15px;
  border-radius: 16px;
  background: var(--neutral-3, #f5f5f5);
  width: 100%;

  .item1 {
    padding: 30px;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 500px;
    border: 1px solid var(--neutral-3, #f5f5f5);
    max-width: 104px;
  }

  .text-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-left: 10px;
  }

  .item2-group {
    display: flex;
    gap: 3px;
    margin-bottom: 3px;
    flex-wrap: wrap;
  }

  .item2-text {
    color: var(--black, #222);
    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .itme2 {
    padding: 1px 8px;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08));
    white-space: nowrap;

    .item2-img {
      margin-right: 5px;
      margin-bottom: 1px;
    }
  }
}

.parent-mb {
  margin-top: 15px;
  position: relative;
  box-sizing: border-box;
  // margin: 0 10px;

  .pickerroo-mb {
    position: absolute;
    right: 0%;
    top: -10%;
    width: 20%;
    z-index: 10;
  }

  .recommend-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--Neutral-2, #fafafa);
    z-index: 2;
    border-radius: 0px 110px 0px 0px;
  }
}

.recommend-mb {
  padding: 20px;
  border-bottom: 1px solid var(--neutral-4, #ebebeb);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 56px;
  position: relative;
  z-index: 3;

  .pick-text {
    color: var(--orange, #ffa940);
    /* PC/H4/bold */
    // font-family: NanumSquare Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-top: 12px;
  }

  .text1 {
    margin-top: 16px;
  }
}

.recommend-product-mb {
  flex-wrap: wrap;
  gap: 16px;
}

.mb-twoBtn {
  display: flex;
  width: 360px;
  padding: 12px 20px;
  align-items: flex-start;
  gap: 8px;
  border-top: 1px solid var(--Neutral-4, #ebebeb);
  background: var(--Neutral-2, #fafafa);
  position: relative;
}

.mb-twoBtn::after {
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 94.79%);
  pointer-events: none;
}

.mb-closeBtn12 {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid var(--Neutral-4, #ebebeb);
  background: var(--white, #fff);
  color: var(--black, #222);

  /* Mo/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.mb-goBtn23 {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--yeloow, #fce500);
  color: var(--black, #222);

  /* Mo/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.compare-text23 {
  color: var(--neutral-10, #3d3d3d);

  /* PC/Bodyl/regular */
  // font-family: NanumSquare Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 162.5% */
}

.mb-filter-group {
  display: flex;
  width: 100%;
  max-width: 893px;
  padding: 24px 20px 12px 20px;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  background: var(--Neutral-3, #f5f5f5);

  .search-input {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    background: var(--White, #fff);
    color: var(--Neutral-11, #292929);

    /* Mo/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    height: 36px !important;
  }

  .search-input::placeholder {
    font-size: 12px !important;
  }

  .stack-label {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* Body_14/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    // line-height: 24px; /* 171.429% */
  }

  .search-btn {
    display: flex;
    width: 64px;
    height: 36px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* Mo/Bodys/regular */
    // font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }

  .reset-btn {
    display: flex;
    width: 120px;
    height: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    color: var(--black, #222);

    /* PC/Bodyl/regular */
    // font-family: NanumSquare Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(34, 34, 34, 0.1);
  }

  .select-label {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
    color: var(--black, #222);

    /* Body_14/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .select-label2 {
    height: 28px;
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background: var(--yeloow, #fce500);
    color: var(--black, #222);

    /* Body_14/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .select-label.on {
    background: var(--yeloow, #fce500);
  }

  .label-section-line {
    color: var(--opacity-black-opacity-10, rgba(34, 34, 34, 0.1));
  }

  .course-period {
    width: 140px;
    height: 40px !important;
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--neutral-4, #ebebeb);
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .addBtn {
    display: flex;
    padding: 8px 20px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--neutral-5, #ccc);
    color: var(--black, #222);

    /* PC/Bodym/regular */
    // font-family: NanumSquare Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }
}

.mb-optioncloseBtnText {
  color: var(--black, #222);

  /* PC/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.label5-1 {
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 8px;
  border: 1px solid var(--sunset-orange-3, #ffd591);
  background: var(--sunset-orange-1, #fff7e6);
  color: var(--funtion-warning, #ff9d00);

  /* PC/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  max-height: 25px;
  width: fit-content;
}

.label5-2 {
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 8px;
  border: 1px solid var(--cyan-3, #87e8de);
  background: var(--cyan-1, #e6fffb);
  color: var(--cyan-6, #13c2c2);

  /* PC/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  max-height: 25px;
  width: fit-content;
}

.label5-3 {
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 8px;
  border: 1px solid #ea9b8c;
  background: #faf1f0;
  color: var(--tag-red, #ce472d);
  /* PC/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  max-height: 25px;
  width: fit-content;
}

.label5-4 {
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 8px;
  border: 1px solid var(--sunset-orange-3, #ffd591);
  background: var(--sunset-orange-1, #fff7e6);
  color: var(--funtion-warning, #ff9d00);

  /* PC/Bodys/regular */
  // font-family: NanumSquare Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  max-height: 25px;
  width: fit-content;
}

.mb-member-exit {
  .card-template5 {
    .text-filed {
      padding: 8px 16px !important;
    }

    .reason-box {
      margin-top: 16px;
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
    }

    .text7 {
      color: var(--opacity-black-opacity-60, rgba(34, 34, 34, 0.6));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    border-radius: 16px 120px 16px 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--white, #fff);
    /* shadow_1 */
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 50px 0 !important;

    .skip-btn {
      width: 1000vw;
      max-width: 387px;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--neutral-7, #8f8f8f);

      /* PC/Bodyl/regular */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .accordion-box {
      width: 100vw;
      max-width: 387px;
      border-radius: 16px;
      border: 1px solid var(--neutral-4, #ebebeb);

      .accordion-item1 {
        padding: 16px;
        border-bottom: 1px solid var(--neutral-4, #ebebeb);
      }

      .accordion-item1:last-child {
        border-bottom: none;
      }

      .img1 {
        width: 12px;
        height: 12px;
      }

      .text1 {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

        /* PC/Bodyl/regular */
        // font-family: NanumSquare Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
      }

      .stack-box {
        padding: 8px 16px 0 16px;
      }

      .stack-item {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 3px;
        border-radius: 8px;
        border: 1px solid var(--neutral-5, #ccc);
        background: var(--neutral-2, #fafafa);
        color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

        /* PC/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }

      .stack-item.on {
        border-radius: 8px;
        padding: 1px 8px;
        border: 1px solid var(--yeloow, #fce500);
        background: var(--yeloow, #fce500);
        color: var(--black, #222);
        /* Mo/Bodys/regular */
        // font-family: NanumSquare Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
      }
    }

    .button-group {
      .btn-prev {
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
        font-size: 12px;
        color: black !important;
        padding: 16px 24px;
        height: auto !important;
        width: 100%;
      }

      .btn-prev:hover {
        font-size: 16px;
        color: #222222 !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        border: 1px solid var(--yeloow, #fce500);
        background: rgba(255, 214, 0, 0.4) !important;
      }

      .btn-prev:disabled {
        font-size: 16px;
        color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
      }

      .btn-next {
        border-radius: 10px;
        border: 1px solid var(--neutral-4, #ebebeb);
        background: var(--yeloow, #fce500) !important;
        font-size: 12px !important;
        color: #222222 !important;
        padding: 16px 24px;
        height: auto !important;
        width: 100%;
      }

      .btn-next:hover {
        font-size: 16px;
        color: #fce500 !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        background: #222222 !important;
      }

      .btn-next:disabled {
        font-size: 16px;
        color: var(--opacity-black-opacity-8, rgba(34, 34, 34, 0.08)) !important;
        padding: 16px 24px;
        height: auto !important;
        width: 150px;
        border-radius: 10px;
        background: var(--opacity-black-opacity-5, rgba(34, 34, 34, 0.05)) !important;
      }
    }
  }

  .card-template5 .form-control {
    height: 30px !important;
    font-size: 12px !important;
    color: #333;
    border-radius: 10px !important;
    border: 1px solid #ccc;
  }

  .goodbye-container {
    background: var(--neutral-2, #fafafa);
    width: 100vw;
    height: 100vh;

    .goodbye {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .lets-go-home {
      display: flex;
      width: 100%;
      max-width: 368px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
      color: var(--neutral-7, #8f8f8f);

      /* PC/Bodyl/bold */
      // font-family: NanumSquare Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      /* 162.5% */
    }
  }
}

.data-list-item111122 {
  width: 48%;
  max-width: 152px;
  display: flex;
  justify-content: center;

  .educompany-box {
    display: flex;
    width: 100%;
    max-width: 282px;
    padding: 24px 16px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 174px;
    flex-wrap: wrap;
    border-radius: 16px;
    border: 1px solid var(--neutral-4, #ebebeb);
    background: var(--neutral-2, #fafafa);

    .thumb-box {
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: #fff;
    }

    .text1 {
      color: var(--black, #222);

      /* PC/H5/bold */
      // font-family: NanumSquare Neo;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      /* 160% */
    }

    .cancleBtn {
      width: 100%;
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--neutral-4, #ebebeb);
      background: var(--white, #fff);
    }

    .text2 {
      color: var(--opacity-black-opacity-80, rgba(34, 34, 34, 0.8));

      /* PC/Bodym/regular */
      // font-family: NanumSquare Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .bell-off-img {
      width: 20px;
      height: 20px;
    }
  }
}

.thumb123 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
  /* 이미지를 요소의 크기에 맞게 확대/축소합니다. */
  background-position: center;
  /* 이미지를 가운데에 위치시킵니다. */
  background-repeat: no-repeat;
  /* 이미지를 반복하지 않습니다. */
}

.hiddenCard {
  width: 1px !important;
}

.mb-selected-btn {
  background-color: yellow;
  display: flex;
  padding: 4px;
  align-items: center;
  border-radius: 12px;
  width: 32px;
  height: 32px;
}

.mb-selected-btn {
  background-color: white;
  display: flex;
  padding: 4px;
  align-items: center;
  border-radius: 12px;
  width: 32px;
  height: 32px;
}

.timeout-label {
  display: flex;
  width: 100%;
  padding: 9px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #ffe2e2;
  background: #fcf5f5;
  z-index: 1000000;
}

.text12345 {
  color: var(--funtion-danger, #c23934);
  /* PC/Bodym/bold */
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.text556677 {
  color: var(--black, #222);

  /* PC/Bodym/regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.home-tooltip {
  width: 227px;
  height: 133px;
  position: absolute;
  top: -110px;
  z-index: 1;
  max-width: 227px;
  z-index: 1;
}

.home-tooltip-m {
  width: 169px;
  height: 112px;
  position: absolute;
  top: -145px;
  left: 0px;
  max-width: 169px;
  z-index: 1;
}

.x-icon-btn {
  background-color: transparent;
  border: none;
  width: 15px;
  height: 15px;
  z-index: 2;
  position: absolute;
  top: -60px;
  right: -35px;

  @media (max-width: 767px) {
    top: -100px;
    right: -80px;
    width: 8px;
    height: 8px;
  }
}

//  asy UI 수정 //
.list-table-header {
  .table-header {
    background-color: #f3f4f6;
    /* bg-gray-100 */
    border-bottom: 1px solid #000;
    /* border-b border-black */
  }

  .table-row {
    display: flex;
    justify-content: flex-start;
    width: 885px;
  }

  .table-cell {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #2d2d2d;
    /* text-neutral-800 */
    font-size: 0.875rem;
    /* text-sm */
    font-weight: bold;
    /* font-bold */
    font-family: 'NanumSquare Neo', sans-serif;
    line-height: 1.75rem;
    /* leading-relaxed */
    height: 60px;
  }

  .job-type {
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--black, #222);
    font-family: 'NanumSquare Neo';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 185.714% */
  }

  .education {
    display: flex;
    width: 90px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
  }

  .remain {
    display: flex;
    width: 80px;
    padding: 7px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
  }

  .period {
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--black, #222);
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 185.714% */
  }

  .money {
    display: flex;
    width: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
  }

  .company {
    display: flex;
    width: 60px;
    height: 60px;
    padding: 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }
}

//
//  list-table-body  //
//
.list-table-body {
  .table-row {
    display: flex;
    width: 885px;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    font-weight: 500 !important;
    font-family: 'NanumSquare Neo', sans-serif;

    cursor: pointer;
  }

  .table-row:hover {
    background-color: #f3f4f6;
    /* bg-gray-100 */
  }

  .table-cell {
    padding: 0.5rem 0;
    border-left: 0.1px solid rgba(0, 0, 0, 0.05);
  }

  .title-cell {
    width: 350px;
    padding: 0 25px;
    flex-shrink: 0;
    color: #292929;
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    border: 0;
    box-sizing: border-box;
  }

  .jobtype-cell {
    display: flex;
    width: 100px;
    height: 35px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--orange, #ffa940);
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    /* 171.429% */
  }

  .method-cell {
    display: flex;
    width: 90px;
    height: 35px;
    justify-content: center;
    align-items: center;
  }

  .method-cell-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px !important;
  }

  .method-orange {
    border-radius: 21px;
    background: var(--tag-orange, #ff7e47);
    /* bg-orange-400 */
  }

  .method-sky {
    background-color: #0ea5e9;
    /* bg-sky-500 */
  }

  .method-green-light {
    background-color: #22c55e;
    /* bg-green-400 */
  }

  .method-green-dark {
    background-color: #065f46;
    /* bg-green-950 */
  }

  .method-cell div {
    padding: 0.125rem 0.75rem;
    /* px-3 py-0.5 */
    border-radius: 9999px;
    /* rounded-full */
    color: #ffffff;
    /* text-white */
    font-size: 12px;
    /* text-sm */
    font-weight: normal;
  }

  .deadline-cell {
    display: flex;
    width: 80px;
    height: 35px;
    justify-content: center;
    align-items: center;
    color: var(--black, #000);
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 12px;
    font-style: normal;
    line-height: 24px;
    /* 200% */
  }

  .remain-led {
    color: #f00;
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 12px;
    font-style: normal;
    line-height: 24px;
    /* 200% */
  }

  .period-cell {
    display: flex;
    width: 120px;
    height: 35px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--black, #222);
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    /* 166.667% */
  }

  .price-cell {
    display: flex;
    width: 80px;
    height: 35px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--black, #222);
    text-align: center;
    font-family: 'NanumSquare Neo';
    font-size: 12px;
    font-style: normal;
    line-height: 26px;
    /* 185.714% */
  }

  .profile-cell {
    display: flex;
    width: 60px;
    height: 35px;
    padding: 11px 0;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .profile-content {
    width: 50%;
    display: flex;
    padding: 6.25px 3.75px 7.5px 3.75px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  img {
    width: 50%;
  }
}

// view-toggle //
.view-toggle {
  display: flex;
  width: 200px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #eee;
  left: 78%;

  .list-btn {
    border: 0.1px solid #eee;

    svg {
      position: relative;
      left: -3px;
    }

    &.on {
      background-color: #fce500;
      stroke-width: 0.5px;
      stroke: #eee;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
    }
  }

  .card-btn {
    &.on {
      background-color: #fce500;
      stroke-width: 0.5px;
      stroke: #eee;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
    }
  }

  .btn {
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 10px;
  }

  .view-char {
    position: absolute;
    right: 80px;
    top: -80px;
    animation: moving 1s infinite alternate cubic-bezier(0.46, 0.03, 0.52, 0.96);
    cursor: pointer;
  }
}

@keyframes moving {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.disappear {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.pagination button {
  transition: 0.3s;
  display: flex;
  width: 32px;
  height: 30px;
  padding: 1px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-4, #ebebeb);
  background: var(--white, #fff);
  box-sizing: border-box;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button.active {
  scale: 1.2;
  border-radius: 8px;
  background: var(--yeloow, #fce500);
  outline: none;
  color: var(--black, #222);
  text-align: center;

  /* PC/Bodyl/bold */
  font-family: 'NanumSquare Neo';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 162.5% */
}

.isMobileList {
  width: 98%;

  .card {
    width: 100% !important;
    margin: 0 !important;
  }
  h2.view-list {
    margin-left: 0;
  }

  .thumbnail1-group {
    display: flex;
  }

  .edu-item .edu-title {
    height: 40px !important;
  }

  .edu-item .pickBtnNoPick {
    display: flex;
    padding: 8px 20px;
    height: 35px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--Neutral-4, #ebebeb);
    background: var(--white, #fff);
    box-sizing: border-box;
  }

  picker.direction-flex-row.gap-8.align-center2 {
    justify-content: space-between;
  }
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.isMobileList-toggle {
  width: 140px;
  height: 80px;
  transform: scale(0.8);
  .view-char {
    top: -100px !important;
    right: -60px !important;
  }
}

.isMobile.view-toggle {
  justify-content: end;
  left: 0;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div
  > div
  > section
  > div
  > div.mt-0.mb-2.mb-md-3.mt-24.findJobData
  > div.flex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.isMobile {
  &.view-char {
    margin-top: 20px;
    transform: scale(1.2);
  }

  .view-toggle {
    position: absolute;
    right: 20px;
  }
  .edu-item {
    margin: 0;
  }
  h5 {
    height: 38px !important;
  }
}
.text133 {
  width: 130px;
}
.isMobile-view-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  right: -25px;
  transform: scale(0.8);
  .view-char {
    right: -65px !important;
  }
  .view-char svg {
    scale: 0.8;
  }
}

.isMobileCard {
  width: 45%;
}

div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div
  > div
  > section
  > div {
  margin-top: 0px;
}

div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div
  > div
  > section {
  margin-top: 30px;
  position: relative;
}

div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div
  > div
  > section
  > div
  > div {
  padding: 0 !important;
  gap: 20px;
}

div.wrapper.d-flex.flex-column.min-vh-100
  > div
  > div
  > section
  > div
  > div
  > div
  > section
  > div
  > div:nth-child(2) {
  justify-content: flex-start;
}

.picker.direction-flex-row.gap-8.align-center2 {
  justify-content: space-between;
}
div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(8)
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide.swiper-slide-active
  > div {
  width: 90% !important;
  height: 100%;
}
div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(8)
  > div:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide.swiper-slide-active
  > div
  > div {
  width: 90% !important;
}
div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(8)
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide.swiper-slide-next
  > div {
  width: 90% !important;
  height: 100%;
}
div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(8)
  > div:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide.swiper-slide-active
  > div {
  width: 90% !important;
}
div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(8)
  > div:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide.swiper-slide-next
  > div {
  width: 90% !important;
}

.viewCard {
  width: 30%;
  height: fit-content;
  // background: red;
  .card {
    width: 100% !important;
    .card-body {
      width: 100%;
    }
  }
  &.disIsMobile {
    width: 31%;
  }
  &.isMobileList {
    width: 48%;
    &.disIsMobile {
      width: 30%;
    }
    &.isMobile {
      width: 48% !important;
    }
  }
  &.no {
    width: 90%;
  }
}

div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div.gap-32
  > div
  > section
  > div:nth-child(2)
  > div.three-item-wrap
  .no {
  width: 30%;
}

.curation {
  &.card {
    width: 100%;
    .card {
      width: 100%;
    }
  }
}

.view-char.isMobile {
  position: relative;
  margin-top: 20px;
  right: -70px;
  top: -100px;
  svg {
    transform: scale(2);
  }
}

.recommend-product-mb {
  width: 100%;
  .recommend-product-item {
    width: 45%;
    .isMobileCard {
      width: 100%;
    }
  }
  .edu-title {
    width: 115px;
    height: 48px;
  }
}

.recommend-mb {
  align-items: flex-start;
  border-bottom: 1px solid #ebebeb;
  border-bottom: 1px solid var(--neutral-4, #ebebeb);
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  padding: 20px;
  position: relative;
  z-index: 3;
}

.findjob .filter-box .three-item-wrap .edu-item {
  width: 100% !important;
}

#maininfo > div.recommended-md {
  position: relative;
  z-index: 10;
  padding: 20px;
  margin-top: 56px;
  .pick-text {
    color: #ffa940;
    font-size: 24px;
    font-weight: 700;
    margin-top: 12px;
  }
  .text1 {
    margin-top: 16px;
  }
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div.mt-40.gap-104.home-list.flex-direction
  > div.home-list3.gap-32.flex-direction
  > div.gap-24
  > div
  > div {
  width: 95%;
}
#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div.mt-40.gap-104.home-list.flex-direction
  > div.home-list4.gap-32.flex-direction
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div.swiper-wrapper
  > div.swiper-slide
  > div {
  width: 98%;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div.mt-40.gap-104.home-list.flex-direction
  > div.home-list4.gap-32.flex-direction
  > div:nth-child(3)
  > div:nth-child(2)
  > div
  > div
  > div.swiper-wrapper
  > div.swiper-slide
  > div {
  width: 98%;
}
#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div.container
  > div
  > div
  > div
  > div.col-md-9.px-0.px-md-3
  > div.detail-wrap
  > section
  > div.justify-between
  > div
  > div {
  width: 98%;
}

.view-list-area {
  display: flex;
  margin-top: 20px;
  height: 38px;
  justify-content: space-between;
  .view-list {
    font-size: 18px;
    margin-top: 0px !important;
    margin-left: 5px;
    line-height: 2;
  }
}
.line-height-2-2 {
  line-height: 2.2;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div
  > div
  > section
  > div
  > div.mt-0.mb-2.mb-md-3.mt-24.findJobData {
  margin-top: 14px !important;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div.mt-40.gap-104.home-list.flex-direction
  > div.home-list2.gap-32.flex-direction
  > div.home-slide.undefined.four-item-wrap
  > div.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden
  > div.swiper-wrapper
  > div.swiper-slide
  > div {
  width: 100%;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div.edu-detail.mypage.py-0.py-md-2
  > section
  > div
  > div
  > div
  > div
  > div.col-md-9.px-0.px-md-3
  > div.detail-wrap.down-wrap.mypage-middle
  > div.position-relative.mt-3
  > div.three-item-wrap
  > div {
  width: 33%;
  .card {
    width: 90% !important;
  }
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div
  > div
  > section
  > div
  > div:nth-child(7)
  > div
  > div
  > div.swiper-slide
  > div {
  width: 90% !important;
}
#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(11)
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide
  > div {
  width: 90% !important;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(7)
  > div
  > div
  > div.swiper-slide
  > div {
  width: 90% !important;
}

#root
  > div
  > div.wrapper.d-flex.flex-column.min-vh-100
  > div.body.flex-grow-1
  > div
  > section
  > div
  > div:nth-child(11)
  > div:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div
  > div.swiper-slide
  > div {
  width: 90% !important;
}
